import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TypoGraphy } from './TypoGraphy'
import { RFPercentage } from 'react-native-responsive-fontsize';
import { secondaryPalette } from './Palette';

export default function KnowMeButton(props: any) {
    const useStyles = makeStyles((theme) => ({
        disabled: {
            backgroundColor: props.bgcolor ?? 'transparent',
            width: props.width ?? "100%",
            color: props.color ?? '',
            height: props.height ?? 'auto',
            borderRadius: '0px',
            fontFamily: 'Campton-Bold',
            textTransform: 'none',
            "&:hover": {
                backgroundColor: props.bgcolor ?? 'transparent',
                width: props.width ?? "100%",
                color: props.color ?? '',
                height: props.height ?? 'auto',
                borderRadius: '0px',
                fontFamily: 'Campton-Bold',
                textTransform: 'none',
            }
        }
    }));
    const classes = useStyles();
    return (
        <Button {...props} className={classes.disabled} onClick={props.onClick}>
            {props.children}
        </Button>
    )
}
