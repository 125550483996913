import React from "react";
import { makeStyles } from "@material-ui/core";

export default function Close(props: any) {
    const useStyles = makeStyles({
        close: {
            transform : "rotateZ(45deg)"
        }
    })
    const classes = useStyles();
    return (
        <svg className={classes.close} x="1.7" y="5.4" viewBox="0 0 72 72">
            <path fill={props.color} d="M 36 72 C 16.1 72 0 55.9 0 36 C 0 16.1 16.1 0 36 0 C 55.9 0 72 16.1 72 36 C 72 55.9 55.9 72 36 72 Z M 36 3 C 17.8 3 3 17.8 3 36 C 3 54.2 17.8 69 36 69 C 54.2 69 69 54.2 69 36 C 69 17.8 54.2 3 36 3 Z"></path>
            <path fill={props.color} d="M 39.5 14.6 H 32.4 V 57.4 H 39.5 V 14.6 Z">
            </path>
            <path fill={props.color} d="M 14.6 32.5 V 39.5 H 57.5 V 32.5 H 14.6 Z">
            </path>
        </svg>
    );
}
