import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import axios from 'axios';
import { palette } from "../../../components/src/Palette";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  email: string;
  applicationNumber: string;
  loginError: string;
  buttonBackground: string,
  buttonFontColor: string,
  loginType: string,
  emailError: any,
  pwdError: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SingleSignonssoController extends BlockComponent<
  Props,
  S,
  SS
> {

  loginApi: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start    
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationDashboardMessage)];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      email: '',
      applicationNumber: '',
      loginError: '',
      buttonBackground: '',
      buttonFontColor: '',
      loginType: 'candidate',
      emailError: '',
      pwdError: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.meta != undefined) {
      // user is successfully logged-in here, take the user to the dashboard page and save auth token
      const theme = responseJson.meta.user.data.attributes.company?.attributes
      console.log("ALL COLORES", theme?.theme_hash_code_1, palette.primary.main)
      localStorage.setItem('token', responseJson.meta.token)
      localStorage.setItem('role', responseJson.meta.role)
      localStorage.setItem('fullName', responseJson.meta.first_name + ' ' + responseJson.meta.last_name)
      localStorage.setItem('email', this.state.email);
      localStorage.setItem('setting', theme?.theme_colour_type);
      localStorage.setItem('is_premium', responseJson.meta.user.data.attributes.company?.attributes.is_premium);
      localStorage.setItem('logo', theme?.theme_logo !== null ? theme?.theme_logo.url : '');
      localStorage.setItem('user_id', responseJson.meta.user.data.id);
      localStorage.setItem('company_id', responseJson.meta.user.data.attributes.company?.id);
      localStorage.setItem('termsAndCondition', responseJson.meta.user.data.attributes.terms_and_condition_acceptance);
      localStorage.setItem('language', responseJson.meta.user.data.attributes.language);
      if(theme){
        localStorage.setItem('colors', JSON.stringify({
          primary: theme?.theme_hash_code_1 ? theme?.theme_hash_code_1 : palette.primary.main,
          secondary: theme?.theme_hash_code_2 ? theme?.theme_hash_code_2 : palette.secondary.main,
          extra: theme?.theme_hash_code_3 ? theme?.theme_hash_code_3 : '#ffffff'
        }))
      } else {
        localStorage.setItem('colors', JSON.stringify({
          primary: palette.primary.main,
          secondary: palette.secondary.main,
          extra: '#ffffff'
        }))
      }
      if (localStorage.getItem('language') == 'null' || localStorage.getItem('termsAndCondition') == 'null') {
        localStorage.setItem('guide', 'true');
      } else {
        localStorage.setItem('guide', 'false');
      }
      this.props.navigation.navigate('Dashboard')
    } else if (responseJson.errors != undefined) {
      this.setState({
        loginError: responseJson.errors[0].failed_login ? responseJson.errors[0].failed_login : responseJson.errors[0].account
      });
    } else {
      this.showAlert('message', 'Something went wrong!')
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleInput(inputType: string, event: any) {
    // setting state    
    this.setState({ [inputType]: event.target.value } as Pick<S, keyof S>);
    if (inputType == 'email' && event.target.value) {
      this.setState({
        emailError: ''
      })
    } else if (inputType == 'applicationNumber' && event.target.value) {
      this.setState({
        pwdError: ''
      })
    }
  }

  async login() {
    if (
      this.state.email === null ||
      this.state.email.length === 0 || this.state.email.length === undefined
    ) {
      this.setState({
        emailError: 'This field is required!'
      })
      return false;
    }

    if (this.state.applicationNumber === null || this.state.applicationNumber.length === 0) {
      this.setState({
        pwdError: 'This field is required!'
      })
      return false;
    }

    // set `applicationId` in LS to show in header
    localStorage.setItem('applicationId', this.state.applicationNumber);
    const header = {};

    const formData = new FormData();
    formData.append('data[type]', 'email_account');
    formData.append('data[attributes][email]', this.state.email);
    formData.append('data[attributes][password]', this.state.applicationNumber);
    if (this.state.loginType == 'hr') {
      formData.append('data[attributes][role]', this.state.loginType);
    }

    const httpBody = formData;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(this.loginApi, requestMessage);
  }
  // Customizable Area End
}
