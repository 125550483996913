import React from 'react';
import { View, Text, Platform, Image, StyleSheet, TouchableOpacity } from 'react-native'
import TestHeader from './TestHeader'
import { RFPercentage } from "react-native-responsive-fontsize"
import * as Theme from '../../blocks/ProjecttaskTracking/src/theme'
import { Divider } from 'react-native-elements'
import { useSelector, useDispatch } from 'react-redux'
import { TypoGraphy } from './TypoGraphy'
import { Button, Box, Container, Typography, Grid, Modal, ListItem, List, ListItemText, SvgIcon, Checkbox, FormControl, FormGroup, FormControlLabel, Icon, TextField } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'// a plugin!
import styled from 'styled-components'
import { palette, secondaryPalette } from './Palette'
import FormField from './FormField'
import KnowMeButton from './Button'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { toggleContactModal } from "./actions/index";
// @ts-ignore
import Pdf from "react-to-pdf";
// @ts-ignore
import { CSVLink } from "react-csv";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const StyledWrapper = styled.div`
  * {
    font-family : Campton-Bold;
    color: #10069f
  }
  .fc-day-today .fc-daygrid-day-frame {
    background: #ffffff
  }
  .fc-daygrid-day-number {
    color: #10069f;
    font-weight: 500
  }
  .fc-day-today .fc-daygrid-day-number{
    color: #64CBBA;
    padding:0.3em;
    background: #10069f;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc-timegrid-slot-label-cushion{
    text-transform: uppercase
  }
  .fc-timegrid-axis-cushion, .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
  }
  .fc-timegrid-slot-minor {
    border: 0!important;
  }
  .fc-col-header-cell-cushion {
    word-spacing: 20px;
  }
`
const StyledWrapperSub = styled(StyledWrapper)`
  .fc-daygrid-day-events {
    display: none
  }
  .fc-daygrid-day-number{
    font-size: 0.5em
  }
  a.fc-col-header-cell-cushion {
    font-size: 0.5em;
  }
  .fc-day-today .fc-daygrid-day-frame {
    background-color: #e9e9e9
  }
  .fc-col-header {
    display:none
  }
`
const calendarRef: any = React.createRef()
const subcalendarRef: any = React.createRef()


const Accordion = withStyles({
    root: {
        width: "100%",
        fontFamily: "Campton-Bold",
        borderBottom: '5px solid #fff',
        boxShadow: 'none',
        '&:last-child': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: Theme.palette.primary.main,
        borderBottom: 'none',
        marginBottom: -1,
        padding: '20px',
        paddingLeft: '55px',
        color: '#fff',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'flex',
        paddingTop: '7px',
        paddingBottom: '0px',
        paddingRight: '0px',
        paddingLeft: '0px',
        width: '100%',
        '&:last-child': {
            paddingBottom: '5px',
        },
    },
}))(MuiAccordionDetails);
const ref = React.createRef();
export default function EiResults(props: any) {
    const mainIconDimensions = RFPercentage(4)
    const [drawer, openDrawer] = React.useState(localStorage.getItem('role') == 'candidate' ? props.resultId ? true : false : true)
    const [options, openOptions] = React.useState(true)
    const scroll = React.useRef()
    const dispatch = useDispatch()
    const [expanded, setExpanded] = React.useState('');
    const [expandResultLikert, setExpandResultLikert] = React.useState(0);
    const [expandResultFCS, setExpandResultFCS] = React.useState(0);
    const [expandResultEi, setExpandResultEi] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [errorText, setErrorText] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const baseConfig = require("../../blocks/dashboard/src/config");

    React.useEffect(() => {
        if(props.top){
            scrollToImportantStuff()
        }
    }, [])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const changeValue = (e: any) => {
        const value: any = e.target.value;
        setEmail(value)
        let regEmail: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(value)) {
            console.log("INSIDE")
            setErrorText(true)
        } else {
            setErrorText(false)
            setEmail(value);
        }
    }

    const shareViaEmail = () => {
        const requestOptions: any = {
            method: "GET",
            headers: {
                token: localStorage.getItem("token"),
            },
        };
        fetch(
            baseConfig.baseURL +
            "/bx_block_test/candidate_test_results/send_email?id=" + props.id + "&email=" + email + "&title=emotional_intelligence",
            requestOptions
        )
            .then((response) => {
                if (response.status === 204) {
                    setOpen(false);
                    setErrorText(false);
                } else {
                    console.log("FAILED", response)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    const headers: any = [
        { label: "Test Title", key: "test_title" },
        { label: "Test Description", key: "test_desc" },
        { label: "Likert Scale Scoring", key: 'likert_score' },
        { label: "", key: 'like_perce_desc' },
        { lable: 'Understanding', key: 'like_Understanding' },
        { lable: '', key: 'like_under_desc' },
        { lable: 'Management', key: 'like_management' },
        { lable: '', key: 'like_man_desc' },
        { label: "Forced Choice Scoring", key: 'force_score' },
        { label: "", key: 'force_perce_desc' },
        { lable: 'Understanding', key: 'force_Understanding' },
        { lable: '', key: 'force_under_desc' },
        { lable: 'Management', key: 'force_management' },
        { lable: '', key: 'force_man_desc' },
        { lable: 'TeamWork', key: 'force_team' },
        { lable: '', key: 'force_team_desc' },
        { lable: 'Creativity', key: 'force_creativity' },
        { lable: '', key: 'force_creativity_desc' },
        { label: "Developing Emotional Intelligence Composites", key: 'dev_score' },
        { label: "", key: 'dev_perce_desc' },
        { lable: 'Understanding', key: 'dev_Understanding' },
        { lable: '', key: 'dev_under_desc' },
        { lable: 'Management', key: 'dev_management' },
        { lable: '', key: 'dev_man_desc' },
    ];

    const data: any = [
        {
            test_title: props.testName,
            test_desc: `Emotional Intelligence (EQ) is the degree to which you know yourself. 
            EQ is not set and can be increased through awareness and concious effort. 
            The results from your EI-ACER Awareness survey will help you become more aware of your 
            EQ strengths and be able to identify opportunities for growth.`,
            likert_score: 'Perception',
            like_perce_desc: '',
            like_Understanding: 'Understanding',
            like_under_desc: '',
            like_management: 'Management',
            like_man_desc: '',
            force_score: 'Perception',
            force_perce_desc: '',
            force_Understanding: 'Understanding',
            force_under_desc: '',
            force_management: 'Management',
            force_man_desc: '',
            force_team: 'Teamwork',
            force_team_desc: '',
            force_creativity: 'Creativity',
            force_creativity_desc: '',
            dev_score: 'Perception',
            dev_perce_desc: '',
            dev_Understanding: 'Understanding',
            dev_under_desc: '',
            dev_management: 'Management',
            dev_man_desc: '',
        },
        {
            test_title: '',
            test_desc: "",
            likert_score: 'Points',
            like_perce_desc: 'Point Description',
            like_Understanding: 'Points',
            like_under_desc: 'Point Description',
            like_management: 'Points',
            like_man_desc: 'Point Description',
            force_score: 'Points',
            force_perce_desc: 'Point Description',
            force_Understanding: 'Points',
            force_under_desc: 'Point Description',
            force_management: 'Points',
            force_man_desc: 'Point Description',
            force_team: 'Points',
            force_team_desc: 'Point Description',
            force_creativity: 'Points',
            force_creativity_desc: 'Point Description',
            dev_score: 'Points',
            dev_perce_desc: 'Point Description',
            dev_Understanding: 'Points',
            dev_under_desc: 'Point Description',
            dev_management: 'Points',
            dev_man_desc: 'Point Description',
        },
        {
            test_title: '',
            test_desc: "",
            likert_score: props.results?.result_1[0].Likert_EPerception,
            like_perce_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            like_Understanding: props.results?.result_1[0].Likert_EUnderstanding,
            like_under_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            like_management: props.results?.result_1[0].Likert_EManagement,
            like_man_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            force_score: props.results?.result_2[0].Choice_EPerception,
            force_perce_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            force_Understanding: props.results?.result_2[0].Choice_EUnderstanding,
            force_under_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            force_management: props.results?.result_2[0].Choice_EManagement,
            force_man_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            force_team: props.results?.result_2[0].Choice_Teamwork,
            force_team_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            force_creativity: props.results?.result_2[0].Choice_Creativity,
            force_creativity_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            dev_score: props.results?.result_3[0].EPerception_ZScore,
            dev_perce_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            dev_Understanding: props.results?.result_3[0].EUnderstanding_ZScore,
            dev_under_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
            dev_management: props.results?.result_3[0].EManagement_ZScore,
            dev_man_desc: `Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
        }
    ];

    const expandResultType = (id: any, type: any) => {
        if (type === 'likert') {
            setExpandResultLikert(id);
        } else if (type === 'fcs') {
            setExpandResultFCS(id);
        } else if (type === 'ei') {
            setExpandResultEi(id);
        }
    }

    const scrollToImportantStuff = () => {
        // @ts-ignore
        document.getElementById('ImportantStuff').scrollIntoView()
    }

    return (
        // @ts-ignore
        <Box ref={ref} id="ImportantStuff">
            <Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            borderRadius: '0px'
                        },
                    }}
                >
                    <DialogTitle id="form-dialog-title">
                        <Text style={{ fontSize: RFPercentage(1.5), color: Theme.palette.primary.main }}>Share Via Email</Text>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide email ID where you would like us to send results.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            error={errorText}
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            name="email"
                            fullWidth
                            helperText={errorText ? "Invalid Email" : null}
                            onChange={e => changeValue(e)}
                            onBlur={e => changeValue(e)}
                            style={{ color: 'red' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <TouchableOpacity disabled={errorText} onPress={shareViaEmail} style={{ backgroundColor: Theme.palette.primary.main, padding: '10px' }}>
                            <Text style={{ fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Share</Text>
                        </TouchableOpacity>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box style={{ padding: "2em", backgroundColor: Theme.palette.primary.main, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontFamily: "Campton-Bold", fontSize: RFPercentage(2), color: "#ffffff" }}>
                    {props.testName}
                </Text>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <TouchableOpacity onPress={() => { openDrawer(!drawer), scroll.current }}>
                        <Image style={{ height: "3em", width: "3em" }} source={drawer ? require(`./close.svg`) : require(`./add.svg`)} />
                    </TouchableOpacity>
                    {
                        props.type == 'declared' ? (
                            <TouchableOpacity onPress={() => { openOptions(!options), scroll.current }}>
                                <Image style={{ marginLeft: "1em", height: "3em", width: "3em" }} source={require(`./options.svg`)} />
                            </TouchableOpacity>
                        ) : (<></>)
                    }
                </Box>
            </Box>
            {
                props.type == 'declared' ? (
                    <TestHeader ref={scroll}>
                        <Box style={{ display: drawer ? "flex" : "none", flexDirection: 'column' }}>
                            <Box style={{ padding: "3.5em", border: '3px solid', borderColor: Theme.palette.primary.main }}>
                                <Box style={{ display: options ? "flex" : "none" }}>
                                    <Box style={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "row" }}>
                                        <TouchableOpacity onPress={handleClickOpen} style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                            <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                <Image style={{ height: "2em", width: "2em" }} source={require(`./share.svg`)} />
                                                <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Share Via Email</Text>
                                            </Box>
                                        </TouchableOpacity>
                                        <Pdf targetRef={ref} scale={0.69} filename="EiResult.pdf">
                                            {({ toPdf }: any) => {
                                                return (
                                                    <TouchableOpacity onPress={toPdf} style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                                        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                            <Image style={{ height: "2em", width: "2em" }} source={require(`./download.svg`)} />
                                                            <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Download PDF</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                )
                                            }}
                                        </Pdf>
                                        <CSVLink data={data} headers={headers} filename={"EiResult.csv"} style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                                <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                    <Image style={{ height: "2em", width: "2em" }} source={require(`./download.svg`)} />
                                                    <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Download CSV</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </CSVLink>
                                    </Box>
                                    <Divider style={{
                                        marginVertical: '2em', backgroundColor: "transparent"
                                    }} />
                                </Box>
                                <Box style={{ marginTop: '3em', display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontFamily: "Campton-Light", fontSize: RFPercentage(1.2), lineHeight: 26 }}>
                                        {props.mainDescription}
                                    </Text>
                                    <Text style={{ fontFamily: "Campton-Bold", marginTop: "1.5em", fontSize: RFPercentage(1.2) }}>
                                        Please click on any of the sections in the graph to explore them further
                                    </Text>
                                </Box>
                            </Box>
                            <Box style={{ border: '3px solid', borderColor: Theme.palette.primary.main }}>
                                <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>Likert Scale Scoring</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            style={{
                                                display: expandResultLikert === 1 || expandResultLikert === 2 || expandResultLikert === 3 ? 'none' : 'flex',
                                                flexDirection: "row",
                                                width: '100%',
                                                backgroundColor: Theme.palette.primary.main
                                            }}
                                        >
                                            <TouchableOpacity
                                                onPress={() => expandResultType(1, 'likert')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#4038b2",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '33.33%',
                                                    minWidth: '33.33%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Perception
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(2, 'likert')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#706ac5",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '33.33%',
                                                    minWidth: '33.33%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Understanding
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(3, 'likert')}
                                                style={{
                                                    backgroundColor: "#9f9bd9",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '33.33%',
                                                    minWidth: '33.33%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Management
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                        </Box>
                                        {
                                            expandResultLikert === 1 ?
                                                <Box
                                                    style={{
                                                        display: expandResultLikert === 1 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#4038b2",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'likert')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                Perception
                                                            </Text>
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                {props.results.result_1[0].Likert_EPerception}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultLikert === 2 ?
                                                <Box
                                                    style={{
                                                        display: expandResultLikert === 2 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#706ac5",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'likert')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                Understanding
                                                            </Text>
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                {props.results.result_1[0].Likert_EUnderstanding}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultLikert === 3 ?
                                                <Box
                                                    style={{
                                                        display: expandResultLikert === 3 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#9f9bd9",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'likert')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                Management
                                                            </Text>
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                {props.results.result_1[0].Likert_EManagement}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                        <Typography>Forced Choice Scoring</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            style={{
                                                display: expandResultFCS === 1 || expandResultFCS === 2 || expandResultFCS === 3 || expandResultFCS === 4 || expandResultFCS === 5 ? 'none' : 'flex',
                                                flexDirection: "row",
                                                width: '100%',
                                                backgroundColor: Theme.palette.primary.main
                                            }}
                                        >
                                            <TouchableOpacity
                                                onPress={() => expandResultType(1, 'fcs')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#4038b2",
                                                    width: '20%',
                                                    minWidth: '20%',
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Perception
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(2, 'fcs')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#706ac5",
                                                    width: '20%',
                                                    minWidth: '20%',
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Understanding
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(3, 'fcs')}
                                                style={{
                                                    backgroundColor: "#9f9bd9",
                                                    width: '20%',
                                                    minWidth: '20%',
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Management
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(4, 'fcs')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#cfcdec",
                                                    // padding: "5em", 
                                                    // paddingLeft: '4em', 
                                                    // paddingRight: '4em', 
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '20%',
                                                    minWidth: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#000000",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Teamwork
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(5, 'fcs')}
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '20%',
                                                    minWidth: '20%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Creativity
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                        </Box>
                                        {
                                            expandResultFCS === 1 ?
                                                <Box
                                                    style={{
                                                        display: expandResultFCS === 1 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#4038b2",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'fcs')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                Perception
                                                            </Text>
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                {props.results.result_2[0].Choice_EPerception}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultFCS === 2 ?
                                                <Box
                                                    style={{
                                                        display: expandResultFCS === 2 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#706ac5",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'fcs')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                Understanding
                                                            </Text>
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                {props.results.result_2[0].Choice_EUnderstanding}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultFCS === 3 ?
                                                <Box
                                                    style={{
                                                        display: expandResultFCS === 3 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#9f9bd9",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'fcs')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                Management
                                                            </Text>
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                {props.results.result_2[0].Choice_EManagement}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultFCS === 4 ?
                                                <Box
                                                    style={{
                                                        display: expandResultFCS === 4 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#cfcdec",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'fcs')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                Teamwork
                                                            </Text>
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                {props.results.result_2[0].Choice_Teamwork}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultFCS === 5 ?
                                                <Box
                                                    style={{
                                                        display: expandResultFCS === 5 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#e0e0e0",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'fcs')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                Creativity
                                                            </Text>
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                {props.results.result_2[0].Choice_Creativity}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Typography>Developing Emotional Intelligence Composites</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            style={{
                                                display: expandResultEi === 1 || expandResultEi === 2 || expandResultEi === 3 ? 'none' : 'flex',
                                                flexDirection: "row",
                                                width: '100%',
                                                backgroundColor: Theme.palette.primary.main
                                            }}
                                        >
                                            <TouchableOpacity
                                                onPress={() => expandResultType(1, 'ei')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#4038b2",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '33.33%',
                                                    minWidth: '33.33%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Perception
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(2, 'ei')}
                                                style={{
                                                    borderRightWidth: 5,
                                                    borderColor: "#ffffff",
                                                    backgroundColor: "#706ac5",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '33.33%',
                                                    minWidth: '33.33%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            color: "#ffffff",
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Understanding
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => expandResultType(3, 'ei')}
                                                style={{
                                                    backgroundColor: "#9f9bd9",
                                                    paddingTop: '5em',
                                                    paddingBottom: '5em',
                                                    width: '33.33%',
                                                    minWidth: '33.33%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flex: 1
                                                }}
                                            >
                                                <Box>
                                                    <Text
                                                        style={{
                                                            textAlign: "center",
                                                            fontFamily: 'Campton-Bold'
                                                        }}
                                                    >
                                                        Management
                                                    </Text>
                                                </Box>
                                            </TouchableOpacity>
                                        </Box>
                                        {
                                            expandResultEi === 1 ?
                                                <Box
                                                    style={{
                                                        display: expandResultEi === 1 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#4038b2",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'ei')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                Perception
                                                            </Text>
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                {props.results.result_3[0].EPerception_ZScore}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultEi === 2 ?
                                                <Box
                                                    style={{
                                                        display: expandResultEi === 2 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#706ac5",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'ei')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                Understanding
                                                            </Text>
                                                            <Text
                                                                style={styles.textWhite}
                                                            >
                                                                {props.results.result_3[0].EUnderstanding_ZScore}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#fff',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            expandResultEi === 3 ?
                                                <Box
                                                    style={{
                                                        display: expandResultEi === 3 ? 'flex' : 'none',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: "#9f9bd9",
                                                        padding: '4em',
                                                    }}
                                                >
                                                    <TouchableOpacity
                                                        onPress={() => expandResultType(0, 'ei')}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                Management
                                                            </Text>
                                                            <Text
                                                                style={styles.textBlack}
                                                            >
                                                                {props.results.result_3[0].EManagement_ZScore}
                                                            </Text>
                                                        </Box>
                                                    </TouchableOpacity>
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        {/* <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '3em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type 
                                                        and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                                                        typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: '#000',
                                                            fontFamily: 'Campton-Light',
                                                            marginTop: '2em',
                                                            fontSize: RFPercentage(1.2), 
                                                            lineHeight: 26
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                                                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </Text> */}
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" flexDirection="row">
                            <Button onClick={(e: any) => { dispatch(toggleContactModal(true)) }}>
                                <Typography color="textSecondary" variant="h6">Need Help?</Typography>
                                <Image style={{ marginLeft: "1em", height: 24, width: 24 }} source={require(`./help.svg`)} />
                            </Button>
                        </Box>
                    </TestHeader>
                ) : (
                    /**
                     * show calendar for undeclared tests
                     */
                    <Box display={drawer ? "block" : "none"} style={{ padding: "3em", borderStyle: "solid", borderWidth: 3, borderColor: palette.primary.main }}>
                        <Box mb="3em">
                            <TypoGraphy transform="none" variant="h5" color="#000000">Your EQ-i 2.0 results are not yet ready to view. Please start by booking an appointment with one of our coaches</TypoGraphy>
                        </Box>
                        <Grid container>
                            <Grid item sm={8}>
                                <StyledWrapper>
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin, timeGridPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={false}
                                        allDayText="All Day"
                                    />
                                </StyledWrapper>
                            </Grid>
                            <Grid item sm={4} style={{ backgroundColor: '#e9e9e9' }}>
                                <Box display="flex" flexDirection="column" paddingX={5} paddingY={5}>
                                    <TypoGraphy color={palette.primary.main} variant="subtitle1">Please select a time and fill out the form below</TypoGraphy>
                                    <Box>
                                        <FormControl required component="fieldset" >
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Name</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('fullName')} readOnly={true} />
                                            </Box>
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Employee #</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('applicationId')} readOnly={true} />
                                            </Box>
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Email Address</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('email')} readOnly={true} />
                                            </Box>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box display="flex" mt="2em" justifyContent="flex-end">
                            <KnowMeButton btnText="Submit" type="disabled" />
                        </Box>
                    </Box>
                )
            }
        </Box >
    )
}
const styles = StyleSheet.create({
    text: {
        fontFamily: "Campton-Bold"
    },
    textBlack: {
        color: '#000',
        fontFamily: 'Campton-Bold',
        fontSize: 20,
    },
    textWhite: {
        color: '#fff',
        fontFamily: 'Campton-Bold',
        fontSize: 20,
    },
    inlineView: {
        display: "flex", justifyContent: "space-between", flexDirection: "row"
    }
})