import React from "react"
import { Icon, makeStyles } from "@material-ui/core"

export const IconImage = (props: any) => {
    const useStyles = makeStyles((theme) => ({
        colors: {
            height: props.height,
            width: props.width
        }
    }));
    const classes = useStyles();
    return (
        <Icon className={classes.colors}>
            {props.children}
        </Icon>
    )
}