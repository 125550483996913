import React from "react";

export default function Affirmations(props: any) {
  return (
    <svg viewBox="0 0 72 72" fill={props.color}>
      <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
      <path d="M57.9783 38.0463L62.3741 32.9684L56.993 28.8758L58.9636 22.4337L52.4457 20.9179L51.612 14.2484L45.0183 15.5369L41.532 9.77686L35.9994 13.5663L30.4667 9.77686L26.9804 15.5369L20.3867 14.2484L19.5531 20.9179L13.0352 22.4337L15.0057 28.8758L9.62463 32.9684L14.0204 38.0463L10.7615 43.8821L16.8246 46.7621L16.2941 53.4316L23.0394 53.5832L25.2373 59.9495L31.3762 57.2969L35.9994 62.2232L40.6225 57.2969L46.7615 59.9495L48.9594 53.5832L55.7046 53.4316L55.1741 46.7621L61.2373 43.8821L57.9783 38.0463ZM35.9994 51.0063C27.7383 51.0063 20.9931 44.2611 20.9931 36C20.9931 27.739 27.7383 20.9937 35.9994 20.9937C44.2604 20.9937 51.0057 27.739 51.0057 36C51.0057 44.2611 44.2604 51.0063 35.9994 51.0063Z" />
      <path d="M36.9861 40.2442L29.1798 32.5137L26.3756 35.3937L34.2577 43.0484L36.9861 40.2442Z" />
      <path d="M34.2571 43.0485L31.4529 40.3201L42.7455 28.9517L45.6255 31.7559L34.2571 43.0485Z" />
    </svg>
  );
}
