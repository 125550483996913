import React, { Component } from "react";
import { View, StyleSheet, Image, Text, TouchableOpacity, TextInput } from "react-native";
import { Box, Typography, Button, List, ListItem } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { palette } from "./Palette";
import {
  deviceType,
} from "react-device-detect";
import { TypoGraphy } from "./TypoGraphy";
import { connect } from "react-redux";
import CustomSVGIcon from "./SVGIcon";
import { Link } from "react-router-dom";
import axios from "axios";
import { updateTheme, updateLogo, expandFilter, testFilter } from "./actions/index";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    border: `1px solid ${palette.secondary.main}`,
    backgroundColor: palette.secondary.main,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface props {
  stateHandler?: any,
  searchURL?: string,
  state?: any,
  colors?: any,
  history: any,
  dispatch: any,
}

interface S {
  searchData: any[]; //replace any with suitable type
  popOver: boolean;
  searchTerm: string;
  searchComplete: boolean;
  logOutMenu: any;
}

class TopNav extends Component<props, S>{
  constructor(props: any) {
    super(props);
    this.state = {
      popOver: false,
      searchTerm: '',
      searchData: [],
      searchComplete: false,
      logOutMenu: null
    }
  }

  componentDidMount() {
    this.props.dispatch(updateLogo(localStorage.getItem('logo') as string));
  }

  handleClick(event: React.MouseEvent<HTMLElement>) {
    this.setState({
      logOutMenu: event.currentTarget
    });
  };

  handleClose() {
    this.setState({
      logOutMenu: null
    });
  };
  /**
   * search users
   */
  async search(url: string, term: string) {
    if (this.state.searchTerm === '') {
      return false;
    }
    const requestOptions = {
      headers: {
        'token': localStorage.getItem('token') ?? '',
        'Content-Type': 'application/json'
      }
    };
    axios.get(url + '/bx_block_ceridian_employee/ceridian_employees?first_name=' + term, requestOptions)
      .then((response: any) => {
        if (response.status === 200) {
          this.setState({ searchData: response.data.employee_listing.data });
          this.setState({ searchComplete: true });
        } else {
          this.setState({ searchData: [] });
        }
      })
      .catch((error: any) => {
        this.setState({ searchData: [] })
      })
  }

  expandJobs() {
    this.props.dispatch(
      expandFilter(false),
    );
    this.props.dispatch(
      testFilter(true)
    );
  }

  searchBox() {
    const colors = this.props.state.updateTheme.present.colors;
    let items = [];
    if (this.state.searchData.length == 0) {
      items.push(<ListItem key={0}><TypoGraphy variant="body1" color={colors.primary}>No results found</TypoGraphy></ListItem>);
    } else {
      items = this.state.searchData.map((item: any, index: any) =>
        <ListItem key={index} button onClick={() => window.location.href = '/filters/hire-date/searched/' + item.id}>
          <TypoGraphy variant="body1" color={colors.primary}>{item.attributes.first_name} {item.attributes.last_name}</TypoGraphy>
        </ListItem>
      )
    }
    return (
      <List component="nav" style={{
        display: this.state.searchComplete ? 'flex' : 'none',
        zIndex: 999, top: "48px",
        paddingLeft: "10px",
        position: "absolute",
        width: "98%",
        backgroundColor: "#ffffff",
        borderWidth: "2px",
        borderStyle: " solid",
        borderColor: colors.primary,
        left: "-3px",
        flexDirection: "column"
      }}>
        {items}
      </List>
    )
  }

  render() {
    const colors = this.props.state.updateTheme.present.colors;
    const styles = StyleSheet.create({
      row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
      },
      outerRow: {
        position: "absolute",
        width: "100%",
        zIndex: 0,
      },
      search: {
        marginRight: 10,
        marginLeft: 10,
        height: 50,
        borderColor: colors.primary,
        borderWidth: 3,
        padding: 10,
        backgroundColor: colors.secondary + '80',
      },
      searchBox: {
        borderWidth: 0,
        outlineWidth: 0,
        fontSize: 16,
      },
    });
    return (
      <View style={[styles.outerRow, styles.row, { justifyContent: 'space-between' }]}>
        <Link to="/Dashboard">
          <TouchableOpacity
            onPress={() => this.expandJobs()}
          >
            <Image style={{ width: 150, height: 30, margin: 20 }} source={require('../../web/public/assets/icons/logo/logo.svg')} />
          </TouchableOpacity>
        </Link>
        <View style={styles.row}>
          {localStorage.getItem('role') == 'hr' ? (
            <View style={styles.row}>
              <Button onClick={() => this.props.stateHandler({ mailInvitationModal: true, trigger: 'topBar'})}>
                <CustomSVGIcon width="36px" height="36px" icon="Mail" color={colors.primary} />
              </Button>
              <View style={[styles.row, styles.search]}>
                <TextInput onChange={(e: any) => this.setState({ searchTerm: e.target.value })} style={styles.searchBox} />
                <Button style={{ marginRight: '-20px' }} onBlur={() => setTimeout(() => this.setState({ 'searchComplete': false }), 500)} onClick={() => this.search(this.props.searchURL ?? '', this.state.searchTerm)}>
                  <CustomSVGIcon width="32px" height="32px" icon="Search" color={colors.primary} />
                </Button>
                {this.searchBox()}
              </View>
            </View>
          ) : null}
          {localStorage.getItem('token') ? (
            <Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <TypoGraphy variant="body2" color="primary">{localStorage.getItem('role') == 'candidate' ? '#' + localStorage.getItem('applicationId') : ''}</TypoGraphy>
                <Button onClick={(e) => this.handleClick(e)}>
                  <TypoGraphy style={{cursor: 'pointer'}} variant="body2" color="primary">Log Out</TypoGraphy>
                </Button>
              </Box>
              <Box>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={this.state.logOutMenu}
                  keepMounted
                  open={Boolean(this.state.logOutMenu)}
                  onClose={() => this.handleClose()}
                >
                  <StyledMenuItem onClick={() => { localStorage.clear(); this.setState({ popOver: false }); this.props.history.push('/'); window.location.reload() }}>
                    <TypoGraphy cursor="pointer" variant="body2" color={colors.extra}>
                      Log Out
                    </TypoGraphy>
                  </StyledMenuItem>
                </StyledMenu>
              </Box>
            </Box>
          ) : null}
        </View>
      </View>
    );
  }
};


const mapStateToProps = (state: any) => ({
  state: state,
});

export default withRouter(connect(mapStateToProps)(TopNav));
