import React, { ReactElement } from 'react'
import { Box } from "@material-ui/core"
import FullCalendar from '@fullcalendar/react' // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid'

interface Props {
    events?: any,
    onClick?: any
}

export default function Calendar({ events }: Props): ReactElement {
    return (
        <Box>
            <FullCalendar
                plugins={[timeGridPlugin]}
                initialView="timeGridWeek"
                events={events}
                eventContent='Not available'
            />
        </Box>
    )
}
