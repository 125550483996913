import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  View,
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  Platform,
  Dimensions
} from "react-native";
import Dropzone from 'react-dropzone';
import { RFPercentage } from "react-native-responsive-fontsize"
import Test from '../../../components/src/Test'
import EiResults from '../../../components/src/EiResults'
import ProseResults from '../../../components/src/ProseResults'
import OceanResults from '../../../components/src/OceanResults'
import CompetencyResults from '../../../components/src/CompetencyResults'
import GenericCard from "../../../components/src/GenericCard"
import { Divider } from 'react-native-elements'
import { 
  Button, 
  Box,
  TextField,
  Typography, 
  ListItem, 
  List, 
  Collapse, 
  Menu, 
  MenuItem
} from '@material-ui/core'
import { Translation } from 'react-i18next'
import { TypoGraphy } from '../../../components/src/TypoGraphy'
import { palette, secondaryPalette } from '../../../components/src/Palette'
import { IconImage } from '../../../components/src/IconImage'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { expandFilter } from "../../../components/src/actions/index";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DocViewer, {PDFRenderer} from "react-doc-viewer";
import Pagination from '@material-ui/lab/Pagination';
import CustomSVGIcon from "../../../components/src/SVGIcon";
import { withStyles } from '@material-ui/styles';
import {compose} from 'redux'

// Customizable Area End

import DashboardGuestsController, {
  Props,
  configJSON
} from "./DashboardGuestsController";

const pageStyles = () => ({
  root: {
      "& .Mui-selected": {
        background: '#10069F',
        color: '#64CBBA',
        fontWeight: 'bold'
      }
  },
});

class DashboardGuests extends DashboardGuestsController {
  colors: any
  constructor(props: Props) {
    super(props);
    // Customizable Area Start  
    this.colors = this.props.state.updateTheme.present.colors  
    // Customizable Area End
  }

  // Customizable Area Start
    // async componentDidMount() {
    //   console.log("UNDER DID MOUNT");
    //   this.getCandidateList();
    //   this.getTests();
    // }
    

  showContent() {
    if (this.state.candidateCard) {
      this.setState({ candidateCard: false })
    } else {
      this.setState({ candidateCard: true })
    }
  }
  setCandidate(data: any) {
    this.setState({ candidateCard: true, candidateId: data.id }, () => this.getCandidateResults())
    this.getAllTests();
    this.getCandidateDetails(data.id);
  };

  setNotes = (event: any, id: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      notes: event.target.value,
      typing: false,
      typingTimeout: setTimeout( () => {
          this.addCandidateNotes(id);
        }, 5000)
    });
  }

  editUser() {
    this.setState({ editingUser: !this.state.editingUser });
    if(this.state.noteView === true){
      this.setState({
        noteView: false
      });
    }
  }

  submitResumeCover(id: any) {
    if(this.state.resumeFiles.length || this.state.coverLetters.length){
      this.uploadResumeCover(id)
    }
  }

  onResumeDrop = (files: any) => {
    let fileType: any = files[0].type
    let fileSize: any = (files[0].size/1024)/1024;
    if(fileType == 'application/pdf'){
      if(fileSize <= 0.5){
        this.setState({
          resumeName: files[0].path,
          resumeFiles: files
        })
      } else {
        this.setState({
          openSnackBar: true,
          snackType: 'error',
          profileError: 'File should be less than or equal to 0.5 mb!'
        })
      }
    } else {
      this.setState({
        openSnackBar: true,
        snackType: 'error',
        profileError: 'Select a pdf file'
      })
    }
  }

  onCoverDrop = (files: any) => {
    let fileType: any = files[0].type
    let fileSize: any = (files[0].size/1024)/1024;
    if(fileType == 'application/pdf'){
      if(fileSize <= 2){
        this.setState({
          coverLetters: files
        })
      } else {
        this.setState({
          openSnackBar: true,
          snackType: 'error',
          profileError: 'File should be less than or equal to 2 mb!'
        })
      }
    } else {
      this.setState({
        openSnackBar: true,
        snackType: 'error',
        profileError: 'Select a pdf file'
      })
    }
    
  }

  openDoc = () => {
    this.setState({
      openDocViewer: true
    })
  }

  closeDocViewer = () => {
    this.setState({
      openDocViewer: false
    })
  }

  openResume = () => {
    this.setState({
      openResumeViewer: true
    })
  }

  closeResumeViewer = () => {
    this.setState({
      openResumeViewer: false
    })
  }

  updateCandidateStatus(candidateData: any, statusType: string) {
    this.updateCandidateCardStatus(candidateData.id, statusType);
  }

  changePage  = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      currentPage: value
    }, () => {
      this.getFilteredCandidateList();
    })
  }

  showCandidate() {
    const resumeName: any = this.state.candidateDetailsData?.attributes?.resume_uri?.url.split('/');
    const coverName: any = this.state.candidateDetailsData?.attributes?.cover_letter_uri?.url.split('/')
    const user = (
      <Box>
        <Box style={{ padding: "1.5em", backgroundColor: palette.primary.main }}>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <TypoGraphy variant="h4" color="#fff">
                  {this.state.candidateDetailsData.id ? this.state.candidateDetailsData.attributes.first_name : "---"}
                </TypoGraphy>
              </Box>
              <Box>
                <Button onClick={() => {
                  if(this.state.editingUser){
                    this.editUser();
                    this.submitResumeCover(this.state.candidateDetailsData.id);
                  } else {
                    this.editUser();
                  }
                }}>
                  {
                    this.state.editingUser ?
                      <CheckCircleOutlineIcon style={{color: palette.secondary.main, fontSize: '2.5em'}}/>
                    : <Image style={{ height: "2em", width: "2em" }} source={require(`../assets/edit.svg`)} />
                  }
                </Button>
              </Box>
              <Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Image style={{ height: "2em", width: "2em" }} source={require(`../assets/profile.svg`)} />
                  <TypoGraphy variant="h5" color="#ffffff"> &nbsp;&nbsp;Current Employee</TypoGraphy>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button onClick={() => this.setState({ candidateCard: false })}>
                  <Image style={{ height: "2em", width: "2em" }} source={require(`../assets/expand-secondary.svg`)} />
                </Button>
                <Button onClick={() => this.updateCandidateStatus(this.state.candidateDetailsData, 'starred')}>
                  <Image style={{ marginLeft: "1.5em", marginRight: "1.5em", height: "2em", width: "2em" }} source={require(`../assets/starred.svg`)} />
                </Button>
                <Button onClick={() => this.updateCandidateStatus(this.state.candidateDetailsData, 'minimized')}>
                  <Image style={{ height: "2em", width: "2em" }} source={require(`../assets/minimize.svg`)} />
                </Button>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <TypoGraphy variant="h5" color="#ffffff">
                Applied:
              </TypoGraphy>
              <TypoGraphy variant="h5" fontWeight="light" color="#ffffff">
                &nbsp;{this.state.candidateDetailsData.attributes ? this.state.candidateDetailsData.attributes.applied === null ? "----/--/--" : this.formatDate(this.state.candidateDetailsData.attributes.applied) : "----/--/--"}
              </TypoGraphy>
            </Box>
          </Box>
          <Collapse in={this.state.editingUser}>
            <Box>
              <TypoGraphy color="#ffffff" style={{marginTop: '20px', textTransform: 'lowercase'}}>
                only pdf type of file will be accepted!
              </TypoGraphy>
            </Box>
          </Collapse>
          <Box style={{ marginTop: Dimensions.get('window').width >= 768 ? "8em" : "2.5em", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box flex="1">
              <TypoGraphy color="#ffffff">
                Resume:
                {
                  this.state.candidateDetailsData?.attributes?.resume_uri?.url && !this.state.editingUser ? <Box><span style={{cursor: 'pointer'}} onClick={this.openResume}>{resumeName ? resumeName[resumeName?.length - 1]: null}</span></Box>
                  :
                  <Dropzone onDrop={this.onResumeDrop} multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <section className="container">
                        <div {...getRootProps({className: 'dropzone'})}>
                          <input {...getInputProps()} />
                          {
                            !this.state.resumeFiles.length ?
                            <Typography 
                              style={{ 
                                display: this.state.editingUser ? 'flex' : 'none', 
                                textDecorationLine: "underline",
                                cursor: 'pointer'
                              }}
                            >
                              DRAG &amp; DROP FILE
                            </Typography>
                            : null
                          }
                          {
                            this.state.resumeFiles.length ? this.state.resumeFiles.map((resume: any) => {
                              return (
                                <Typography 
                                  style={{ 
                                    display: this.state.resumeFiles ? 'flex' : 'none'
                                  }}
                                >
                                  {resume.name}
                                </Typography>
                              )
                            })
                            : null
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                }
              </TypoGraphy>
            </Box>
            <Box flex="1.1">
              <TypoGraphy color="#ffffff">
                Cover Letter:
                {
                  this.state.candidateDetailsData?.attributes?.cover_letter_uri?.url && !this.state.editingUser ? <Box><span style={{cursor: 'pointer'}} onClick={this.openDoc}>{coverName ? coverName[coverName?.length - 1]: null}</span></Box>
                  :
                  <Dropzone onDrop={this.onCoverDrop} multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <section className="container">
                        <div {...getRootProps({className: 'dropzone'})}>
                          <input {...getInputProps()} />
                          {
                            !this.state.coverLetters.length ?
                            <Typography 
                              style={{ 
                                display: this.state.editingUser ? 'flex' : 'none', 
                                textDecorationLine: "underline" ,
                                cursor: 'pointer'
                              }}
                            >
                              DRAG &amp; DROP FILE
                            </Typography>
                            : null
                          }
                          {
                            this.state.coverLetters.length ? this.state.coverLetters.map((coverLetter: any) => {
                              return (
                                <Typography 
                                  style={{ 
                                    display: this.state.coverLetters ? 'flex' : 'none'
                                  }}
                                >
                                  {coverLetter.name}
                                </Typography>
                              )
                            })
                            : null
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                }
              </TypoGraphy>
            </Box>
          </Box>
        </Box>
        <Collapse in={!this.state.editingUser} style={{padding: '10px'}}>
          <Box style={{ marginTop: '10px', minHeight: '100px', padding: 10, border: '2px dashed', borderColor: palette.primary.main }}>
            {
              !this.state.noteView && this.state.candidateDetailsData?.attributes?.add_notes ? 
              this.state.notes
              : null
            }
          </Box>
        </Collapse>
        <Collapse in={this.state.editingUser} mountOnEnter unmountOnExit>
          <Box style={{ padding: 10, borderWidth: 3, borderColor: palette.primary.main }}>
            {
              !this.state.noteView ?
                <Typography 
                  style={{ 
                    padding: "3em", 
                    borderColor: palette.primary.main, 
                    borderWidth: 2, 
                    borderStyle: "dashed", 
                    textAlign: "center", 
                    color: palette.primary.main, 
                    textDecorationLine: "underline" 
                  }}
                  onClick={this.changeNoteView}
                >
                  {this.state.candidateDetailsData?.attributes?.add_notes ? 'EDIT NOTES' : 'ADD NOTES'}
                </Typography>
                : 
                  <TextField
                    multiline
                    rows={8}
                    autoFocus={true}
                    type="text"
                    value={this.state.notes}
                    onChange={(e) => this.setNotes(e, this.state.candidateDetailsData.id)}
                    placeholder={"Please add a note"}
                    InputProps={{ 
                      disableUnderline: true,  
                      style: {fontSize: '1em', color: 'rgb(16, 6, 159)'}
                    }}
                    style={{
                      border: "dashed 2px rgb(16, 6, 159)",
                      boxSizing: "border-box",
                      width: "100%",
                      outline: 'none',
                      padding: '10px'
                    }}
                  />
            }
          </Box>
        </Collapse>
        <Box style={{ marginTop: "5em" }}>
          {
            this.state.candidateResults.map((item: any) => {
              if(item.attributes.result.test_id == 1){
                return <CompetencyResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Digital Competencies - Self-assessment grid" desc={item.attributes.result} id={item.id} />
              } else if (item.attributes.result.test_id == 2){
                return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Problem Solving" id={item.id} />
              }else if (item.attributes.result.test_id == 3){
                return <OceanResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="OCEAN" id={item.id} />
              }else if (item.attributes.result.test_id == 4){
                return <EiResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result} testName="Emotional Intelligence" id={item.id} />
              }else if (item.attributes.result.test_id == 5){
                return <Test type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result} resultId={item.id} testName="ACER: Your Emotional Intelligence at a Glance" id={item.id} />
              } else if (item.attributes.result.test_id == 6){
                return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Document Literacy" id={item.id} />
              } else if (item.attributes.result.test_id == 7){
                return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Numeracy" id={item.id} />
              } else if (item.attributes.result.test_id == 8){
                return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Prose Literacy" id={item.id} />
              } else {
                return <Test type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result} resultId={item.id} testName="ACER: Your Emotional Intelligence at a Glance" id={item.id} />
              }
            })
          }
        </Box>
        <Divider style={{
          marginVertical: '5em', backgroundColor: "transparent"
        }} />
      </Box>
    )
    return user
  }
  getData() {
    const path = window.location.href.split('/', 5).pop()    
    const { classes } = this.props;
    if (path == 'all-results') {
      return (
        <View>
          <Box style={{ marginTop: "5em" }}>
            {
              this.state.candidateResults.map((item: any) => {
                if(item.attributes.result.test_id == 1){
                  return <CompetencyResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Digital Competencies - Self-assessment grid" desc={item.attributes.result} id={item.id} />
                } else if (item.attributes.result.test_id == 2){
                  return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Problem Solving" id={item.id} />
                }else if (item.attributes.result.test_id == 3){
                  return <OceanResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="OCEAN" id={item.id} />
                }else if (item.attributes.result.test_id == 4){
                  return <EiResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result} testName="Emotional Intelligence" id={item.id} />
                }else if (item.attributes.result.test_id == 5){
                  return <Test type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result} resultId={item.id} testName="ACER: Your Emotional Intelligence at a Glance" id={item.id} />
                } else if (item.attributes.result.test_id == 6){
                  return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Document Literacy" id={item.id} />
                } else if (item.attributes.result.test_id == 7){
                  return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Numeracy" id={item.id} />
                } else if (item.attributes.result.test_id == 8){
                  return <ProseResults type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result?.result} testName="Prose Literacy" id={item.id} />
                } else {
                  return <Test type={item.attributes.result === null ? "pending" : "declared"} route="results" mainDescription={item.attributes?.description} results={item.attributes.result} resultId={item.id} testName="ACER: Your Emotional Intelligence at a Glance" id={item.id} />
                }
              })
            }
          </Box>
        </View>
      )
    }

    if (path == 'all-tests') {
      const tests = this.state.tests.map((test: any) =>
        <Box width="100%" style={{ marginBottom: 15 }}>
          <GenericCard type={'Recent'}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" style={{ padding: '0.7em' }}>
              <Box flex="2" mt={1.5}>
                <TypoGraphy variant="subtitle1" color={"#ffffff"}>{test.test_title}</TypoGraphy>
                {/* <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ marginTop: '0.8em' }}>
                  <TypoGraphy variant="subtitle" color={test.status == 'Recent' ? "#ffffff" : palette.primary.main}>{test.org}</TypoGraphy>
                  <TypoGraphy fontWeight="light" variant="body2" color={test.status == 'Recent' ? "#ffffff" : palette.primary.main}>{test.candidates}</TypoGraphy>
                </Box> */}
              </Box>
              <Box>
                <Button onClick={() => this.props.navigation.navigate('ProjecttaskTracking', { id: test.test_id })}>
                  <IconImage height="1.5em" width="1.5em">
                    <img width="100%" src={require(`../assets/expand-Recent.svg`)} />
                  </IconImage>
                </Button>
              </Box>
            </Box>
          </GenericCard>
        </Box>
      );
      return tests;
    }
    if(path === 'hire-date' || path === 'interview-date' || path === 'application-submission-date' || path === 'star-rating'){
      const totalEntries = this.state.filteredCandidateListData ? this.state.filteredCandidateListData.length : 0
      const starred = ((this.state.filteredCandidateListData && this.state.filteredCandidateListData.filter(function (n: any, i: any) {
        return n.attributes.candidate_card_colour_type === 'starred';
      }).length) / totalEntries) * 100 + '%';
      const standard = ((this.state.filteredCandidateListData && this.state.filteredCandidateListData.filter(function (n: any, i: any) {
        return n.attributes.candidate_card_colour_type === 'standard' || n.attributes.candidate_card_colour_type === null ;
      }).length) / totalEntries) * 100 + '%';
      const minimized = ((this.state.filteredCandidateListData && this.state.filteredCandidateListData.filter(function (n: any, i: any) {
        return n.attributes.candidate_card_colour_type === 'minimized';
      }).length) / totalEntries) * 100 + '%';

      const candidates = this.state.filteredCandidateListData && this.state.filteredCandidateListData.length ? this.state.filteredCandidateListData.map((position: any) =>
        <Box style={{ marginRight: "2em", marginBottom: "2em" }}>
          <GenericCard type={!position.attributes.candidate_card_colour_type ? 'standard': position.attributes.candidate_card_colour_type}>
            <Box style={{ height: "15rem", width: "15rem" }}>
              <Box style={{ padding: '1em' }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom="2em">
                  <Button onClick={() => this.setCandidate(position)}>
                    <Image style={{ height: 24, width: 24 }} source={require(position.attributes.candidate_card_colour_type === "starred" ? `../assets/expand-secondary.svg` : `../assets/expand.svg`)} />
                  </Button>
                  <Button onClick={() => this.updateCandidateStatus(position, 'starred')}>
                    <Image style={{ height: 24, width: 24 }} source={require(position.attributes.candidate_card_colour_type === "starred" ? `../assets/starred.svg` : `../assets/outlined-starred.svg`)} />
                  </Button>
                  <Button onClick={() => this.updateCandidateStatus(position, 'minimized')}>
                    <Image style={{ height: 24, width: 24 }} source={require(position.attributes.candidate_card_colour_type === "starred" ? `../assets/minimize.svg` : `../assets/outlined-minimized.svg`)} />
                  </Button>
                </Box>
                <Box>
                  <TypoGraphy variant="h6" color={position.attributes.candidate_card_colour_type == 'starred' ? '#ffffff' : palette.primary.main}>{position.attributes.first_name}</TypoGraphy>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <TypoGraphy variant="subtitle1" color={position.attributes.candidate_card_colour_type == 'starred' ? '#ffffff' : palette.primary.main}>Applied: {this.formatDate(position.attributes.application_submission_date)}</TypoGraphy>
                    {/* <TypoGraphy variant="subtitle1" fontWeight="light" color={position.attributes.candidate_card_colour_type == 'starred' ? '#ffffff' : palette.primary.main}>&nbsp;&nbsp;{position.attributes.applied}</TypoGraphy> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </GenericCard>
        </Box>
      ) : <Box> <TypoGraphy variant="h6" justifyContent='center'>No data found!</TypoGraphy> </Box>
      return (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <ScrollView contentContainerStyle={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {
              this.state.loading ? 
              <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Image style={{ height: 34, width: 34 }} source={require(`../assets/loader.svg`)} />
              </Box> 
              : <>
                  {candidates}
                  <Box style={{width: '100%', display:'flex', justifyContent: 'center', marginBottom: '30px', marginTop: '30px'}}>
                    <Pagination 
                      count={this.state.filteredMetaData.total_pages} 
                      defaultPage={1}
                      page={this.state.currentPage}
                      onChange={this.changePage}
                      className={classes.root}
                    />
                  </Box>
                </>
            }
          </ScrollView>
          <View
            style={{
              marginLeft: 10,
              width: 20,
              height: "50%"
            }}
          >
            <View
              style={{
                width: '100%',
                backgroundColor: `${palette.primary.main}`,
                height: '33%',
                borderWidth: 2,
                borderColor: palette.primary.main
              }}
            />
            <View
              style={{
                width: '100%',
                backgroundColor: `${palette.secondary.light}`,
                height: '33%',
                borderWidth: 2,
                borderColor: palette.primary.main
              }}
            />
            <View
              style={{
                width: '100%',
                backgroundColor: `${secondaryPalette.gray.shade4}`,
                height: `${minimized}`,
                borderWidth: 2,
                borderColor: palette.primary.main
              }}
            />
          </View>
        </View>
      );
    } else {
      const totalEntries = this.state.candidateListData.id ? this.state.candidateListData.attributes.ceridian_employees.length : 0
      const starred = ((this.state.candidateListData.id && this.state.candidateListData.attributes.ceridian_employees.data.filter(function (n: any, i: any) {
        return n.candidate_card_colour_type === 'starred'; 
      }).length) / totalEntries) * 100 + '%';
      const standard = ((this.state.candidateListData.id && this.state.candidateListData.attributes.ceridian_employees.data.filter(function (n: any, i: any) {
        return n.candidate_card_colour_type === 'standard';
      }).length) / totalEntries) * 100 + '%';
      const minimized = ((this.state.candidateListData.id && this.state.candidateListData.attributes.ceridian_employees.data.filter(function (n: any, i: any) {
        return n.candidate_card_colour_type === 'minimized';
      }).length) / totalEntries) * 100 + '%';

      const candidates = this.state.candidateListData.id && this.state.candidateListData.attributes.ceridian_employees.data.length ? this.state.candidateListData.attributes.ceridian_employees.data.map((position: any) =>
        <Box style={{ marginRight: "2em", marginBottom: "2em" }}>
          <GenericCard type={position.attributes.candidate_card_colour_type === null ? 'standard': position.attributes.candidate_card_colour_type}>
            <Box style={{ height: "15rem", width: "15rem" }}>
              <Box style={{ padding: '1em' }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom="2em">
                  <Button onClick={() => this.setCandidate(position)}>
                    <Image style={{ height: 24, width: 24 }} source={require(position.attributes.candidate_card_colour_type === "starred" ? `../assets/expand-secondary.svg` : `../assets/expand.svg`)} />
                  </Button>
                  <Button onClick={() => this.updateCandidateStatus(position, 'starred')}>
                    <Image style={{ height: 24, width: 24 }} source={require(position.attributes.candidate_card_colour_type === "starred" ? `../assets/starred.svg` : `../assets/outlined-starred.svg`)} />
                  </Button>
                  <Button onClick={() => this.updateCandidateStatus(position, 'minimized')}>
                    <Image style={{ height: 24, width: 24 }} source={require(position.attributes.candidate_card_colour_type === "starred" ? `../assets/minimize.svg` : `../assets/outlined-minimized.svg`)} />
                  </Button>
                </Box>
                <Box>
                  <TypoGraphy variant="h6" color={position.attributes.candidate_card_colour_type == 'starred' ? '#ffffff' : palette.primary.main}>{position.attributes.first_name}</TypoGraphy>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <TypoGraphy variant="subtitle1" color={position.attributes.candidate_card_colour_type == 'starred' ? '#ffffff' : palette.primary.main}>Applied: {this.formatDate(position.attributes.application_submission_date)}</TypoGraphy>
                  </Box>
                </Box>
              </Box>
            </Box>
          </GenericCard>
        </Box>
      ) : <Box> <TypoGraphy variant="h6" justifyContent='center'>No data found!</TypoGraphy> </Box>
      return (
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <ScrollView contentContainerStyle={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {
              this.state.loading ? 
              <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Image style={{ height: 34, width: 34 }} source={require(`../assets/loader.svg`)} />
              </Box> 
              : candidates
            }
          </ScrollView>
          <View
            style={{
              marginLeft: 10,
              width: 20,
              height: "50%"
            }}
          >
            <View
              style={{
                width: '100%',
                backgroundColor: `${palette.primary.main}`,
                height: `${starred}`,
                borderWidth: 2,
                borderColor: palette.primary.main
              }}
            />
            <View
              style={{
                width: '100%',
                backgroundColor: `${palette.secondary.light}`,
                height: `${standard}`,
                borderWidth: 2,
                borderColor: palette.primary.main
              }}
            />
            <View
              style={{
                width: '100%',
                backgroundColor: `${secondaryPalette.gray.shade4}`,
                height: `${minimized}`,
                borderWidth: 2,
                borderColor: palette.primary.main
              }}
            />
          </View>
        </View>
      );
    }
  }

  expandRoles() {
    this.props.dispatch(
      expandFilter(true),
    );
  }

  dateFormat(date: any){
    var newDate = new Date(date);
    return newDate.getFullYear()+"/"+(newDate.getMonth()+1 )+"/"+newDate.getDate();
  }

  randomDate = (date1: any, date2: any) => {
    function randomValueBetween(min: any, max: any) {
      return Math.random() * (max - min) + min;
    }
    var date1 = date1 || '01-01-1970'
    var date2 = date2 || new Date().toLocaleDateString()
    date1 = new Date(date1).getTime()
    date2 = new Date(date2).getTime()
    if( date1>date2){
        return new Date(randomValueBetween(date2,date1)).toLocaleDateString()   
    } else{
        return new Date(randomValueBetween(date1, date2)).toLocaleDateString()  

    }
  }

  showFilterLabel() {
    const type = window.location.href.split('/', 5).pop()
    let list: any
    let button: any
    switch (type) {
      case 'roles':
        list = (
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography variant="h2" color="primary">
                {this.state.candidateListData.id ? this.state.candidateListData.attributes.title : "---"}
              </Typography>
              <Typography variant="h4" color="primary">
                {this.state.candidateListData.id ? this.state.candidateListData.attributes.city : "---"}
              </Typography>
            </Box>
            <Box>
              <Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="h5" color="primary">
                    Posting Date:
                  </Typography>
                  <TypoGraphy variant="body2" fontWeight="light" fontSize="1em" color={palette.primary.main}>
                    &nbsp; {this.state.candidateListData.id ? this.formatDate(this.state.candidateListData.attributes.date_posted) : "---"}
                  </TypoGraphy>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="h5" color="primary">
                    Closing Date:
                  </Typography>
                  <TypoGraphy variant="body2" fontWeight="light" fontSize="1em" color={palette.primary.main}>
                    &nbsp; {this.state.candidateListData.id ? this.formatDate(this.state.candidateListData.attributes.last_updated) : "---"}
                  </TypoGraphy>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h5" color="primary">
                  Status:
                </Typography>
                <TypoGraphy variant="body2" fontWeight="light" fontSize="1em" color={palette.primary.main}>
                  &nbsp; {this.state.candidateListData.id ? this.state.candidateListData.attributes.job_status : "---"}
                </TypoGraphy>
              </Box>
            </Box>
          </Box>
        )
        break
    }
    button = (
      <Box marginTop="3em" marginBottom="3em" display="flex" flexDirection="row" alignItems="center">
        {/* <Button variant="contained" 
          // onClick={() => this.setState({ positionFilter: !this.state.positionFilter })}
          onClick={this.openFilter}
        > */}
        <span
          style={{ padding: 0, justifyContent: "flex-start" }}
        >
          <CustomSVGIcon style={{cursor: 'pointer'}} color={this.colors.primary} width="36px" height="36px" icon="Filter" onClick={this.openFilter}/>
        </span>
          {/* <Image        onClick={this.openFilter} style={{ height: 36, width: 36 }} source={require('../assets/filter.svg')} /> */}
        {/* </Button> */}
        <Menu
            id="demo-positioned-menu"
            open={this.state.anchorEl}
            onClose={this.closeFilter}
            getContentAnchorEl={null}
            style={{
              marginTop: '12em',
              marginLeft: '5em'
            }}
            // anchorOrigin={{
            //   vertical: 'top',
            //   horizontal: 'right',
            // }}
            // transformOrigin={{
            //   vertical: 'top',
            //   horizontal: 'right',
            // }}
          >
            <MenuItem
              onClick={this.closeFilter}
            >
              <Translation>
                {(t: any) => (
                  <span style={{color: '#000', fontFamily: 'Campton-Bold'}}>
                    {t("filterText")}
                  </span>
                )}
              </Translation>
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.closeFilter();
                this.expandRoles();
                this.props.navigation.navigate('Dashboard', {type: "roles"});
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Role')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
            <MenuItem onClick={() => {
              this.closeFilter();
              this.sortCandidateList('hire-date');
              this.props.navigation.navigate("DashboardGuests", {
                type: "hire-date",
              });
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Hire Date')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
            <MenuItem onClick={() => {
              this.closeFilter();
              this.sortCandidateList('interview-date');
              this.props.navigation.navigate("DashboardGuests", {
                type: "interview-date",
              });
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Interview Date')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
            <MenuItem onClick={() => {
              this.closeFilter();
              this.sortCandidateList('application-submission-date');
              this.props.navigation.navigate("DashboardGuests", {
                type: "application-submission-date",
              });
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Application Submission Date')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
            <MenuItem onClick={() => {
              this.closeFilter();
              this.sortCandidateList('star-rating');
              this.props.navigation.navigate("DashboardGuests", {
                type: "star-rating",
              });
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Star Rating')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
            <MenuItem onClick={() => {
              this.closeFilter();
              this.props.navigation.navigate("DashboardGuests", {
                type: "all-results",
              });
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Show all results')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
            <MenuItem onClick={() => {
              this.closeFilter();
              this.props.navigation.navigate('DashboardGuests', { type: 'all-tests' });
            }}>
              <Translation>
                {
                  (t) => <TypoGraphy style={{cursor: "pointer"}} variant="subtitle2" color="#000000">{t('Show all available test')}</TypoGraphy>
                }
              </Translation>
            </MenuItem>
          </Menu>
        <Box display={this.state.positionFilter == true ? 'flex' : 'none'}>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <span onClick={() => this.setState({ candidateCard: false })}>
            <TypoGraphy color={this.colors.primary} variant="h5" style={{ textTransform: "capitalize", textDecorationLine: 'underline', marginLeft: "1em" }}>
              {type === 'roles' ? 'Candidates' : type}
            </TypoGraphy>
          </span>
          <Box style={{ display: this.state.candidateCard ? "flex" : "none", flexDirection: "row" }}>
            <TypoGraphy color={this.colors.primary} variant="h5">{' > '}</TypoGraphy>
            <TypoGraphy style={{ textTransform: "capitalize" }} color={this.colors.primary} variant="h5"> &nbsp;{this.state.candidateDetailsData.id ? this.state.candidateDetailsData.attributes.first_name : "---"}</TypoGraphy>
          </Box>
        </Box>
      </Box>
    )
    return (
      <View>
        {list}
        {button}
      </View>
    )
  }

  handleClose = () => {
    this.setState({
      openSnackBar: false
    })
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
      <Snackbar open={this.state.openSnackBar} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={this.handleClose} severity={this.state.snackType}>
          <AlertTitle>{this.state.snackType == 'error' ? 'Error' : 'Success'}</AlertTitle>
          {this.state.profileError}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        open={this.state.openDocViewer}
        onClose={this.closeDocViewer}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          {console.log("DOC URL", this.state.candidateDetailsData?.attributes?.cover_letter_uri?.url, this.state.pathState)}
          <DocViewer pluginRenderers={[PDFRenderer]} documents={[{uri: "https://knowmeqcore-28018-ruby.28018.dev.ap-southeast-1.aws.svc.builder.cafe"+this.state.candidateDetailsData?.attributes?.cover_letter_uri?.url}]} />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={this.state.openResumeViewer}
        onClose={this.closeResumeViewer}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DocViewer pluginRenderers={[PDFRenderer]} documents={[{uri: this.state.pathState+this.state.candidateDetailsData?.attributes?.resume_uri?.url}]} />
        </DialogContent>
      </Dialog>
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <Box
          onClick={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>
            {this.showFilterLabel()}
            <View style={{ zIndex: -1, display: this.state.candidateCard == true ? 'none' : 'flex' }}>
              {this.getData()}
            </View>
            <View style={{ display: this.state.candidateCard == false ? 'none' : 'flex' }}>
              {this.showCandidate()}
            </View>
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </Box>
      </ScrollView >
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "2.5em",
    marginRight: "auto",
    width: Platform.OS === "web" ? "85%" : "100%",
    backgroundColor: "#ffffff",
    overflow: "hidden",
  },
  title: {
    color: `${palette.primary.main}`,
    fontSize: RFPercentage(3.5),
    fontWeight: 'bold',
    marginBottom: '0.3em'
  },
  subTitle: {
    color: `${palette.primary.main}`,
    fontSize: RFPercentage(2.5),
    fontWeight: 'bold'
  },
  labels: {
    color: `${palette.primary.main}`,
    fontSize: RFPercentage(1.5),
    fontWeight: 'bold'
  },
  subLabels: {
    color: `${palette.primary.main}`,
    fontSize: RFPercentage(1.5),
    fontWeight: '300'
  },
  BoxInline: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  }
});
// Customizable Area End


const mapStateToProps = (state: any) => ({
  state: state,
});

// export default connect(mapStateToProps)(withTranslation()(DashboardGuests));
export default compose(
  withStyles(pageStyles),
  withTranslation(),
  connect(mapStateToProps)
)(DashboardGuests);

