import styled from 'styled-components/native'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from "react-native-responsive-screen";
import { RFPercentage } from "react-native-responsive-fontsize";

// Define what props.theme will look like
export const palette = {
  primary: {
    main: "#10069f"
  }, // primary color
  secondary: {
    main: "#64CBBA",
    shade1: "#83d5c7",
    shade2: "#a2dfd5",
    shade3: "#c1eae3",
    shade4: "#e0f4f1"
  }, // secondary color
  gray: {
    main: "#000000",
    shade1: "#333333",
    shade2: "#666666",
    shade3: "#999999",
    shade4: "#cccccc"
  }
};

export const color = (color: string) => {
  const palette: any = {
    primary_main: "#10069f",
    secondary_main: "#64CBBA",
    secondary_shade1: "#83d5c7",
    secondary_shade2: "#a2dfd5",
    secondary_shade3: "#c1eae3",
    secondary_shade4: "#e0f4f1",
    gray_main: "#000000",
    gray_shade1: "#333333",
    gray_shade2: "#666666",
    gray_shade3: "#999999",
    gray_shade4: "#cccccc"
  }
  return palette[color]
}

export const PositionCardColor = (type: string) => {
  switch (type) {
    case "Starred":
      return `${palette.secondary.shade3}`
    case "Closed":
      return `${palette.gray.shade4}`
    case "Recent":
      return `${palette.primary.main}`
    default:
      return "#f56342";
  }
};

export const PositionCardTextColor = (type: string) => {
  switch (type) {
    case "Starred":
      return `${palette.primary.main}`
    case "Closed":
      return `${palette.gray.shade3}`
    case "Recent":
      return "#ffffff"
    default:
      return "#f56342";
  }
};

const text = styled.Text`
  fontFamily : Campton-Bold
`

export const StyledView = styled.View`
  width: 100%;
`
export const Title = styled.Text`
  font-size: ${RFPercentage(5)};
  color: ${palette.primary.main};
  fontFamily: Campton-Bold
`
export const description = styled.Text`
  font-size: ${RFPercentage(1.7)};
  color: #000000;
  line-height: 30px;
  fontFamily: Campton-Light
`
export const directions = styled.Text`
  font-size: ${RFPercentage(1.7)};
  color: ${palette.primary.main};  
  textAlign: center;
  fontFamily: Campton-Bold
`
export const QuestionType = styled.View`
  font-size: ${RFPercentage(1.7)};
`
export const RatingView = styled.View`
display: flex;
flex-direction: row;
align-items: center
`
export const RatingText = styled.Text`
  font-size: ${RFPercentage(1.7)};
  color: #000000;
  text-align: center;
  fontFamily: Campton-Bold
`
export const SubTitle = styled(Title)`
  text-align: center;
  font-size: ${RFPercentage(4)};
`
export const QuestionBox = styled(QuestionType)`
  background-color : #f2f2f2
`
export const Button = styled.TouchableOpacity`
  backgroundColor: transparent;
  justifyContent: center;
  borderWidth: 3;
  borderColor: ${palette.primary.main} 
  borderRadius: 50%;
  width: 2.8em;
  height: 2.8em;
`

export const SelectedRoundButton = styled.TouchableOpacity`
  backgroundColor: ${palette.secondary.main};
  justifyContent: center;
  borderWidth: 3;
  borderColor: ${palette.primary.main} 
  borderRadius: 50%;
  width: 2.8em;
  height: 2.8em;
`
export const ProgressButton = styled.TouchableOpacity`
  backgroundColor: ${palette.primary.main};
  color: ${palette.secondary.main};
  padding: 1em 4em;
  borderWidth: 3;
  borderColor: ${palette.primary.main}
`
export const ProgressButtonText = styled(RatingText)`
  color: ${palette.secondary.main};  
`
export const AnswerButton = styled(ProgressButton)`
  color: ${palette.primary.main}
  background: transparent
  borderColor: ${palette.primary.main}
  borderWidth: 3  
`
export const SelectedAnswerButton = styled(ProgressButton)`
  color: ${palette.secondary.main}
  background: ${palette.secondary.main}
  borderColor: ${palette.primary.main}
  borderWidth: 3  
`

export const CustomButton = styled(AnswerButton)`
  color: ${palette.secondary.main}
  marginHorizontal: 0.8em
  padding: 0
`
export const CustomButtonText = styled(ProgressButtonText)`
  color: ${palette.primary.main}
  backgroundColor: ${palette.secondary.main}
  fontSize: 0.8em
  padding: 1em  
`
export const CalendarTitle = styled(directions)`
  color: ${palette.secondary.main}
  fontSize: 1em
  marginLeft: 1.5em
  fontFamily: "Campton-Bold"
  font-weight: normal;
`
export const PositionWrapper = styled.View`
  width: 90%;
`

export const PositionCard = styled.View`
  backgroundColor: ${({ className }: any) => PositionCardColor(className)};
  borderWidth: 3px;
  borderColor: ${({ className }: any) => PositionCardTextColor(className)};
  marginBottom: 2.5em;
  padding: 1em;
  display: flex;
  flexDirection: row;
  justifyContent: space-between
`
export const WelcomeHeading = styled(text)`
  color : ${(props: any) => props.color}
  fontSize: ${(props: any) => RFPercentage(props.fontSize) || RFPercentage(5)}
  `
export const SubHeading = styled(text)`
  textAlign: left
  color : ${(props: any) => props.color}
  fontSize: ${(props: any) => RFPercentage(props.fontSize) || RFPercentage(1.7)}
`