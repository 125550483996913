import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import _ from 'lodash'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  testInfo: any;
  testId: number;
  ansPayload: any;
  setAns: any;
  defaultAns: any;
  draftPayload: any;
  ansObj: any;
  draftStatus: any;
  ansDefaultObj: any;
  testError: any;
  singleResult: any;
  halfwayMark: boolean;
  openSnackBar: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProjecttaskTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {

  GetTestCalculatorId: string = "";
  GetTestInfoId: string = "";
  PostTestDraftApiId: string = "";
  GetTestDraftListApiId: string = "";
  UpdateTestDraftApiId: string = "";
  PostResultApiId: string = "";
  GetCandidateTestResult: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    if (!localStorage.getItem('token')) {
      window.location.href = '/'
    }

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      testInfo: undefined,
      testId: 1,
      ansPayload: {},
      setAns: [],
      defaultAns: {},
      draftPayload: {},
      ansObj: {},
      draftStatus: 'pending',
      ansDefaultObj: {},
      testError: '',
      singleResult: {},
      halfwayMark: false,
      openSnackBar: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    if (this.state.draftStatus === 'pending') {
      // this.getTestInfo(Number(window.location.href.split('/').pop()))
    }
    this.getDraftAnswers(Number(window.location.href.split('/').pop()))
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {

        //Test questions list thirdparty api response
        if (apiRequestCallId === this.GetTestInfoId) {
          if (responseJson && !responseJson.error) {
            let companyId: any = localStorage.getItem('company_id');
            let userId: any = localStorage.getItem('user_id');
            // responseJson[0].questions.map((item: any, index: any) => {
            //   responseJson[0].questions[index]['answer'] = null
            // })
            responseJson[0]['status'] = 'pending';
            responseJson[0]['account_id'] = parseInt(userId);
            responseJson[0]['company_id'] = parseInt(companyId);
            // delete responseJson[0]['answers'];
            this.setState({ draftPayload: responseJson[0] }, () => {
              this.draftAnswers();
            })
          }
        }

        //Create draft response
        if (apiRequestCallId === this.PostTestDraftApiId) {
          if (responseJson && !responseJson.error) {
            if (responseJson.message === "Draft successfully create") {
              this.getDraftAnswers(Number(window.location.href.split('/').pop()))
            }
          }
        }

        //Update draft response
        if (apiRequestCallId === this.UpdateTestDraftApiId) {
          if (responseJson && !responseJson.error) {
            if (responseJson) {
              this.getDraftAnswers(Number(window.location.href.split('/').pop()))
            }
          }
        }

        // Draft answers List response
        if (apiRequestCallId === this.GetTestDraftListApiId) {
          if (responseJson && !responseJson.error) {
            if (!responseJson.data.length) {
              this.getTestInfo(Number(window.location.href.split('/').pop()))
            } else {
              let testInfo: any = {};
              responseJson.data.map((item: any, index: any) => {
                // let answers: any = item.attributes.answers.sort(function (a: any, b: any) {
                //   return a.answer_point - b.answer_point;
                // });
                testInfo = {
                  "test_id": item.attributes.test_id,
                  "test_title": item.attributes.title,
                  "test_description": item.attributes.description,
                  "account_id": item.attributes.account_id,
                  "company_id": item.attributes.company_id,
                  "status": item.attributes.status,
                  "answers": item.attributes.answers,
                  "questions": []
                }
                item.attributes.questions.map((q: any, i: any) => {
                  testInfo.questions[i] = {
                    "answer": q.attributes.answer,
                    "question_id": q.attributes.question_id,
                    "question_title": q.attributes.question_title,
                    "question_type": q.attributes.question_type,
                    "question_level": q.attributes.question_level,
                    "category_name": q.attributes.category_name,
                    "category_description": q.attributes.category_description,
                    "hierarchy": q.attributes.hierarchy
                  }
                });
              });
              testInfo.questions.sort(function (a: any, b: any) {
                return a.question_id - b.question_id;
              });
              this.setState({
                testInfo: testInfo,
                ansDefaultObj: testInfo
              })
            }
          }
        }

        if (apiRequestCallId === this.GetTestCalculatorId) {
          if (responseJson?.Error || !responseJson) {
            this.setState({
              testError: responseJson?.Error ? responseJson?.Error : 'Something went wrong!',
              openSnackBar: true
            })
          } else {
            this.getCandidateTestResult();
          }
        }

        if (apiRequestCallId === this.GetCandidateTestResult) {
          if (responseJson.Error) {
            this.setState({
              testError: responseJson.Error,
              openSnackBar: true
            })
            console.log("Something went wrong");
          } else {
            let ansDefaultObj: any = this.state.ansDefaultObj;
            ansDefaultObj['status'] = 'completed';

            let result: any = {};
            let userId: any = localStorage.getItem('user_id');

            result = {
              "candidate_test_results":
              {
                "user_id": parseInt(userId),
                "result": responseJson
              }
            }

            this.setState({
              draftPayload: ansDefaultObj,
              singleResult: result
            }, () => {
              this.updateDraftAnswers();
              this.postResults();
              this.props.navigation.navigate('AccountScoreranking');
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  /**
   * fetch the test info based on the ID passed in the url params
   */

  getTestInfo(id: number): boolean {
    const header = {
      'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetTestInfoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testInfoAPIEndpoint + id + '?format=json'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  getCandidateTestResult(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateTestResult = requestMessage.messageId;
    let userId: any = localStorage.getItem('user_id');
    let testId: any = Number(window.location.href.split('/').pop());

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCnadidateTestResult + "?user_id=" + userId + "&test_id=" + testId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  draftAnswers(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PostTestDraftApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testQuestionDraftAPIEndpoit
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.draftPayload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  updateDraftAnswers(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateTestDraftApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testQuestionDraftUpdateAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.draftPayload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  getDraftAnswers(id: number): boolean {
    const userId: any = localStorage.getItem('user_id');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetTestDraftListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestDraftAPIEndpoit + "?test_id=" + id + "&user_id=" + parseInt(userId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  getTestCalculator(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetTestCalculatorId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.testCalculatorAPIEndpoit
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.ansPayload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  postResults(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PostResultApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postResultsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.singleResult)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  // Customizable Area End
}
