import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import Affirmations from './icons/Affirmations';
import Back from './icons/Back';
import Check from './icons/Check';
import Close from './icons/Close';
import Contact from './icons/Contact';
import ContractLeft from './icons/ContractLeft';
import Customizer from './icons/Customizer';
import Dash from './icons/Dash';
import Empty from './icons/Empty';
import Expand from './icons/Expand';
import ExpandRight from './icons/ExpandRight';
import Filter from './icons/Filter';
import Fullscreen from './icons/Fullscreen';
import Knowmeq from './icons/Knowmeq';
import Lang from './icons/Lang';
import Mail from './icons/Mail';
import Mute from './icons/Mute';
import Next from './icons/Next';
import Pause from './icons/Pause';
import Play from './icons/Play';
import Result from './icons/Result';
import Search from './icons/Search';
import Survey from './icons/Survey';
import Time from './icons/Time';
import Tutorial from './icons/Tutorial';
import Unmute from './icons/Unmute';
import Website from './icons/Website';

function CustomSVGIcon(props: any) {
	
	const returnIcons = () => {
		switch (props.icon) {

			case "Affirmations":
				return <Affirmations color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Back":
				return <Back color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Check":
				return <Check color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Close":
				return <Close color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Contact":
				return <Contact color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "ContractLeft":
				return <ContractLeft color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Customizer":
				return <Customizer color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Dash":
				return <Dash color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Empty":
				return <Empty color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Expand":
				return <Expand color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "ExpandRight":
				return <ExpandRight color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Filter":
				return <Filter color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Fullscreen":
				return <Fullscreen color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Knowmeq":
				return <Knowmeq color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Lang":
				return <Lang color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Mail":
				return <Mail color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Mute":
				return <Mute color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Next":
				return <Next color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Pause":
				return <Pause color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Play":
				return <Play color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Result":
				return <Result color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Search":
				return <Search color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Survey":
				return <Survey color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Time":
				return <Time color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Tutorial":
				return <Tutorial color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Unmute":
				return <Unmute color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;

			case "Website":
				return <Website color={props.color ?? props.state.updateTheme.present.colors.secondary} />;
				break;


			default:
				break;
		}
	}
	const styles = makeStyles({
		icon: {
			width: props.width,
			height: props.height,
			margin: props.margin ?? "auto"
		},
	});
	const classes = styles();
	return (
		<Box {...props} className={classes.icon}>
			{returnIcons()}
		</Box>
	)
}

const mapStateToProps = (state: any) => ({
	state: state,
});

export default connect(mapStateToProps)(CustomSVGIcon);
