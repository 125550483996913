import React from "react";

export default function Website(props: any) {
  return (
    <svg viewBox="0 0 72 72" fill={props.color}>
      <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
      <path d="M25.3137 30.9218L21.9032 41.2292H18.8716L17.0527 35.2418L15.1579 41.2292H12.1263L8.71582 30.9218H12.3537L13.7937 36.8334L15.6885 30.9218H18.4169L20.3116 36.8334L21.7516 30.9218H25.3137ZM47.8232 26.8292L43.0485 41.305H39.6379L36.7579 32.4376L33.8779 41.305H30.4674L25.7685 26.7534H29.5579L32.2106 35.7724L35.2421 26.8292H38.2737L41.3053 35.7724L44.0337 26.8292H47.8232V26.8292ZM63.6632 30.9218L60.2527 41.2292H57.2211L55.3263 35.2418L53.4316 41.2292H50.4L46.9895 30.9218H50.6274L52.0674 36.8334L53.9621 30.9218H56.6906L58.5853 36.8334L60.0253 30.9218H63.6632Z" />
    </svg>
  );
}
