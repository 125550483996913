import React from 'react';
import { Box, Modal, Button, withStyles } from '@material-ui/core';
import ReactPlayer from "react-player";
import LinearProgress from "@material-ui/core/LinearProgress";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import Slider from "@material-ui/core/Slider";
import clsx from "clsx";
import CustomSVGIcon from "../../SVGIcon";
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import * as Helper from '../../../helpers/Helpers';

export default function Player(Props: {
    open: boolean,
    videoURL: string,
    onClose: any,
    onClosePlayer: any
}) {
    // --- state variables start --- //
    const [seek, setSeek] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const [overlay, setOverlay] = React.useState("flex");
    const [mute, mutePlayer] = React.useState(true);
    const [playing, setPlaying] = React.useState(true);

    // --- state variables end --- //

    // --- refs start --- //
    const ref: any = React.useRef();
    // --- refs end --- //

    const handleSeekChange = (e: any) => {
        setSeek((e / duration) * 100);
    };

    const handleSeek = (e: any, value: any) => {
        setSeek((value / duration) * 100);
        ref.current.seekTo(value);
    };

    const handleDuration = (e: any) => {
        setDuration(e);
    };

    const handleProgress = (state: any) => {
        setSeek((state.playedSeconds / duration) * 100);
    };

    const handleClickFullscreen = () => {
        if (screenfull.isEnabled) {
            if (ref.current !== null) {
                screenfull.request(findDOMNode(ref.current) as Element);
            }
        }
    };


    // --- styles start --- //
    const useStyles = makeStyles({
        playContainer: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "#0000008f",
            top: 0,
            margin: "auto",
        },
    });
    // --- styles end --- //
    const classes = useStyles();

    // a HOC for video player seekbar start
    const Seeker = withStyles({
        root: {
            color: "#282826",
            height: 8,
            padding: 0,
            bottom: 12,
            zIndex: 9,
            opacity: 0,
        },
        thumb: {
            display: "none",
        },
        active: {},
        valueLabel: {
            left: "calc(-50% + 4px)",
        },
        track: {
            height: 20,
            backgroundColor: "red",
        },
        rail: {
            height: 20,
        },
    })(Slider);

    const SeekBar = withStyles((theme) => ({
        root: {
            height: 20,
            borderRadius: 0,
            bottom: 24,
        },
        colorPrimary: {
            backgroundColor: "#282826",
        },
        bar: {
            borderRadius: 0,
            backgroundColor: "ff0000",
        },
    }))(LinearProgress);
    // a HOC for video player seekbar stop

    return (
        <Box display="flex" flexDirection={isMobile && Helper.isPotrait() ? 'column' : 'row'} width="100%" justifyContent="space-between">
            <Box p={2} overflow="auto">
                <Box style={{ position: "relative" }} onMouseOver={() => setOverlay("flex")} onMouseLeave={() => setOverlay("flex")}>
                    <ReactPlayer
                        url={Props.videoURL}
                        width="100%"
                        playing={playing}
                        height="440px"
                        controls={false}
                        onSeek={handleSeekChange}
                        onProgress={handleProgress}
                        onDuration={handleDuration}
                        ref={ref}
                        muted={mute}
                        volume={1}
                    />
                    <Box display={overlay} className={clsx(classes.playContainer)} alignItems="center" flexDirection="row" justifyContent="center">
                        <Button onClick={() => setPlaying(!playing)}>
                            <CustomSVGIcon width="48px" icon={playing ? "Pause" : "Play"} />
                        </Button>
                        <Button onClick={() => mutePlayer(!mute)} style={{ width: '50px', height: '50px', position: 'absolute', right: "3em", bottom: 0 }}>
                            <CustomSVGIcon width="48px" icon={mute ? "Mute" : "Unmute"} />
                        </Button>
                        <Button onClick={() => handleClickFullscreen()} style={{ width: '50px', height: '50px', position: 'absolute', right: 0, bottom: "0.5em" }}>
                            <CustomSVGIcon width="48px" icon="Fullscreen" />
                        </Button>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flexGrow="1">
                        <Seeker
                            valueLabelDisplay="auto"
                            step={0.01}
                            min={0}
                            max={duration}
                            onChange={handleSeek}
                            defaultValue={0}
                        />
                        <SeekBar
                            variant="determinate"
                            value={seek}
                        />
                    </Box>
                </Box>
            </Box>
            <Box p={2} display="flex" flexDirection={!(isMobile && Helper.isPotrait()) ? 'column' : 'row'} justifyContent="space-between">
                <Box>
                    <Button onClick={() => { Props.onClosePlayer() }}>
                        <CustomSVGIcon width="64px" icon="Tutorial" />
                    </Button>
                </Box>
                <Box>
                    <Button onClick={() => Props.onClose(false)}>
                        <CustomSVGIcon width="64px" icon="Knowmeq" />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
