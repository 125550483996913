export const palette = {
    primary: {
        main: '#10069F',
    },
    secondary: {
        light: '#64CBBA',
        main: '#64CBBA',
    },
    info: {
        main: '#ff00ff'
    },
    error: {
        main: '#ff0000'
    },
    text:{
        primary: '#ffffff',
        secondary: '#000000'
    }
}

export const secondaryPalette = {
    gray: {
        main: "#000000",
        shade1: "#333333",
        shade2: "#666666",
        shade3: "#999999",
        shade4: "#cccccc",
        shade5: "#f2f2f2",
    }
}