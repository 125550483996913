import React from "react";

export default function Dash(props: any) {
  return (
    <svg viewBox="0 0 72 72" fill={props.color}>
      <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
      <path d="M31.149 21.5245V36.0003H16.6732V21.5245H31.149ZM34.7111 17.9624H13.1111V39.6382H34.7869V17.9624H34.7111Z" />
      <path d="M56.3105 36.227V51.688H40.9252V36.227H56.3105ZM60.1757 32.4375H37.1357V55.4775H60.1757V32.4375Z" />
      <path d="M53.2807 13.6421H37.0618V29.861H53.2807V13.6421Z" />
      <path d="M34.7844 42.1387H18.5654V58.3576H34.7844V42.1387Z" />
    </svg>
  );
}
