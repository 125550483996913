import React from "react";

export default function Customizer(props: any) {
	return (
		<svg
			fill={props.color}
			version="1.1"
			id="icn-check"
			x="0px"
			y="0px"
			viewBox="0 0 72 72"
		>
			<path d="M36,72C16.1,72,0,55.9,0,36S16.1,0,36,0s36,16.1,36,36S55.9,72,36,72z M36,3C17.8,3,3,17.8,3,36s14.8,33,33,33 s33-14.8,33-33S54.2,3,36,3z" />
			<path d="M14.7,16.7v7.4v31.3h42.6V24.1v-7.4H14.7z M53.3,51.3H18.7V24.1h34.6V51.3z M23,45v-2h14.3  c0.4-1.1,1.5-1.9,2.7-1.9c1.2,0,2.3,0.8,2.7,1.9H49v2h-6.4c-0.4,1.1-1.5,1.9-2.7,1.9c-1.2,0-2.3-0.8-2.7-1.9H23z M40.1,32.7H23v-2  h17.2c0.4-1.1,1.5-1.9,2.7-1.9c1.2,0,2.3,0.8,2.7,1.9H49v2h-3.5c-0.4,1.1-1.5,1.9-2.7,1.9C41.6,34.6,40.6,33.8,40.1,32.7z   M28.1,38.9H23v-2h5.1c0.4-1.1,1.5-1.9,2.7-1.9c1.2,0,2.3,0.8,2.7,1.9H49v2H33.4C33,40,32,40.7,30.8,40.7  C29.5,40.7,28.5,40,28.1,38.9z" />
		</svg>
	);
}
