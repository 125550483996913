import React, { useEffect, useReducer } from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Checkbox,
    useTheme,
    ThemeOptions,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { TypoGraphy } from "../TypoGraphy";
import FormField from "../FormField";
import clsx from "clsx";
import { connect } from "react-redux";
import CustomSVGIcon from "../SVGIcon";
import axios from "axios";
import * as URL from "../config";
import { useTranslation } from "react-i18next";
import KnowMeButton from "../Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { palette } from "../Palette";

const icon = <CheckBoxOutlineBlankIcon style={{ color: 'rgb(16, 6, 159)', fontSize: 25 }} />;
const checkedIcon = <CheckBoxIcon style={{ color: 'rgb(16, 6, 159)', fontSize: 25 }} />;
const initialState = { selectedOptions: [] };

function reducer(state: any, action: any) {
    switch (action.type) {
        case "SET_SELECTED_OPTIONS":
            return { selectedOptions: action.payload.options };
        default:
            throw new Error();
    }
}

interface MailInvitation {
    mail: any,
    trigger: any
}

const MailInvitation = (Props: MailInvitation) => {
    // hooks start
    /**
     * get app theme
     */
    const theme: any = useTheme();
    const { t, i18n } = useTranslation();
    // hooks end

    // component state start
    const [test, setTest] = React.useState<any>([]);
    const [emails, setEmails] = React.useState<any>('');
    const [message, setInviteMessage] = React.useState<any>('');
    const [testInfo, setTestInfo] = React.useState([]);
    const [successMessage, setMessage] = React.useState<any>(false);
    const [loading, setLoading] = React.useState<any>(false);
    const [errorMessage, setErrorMessage] = React.useState<any>(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    // component state end

    /**
     * Function to handle onChange event of test dropdown
     * @param values selected tests
     */
    const selectTest = (e: any, values: any) => {
        let selectedTests: any = [];
        dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
        if (values) {
            values.map((value: any) => {
                selectedTests.push(value.test_id);
            })
            setTest(selectedTests);
        }
    };

    useEffect(() => {
        if (testInfo.length == 0) {
            fetchTests();
        }
    });

    /**
     * fetch all tests
     */
    const fetchTests = () => {
        axios.get(URL.testInfoURL + 'tests', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
            }
        })
            .then(function (response: any) {
                // handle success            
                setTestInfo(response.data);
            })
            .catch(function (error: any) {
                // handle error
                console.log(error);
            })
    }

    /**
     * sending invite to candidates
     */
    function sendInvite() {
        const hrEmail: any = localStorage.getItem('email');
        const role: any = localStorage.getItem('role');
        setLoading(true);
        const params: any = {
            email: role === 'hr' ? emails + ',' + hrEmail : emails,
            test_ids: test,
            contents: message,
            link: URL.frontURL + '/Dashboard',
        };
        const requestOptions: any = {
            method: "GET",
            headers: {
                token: localStorage.getItem("token"),
            },
        };
        fetch(
            URL.baseURL + "/bx_block_email_notifications/email_notifications/invite?" +
            new URLSearchParams(params).toString(),
            requestOptions
        )
            .then((response) => {
                if (response.status === 200) {
                    setTest([]);
                    setLoading(false);
                    setEmails('');
                    setInviteMessage('');
                    setMessage(true);
                    dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: [] } });
                    setTimeout(() => { setMessage(false) }, 5000);
                } else {
                    setErrorMessage(true);
                    setTimeout(() => { setErrorMessage(false) }, 5000);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Box bgcolor={palette?.primary?.main} display="flex" justifyContent="space-between" height="100%" p={2}>
            <Box flexGrow="0.8">
                <TypoGraphy variant="h4" color="#fff">
                    {t('invite_candidates')} <span style={{ visibility: "hidden" }}>{t("beginAssessmentHr")}</span>
                </TypoGraphy>
                <Formik
                    enableReinitialize
                    initialValues={{ emails: emails, tests: _.toString(test), message: message }}
                    validationSchema={Yup.object().shape({
                        emails: Yup.string().email('Invalid email').required('Email is required'),
                        tests: Yup.string().required('Atleast one test is required'),
                        message: Yup.string().min(10, t('invite_min')).max(200, t('invite_max')).required('Message is required'),
                    })}
                    onSubmit={(values: any, actions: any) => {
                        setTimeout(() => {
                            sendInvite()
                            actions.setSubmitting(false)
                        }, 1000);
                    }}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" justifyContent="space-between">
                                    <Box mr={8} flexGrow="1">
                                        <Box mt={5}>
                                            <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                {t('candidate_emails')}
                                            </TypoGraphy>
                                            <FormField
                                                value={emails}
                                                name="emails"
                                                size="h6"
                                                onChange={(e: any) => { handleChange(e); setEmails(e.target.value) }}
                                                // text={t('candidate_emails')}
                                                color="secondary"
                                                bgcolor="#ffffff"
                                                inputcolor={theme.palette?.primary?.main}
                                                width={100}
                                                error={touched.emails && Boolean(errors.emails)}
                                                helperText={touched.emails && errors.emails}
                                            />
                                        </Box>
                                        <Box mt={5}>
                                            <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                {t('tests')}
                                            </TypoGraphy>
                                            <Autocomplete
                                                multiple
                                                options={testInfo}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.test_title}
                                                value={state.selectedOptions}
                                                noOptionsText={<span style={{ fontFamily: 'Campton-Bold', color: '#10069F', fontSize: '.7em' }}>{t('no_test')}</span>}
                                                renderTags={(values) =>
                                                    values.map((value, index) => (
                                                        <span key={index} style={{ color: '#10069F', fontSize: '.7em', fontFamily: 'Campton-Bold' }}>{value.test_title}, &nbsp; </span>
                                                    ))
                                                }
                                                getOptionSelected={(option, value) => option.test_id === value.test_id}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        <span style={{ color: '#10069F', fontSize: '.6em', fontFamily: 'Campton-Bold' }}>{option.test_title}</span>
                                                    </React.Fragment>
                                                )}
                                                onChange={(e, value) => { selectTest(e, value) }}
                                                renderInput={(params: any) => (
                                                    <React.Fragment>
                                                        <TextField
                                                            name="tests"
                                                            style={{ backgroundColor: "#ffffff", paddingTop: 10, paddingBottom: 10 }}
                                                            {...params}
                                                            fullWidth
                                                            InputProps={{ ...params.InputProps, disableUnderline: true }}
                                                        />
                                                        {
                                                            touched.tests && Boolean(errors.tests) && test ?
                                                                <Typography color="error" variant="caption">{touched.tests && errors.tests}</Typography>
                                                                : <></>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            />
                                        </Box>
                                        <Box mt={5} display={successMessage ? "flex" : "none"}>
                                            <TypoGraphy color="#fff" variant="body1">{t('success')}</TypoGraphy>
                                        </Box>
                                        <Box mt={5} display={loading ? "flex" : "none"}>
                                            <TypoGraphy color="#fff" variant="body1">{t('sending')}</TypoGraphy>
                                        </Box>
                                        <Box mt={5} display={errorMessage ? "flex" : "none"}>
                                            <TypoGraphy color="#fff" variant="body1">{t('failure')}</TypoGraphy>
                                        </Box>
                                    </Box>
                                    <Box flexGrow="1">
                                        <Box mt={5} mb={5}>
                                            <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                {t('message_to_candidate')}
                                            </TypoGraphy>
                                            <FormField
                                                multiline={true}
                                                value={message}
                                                rows={10}
                                                name="message"
                                                size="h6"
                                                onChange={(e: any) => { handleChange(e); setInviteMessage(e.target.value) }}
                                                // text={t('message_to_candidate')}
                                                color="secondary"
                                                bgcolor="#ffffff"
                                                inputcolor={theme.palette?.primary?.main}
                                                width={100}
                                                error={touched.message && Boolean(errors.message)}
                                                helperText={touched.message && errors.message}
                                            />
                                        </Box>
                                        <Box mt={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <KnowMeButton type="submit" color={palette?.primary.main} width={"50%"} bgcolor={palette?.secondary.main}>
                                                <Box p={0.75}>
                                                    <Typography variant="h5" >
                                                        {t("send_invite")}
                                                    </Typography>
                                                </Box>
                                            </KnowMeButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        )
                    }}
                </Formik>
            </Box>
            {/* button to switch and open back the menu */}
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box width="2em" height="2em">
                    <Button onClick={() => Props.mail({ mailInvitationModal: false })}>
                        <CustomSVGIcon width="100%" icon={Props.trigger == 'topBar' ? 'Close' : 'Mail'} />
                    </Button>
                </Box>
                <Box>
                    <Button onClick={() => Props.mail({ mailInvitationModal: false })}>
                        <CustomSVGIcon width="48px" icon={Props.trigger == 'topBar' ? 'Empty' : 'Knowmeq'} />
                    </Button>
                </Box>
            </Box>
        </Box >
    );
};

/**
 * Function to map state to props to use this.props.state
 * @param state state of the component
 * @returns state as HOC
 */
const mapStateToProps = (state: any) => ({
    state: state,
});

export default connect(mapStateToProps)(MailInvitation);
