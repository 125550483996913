import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button, MenuItem, Select } from '@material-ui/core';
import clsx from "clsx";
import { Image } from 'react-native';
import { useSelector, useDispatch } from 'react-redux'
import { changeLocale } from '../actions/index'
import { useTranslation } from 'react-i18next'
import { ThemeProvider, useTheme } from '@material-ui/styles'
import CustomSVGIcon from "../SVGIcon";
import { isMobile } from 'react-device-detect';
import { RFPercentage } from 'react-native-responsive-fontsize';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    width: '10rem',
    color: theme.palette.primary.main,
    borderRadius: 0,
    padding: 15,
    textDecoration: 'none',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.secondary.main,
    '&:focus': {
      backgroundColor: '#ffffff',
    },
    '&:active': {
      backgroundColor: '#ffffff',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}))(Button);

export default function ModalTab1(props: any) {
  // state variables start
  const [modal, setmodal] = React.useState(localStorage.getItem('termsAndCondition') == 'yes' || localStorage.getItem('termsAndCondition') == 'no' ? false : true);
  const [modalTab1, setmodalTab1] = React.useState(localStorage.getItem('language') != 'English' || localStorage.getItem('language') != 'French' ? 'inline-flex' : 'none');
  const [modalTab1Header, setmodalTab1Header] = React.useState('none');
  const [modalTab2, setmodalTab2] = React.useState('none');
  const [termsAndCondition, setTermAndCondition] = React.useState<any>(localStorage.getItem('termsAndCondition'))
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  // state variables end

  // hooks start
  const locale = useSelector((state: any) => state.changeLocale)
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useSelector((state: any) => state.updateTheme);
  const parentTheme: any = useTheme();
  // hooks end

  // styles start
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        display: 'inline-flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexShrink: 1,
        marginTop: '15px'
      },
      modalTabs: {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },
      modalTabs2: {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },
      modalTab2Header: {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        animation: `$modalTab2Header 800ms ${theme.transitions.easing.easeInOut}`
      },
      modalTabHeaderTitle: {
        color: theme.palette.primary.main,
        position: 'absolute',
        transformOrigin: '0 0',
        transform: 'rotate(270deg)',
        bottom: '0px',
        whiteSpace: 'nowrap',
        lineHeight: '50px',
        fontSize: RFPercentage(2)
      },
      container: {
        padding: 60,
        alignContent: 'stretch',
        overflowY: isMobile ? 'scroll' : 'hidden',
        height: '100%'
      },
      modalTab1: {
        animation: `$modalTab1 1000ms ${theme.transitions.easing.easeInOut}`,
        backgroundColor: parentTheme?.palette?.primary?.main,
      },
      animatedItemExiting: {
        animation: `$modalTab1Exit 1000ms ${theme.transitions.easing.easeInOut}`,
        opacity: 0,
        transform: "translate(200%, 200%)"
      },
      "@keyframes modalTab1": {
        "0%": {
          opacity: 0,
          transform: "translate(200%, 200%)"
        },
        "100%": {
          opacity: 1,
          transform: "translate(0)"
        }
      },
      "@keyframes modalTab1Exit": {
        "0%": {
          opacity: 1,
          transform: "translate(0)"
        },
        "100%": {
          opacity: 0,
          transform: "translate(800%, 0%)"
        }
      },
      modalTab2: {
        animation: `$modalTab2 500ms ${theme.transitions.easing.easeInOut}`,
        backgroundColor: theme.palette.primary.main,
        justifyContent: "space-between"
      },
      modalTab2Exiting: {
        animation: `$modalTab2Exit 800ms ${theme.transitions.easing.easeInOut}`,
        opacity: 0,
        transform: "translate(200%, 200%)"
      },
      modalTab2HeaderExit: {
        animation: `$modalTab2HeaderExit 500ms ${theme.transitions.easing.easeInOut}`,
        opacity: 0,
        transform: "translate(200%, 200%)"
      },
      "@keyframes modalTab2": {
        "0%": {
          opacity: 0,
          transform: "translate(400%, 0%)"
        },
        "100%": {
          opacity: 1,
          transform: "translate(0)"
        }
      },
      "@keyframes modalTab2Header": {
        "0%": {
          opacity: 0,
          transform: "translate(-800%, 0%)"
        },
        "100%": {
          opacity: 1,
          transform: "translate(0)"
        }
      },
      "@keyframes modalTab2Exit": {
        "0%": {
          opacity: 1,
          transform: "translate(0)"
        },
        "100%": {
          opacity: 0,
          transform: "translate(800%, 0%)"
        }
      },
      "@keyframes modalTab2HeaderExit": {
        "0%": {
          opacity: 1,
          transform: "translate(0)"
        },
        "100%": {
          opacity: 0,
          transform: "translate(-400%, 0%)"
        }
      },
      flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      selected: {
        color: theme.palette.primary.main,
        fontFamily: 'Campton-Bold'
      },
      rootMenuItem: {
        color: theme.palette.primary.main,
        fontFamily: 'Campton-Bold',
        "&$selected": {
          color: theme.palette.primary.main,
          fontFamily: 'Campton-Bold',
        },
      },
      selectRoot: {
        color: props.primary,
        backgroundColor: props.secondary,
        paddingLeft: "10px",
        fontSize: "1.2em",
        marginBottom: '-4px',
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
      }
    })
  );
  const classes = useStyles();
  // styles stop

  const baseConfig = require("../../../blocks/dashboard/src/config");

  /**
   * Function to update selected language in localStorage and react storage
   * @param e selected language french/english
   */
  const switchLang = (e: any) => {
    dispatch(changeLocale(e.target.value));
    updateLanguage(e.target.value);
  }

  /**
   * Function to send user's selected language to backend for persistence
   * @param e selected language
   */
  const updateLanguage = (value: any) => {
    let data: any = {
      "account": {
        "language": value == 'fr' ? 1 : 0
      }
    }
    const requestOptions: any = {
      method: "PUT",
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    };

    let userId: any = localStorage.getItem("user_id");

    fetch(
      baseConfig.baseURL +
      "/account_block/accounts/" + parseInt(userId),
      requestOptions
    )
      .then((response) => {
        if (response.status == 200) {
          if (value == 'fr') {
            localStorage.setItem('language', 'French')
          } else {
            localStorage.setItem('language', 'English')
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Function to update state for users accepted terms of service in backend
   * @param e selected language
   */
  const updateTermsAndCondition = (e: any) => {
    let data: any = {
      "account": {
        "terms_and_condition_acceptance": e
      }
    }
    const requestOptions: any = {
      method: "PUT",
      headers: {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    };

    let userId: any = localStorage.getItem("user_id");

    fetch(
      baseConfig.baseURL +
      "/account_block/accounts/" + parseInt(userId),
      requestOptions
    )
      .then((response) => {
        if (response.status == 200 && e == 0) {
          setmodal(false)
          setmodalTab1Header('none')
          setmodalTab2('none')
          localStorage.setItem('guide', 'false')
          localStorage.setItem('termsAndCondition', 'yes')
        } else if (response.status == 200 && e == 1) {
          setmodal(false)
          setmodalTab1Header('none')
          setmodalTab2('none')
          localStorage.setItem('guide', 'false')
          localStorage.setItem('termsAndCondition', 'no')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * useEffect to resize window size to avoid collapsing calendar grid
   */
  React.useEffect(() => {
    function onResize() {
      let width: any = window.innerWidth
      setWindowWidth(width)
    }
    window.addEventListener("resize", onResize);
  })

  /**
   * Defining custom theme for this component
   */
  const guideTheme = createMuiTheme({
    typography: {
      allVariants: {
        fontFamily: 'Campton-Bold',
      }
    },
    palette: {
      primary: {
        main: theme.present.colors.primary
      },
      secondary: {
        main: theme.present.colors.secondary
      },
      text: {
        primary: theme.present.colors.extra
      }
    },
  });

  return (
    <ThemeProvider theme={guideTheme}>
      <Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modal}
          onClose={props.onClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
            invisible: true
          }}
        >
          <Box style={{ height: '85vh', width: isMobile ? '100%' : '95%' }}>
            <Box style={{ display: modalTab1, flexDirection: 'row-reverse', width: '100%', height: '100%' }}>
              <Grid container className={clsx([classes.container, modalTab1Header != 'none' ? classes.modalTab2 : classes.modalTab1])}>
                <Grid item sm={12}>
                  <Typography color="secondary" variant="h3">{t('guide.guide1.title', { user: props.user })}</Typography>
                </Grid>
                <Grid item sm={12}>
                  <Typography color="textPrimary" variant="h6">{t('guide.guide1.subtitle1')}</Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Box display={"flex"} flexDirection="column" alignItems="center">
                      <CustomSVGIcon margin="0" width="48px" icon="Contact" />
                      <Typography color="textPrimary" variant="h6">{t('guide.guide1.item1')}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box display={"flex"} flexDirection="column" alignItems="center">
                      <CustomSVGIcon margin="0" width="48px" icon="Affirmations" />
                      <Typography color="textPrimary" variant="h6">{t('guide.guide1.item2')}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box display={"flex"} flexDirection="column" alignItems="center">
                      <CustomSVGIcon margin="0" width="48px" icon="Lang" />
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-evenly">
                      <Typography color="textPrimary" variant="h6">{t('guide.guide1.item3')}</Typography>
                      <Select
                        className={classes.selectRoot}
                        value={locale}
                        onChange={switchLang}
                        inputProps={{
                          name: "lang",
                          id: "age-native-helper"
                        }}
                        disableUnderline={true}
                      >
                        <MenuItem value="en" classes={{ selected: classes.selected, root: classes.rootMenuItem }}>English</MenuItem>
                        <MenuItem value="fr" classes={{ selected: classes.selected, root: classes.rootMenuItem }}>French</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                <Typography variant="h6" color="textPrimary">{t('guide.guide1.subtitle2')}</Typography>
                <Grid container justify="space-around" alignItems="flex-end">
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6} className={clsx(classes.flexEnd)}>
                    <ColorButton onClick={() => { setmodalTab1('none'), setmodalTab2('flex') }} color="primary">{t('guide.continue')}</ColorButton>
                  </Grid>
                </Grid>
              </Grid>
              <Box className={clsx(classes.modalTab2Header)} style={{ display: modalTab1Header, paddingRight: '60px', paddingLeft: '35px', borderTopWidth: '2px', borderLeftWidth: '2px' }}>
                <Typography className={clsx(classes.modalTabHeaderTitle)}>{t('guide.tab1')}</Typography>
              </Box>
            </Box>
            <Box style={{ display: modalTab2, flexDirection: 'row-reverse', width: '100%', height: '100%' }}>
              <Grid container className={clsx([classes.container, classes.modalTab2])}>
                <Grid item sm={12}>
                  <Typography color="secondary" variant={isMobile ? 'h5' : 'h4'}>{t('guide.privacy')}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid item xs={12} sm={1}>
                    <Box style={{ height: 57, width: 57, margin: 0 }}>
                      <Image style={{ height: '100%', resizeMode: 'contain' }} source={require('./assets/good.png')} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <ColorButton style={{ width: '100%', padding: 15, textTransform: "none", fontSize: "1.2rem" }} onClick={() => { updateTermsAndCondition(0) }} disableRipple variant="contained" color="primary">{t('guide.agree')}</ColorButton>
                  </Grid>
                </Grid>
              </Grid>
              <Box className={clsx(classes.modalTab2Header)} style={{ paddingRight: '10px', paddingLeft: '10px', borderTopWidth: '2px', borderLeftWidth: '2px' }}>
                <Button style={{ marginTop: 3 }} onClick={() => { setmodalTab1Header('flex'), setmodalTab1('flex'), setmodalTab2('none') }}>
                  <CustomSVGIcon color={theme.present.colors.primary} width="48px" icon="ExpandRight" />
                </Button>
                <Typography className={clsx(classes.modalTabHeaderTitle)}>{t('guide.tab2')}</Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}