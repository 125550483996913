import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import i18n from '../../../components/src/i18n';
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  locale: any;
  t: any;
  state: any;
  colors: any;
  dispatch: any;
  proppedState: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  jobPostData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  guide: boolean;
  user: string;
  modalTab: number;
  testModal: boolean;
  positionFilter: boolean;
  agendaFilter: boolean;
  selectedPositionFilter: string;
  selectedAgendaFilter: string;
  expandCalendar: boolean;
  calendarTitle: string;
  positions: any;
  role: any;
  nylasEventData: any;
  anchorEl: boolean;
  calFilter: boolean;
  calendarView: string;
  invitedTestsList: any;
  testId: any;
  testTitle: any;
  testDescription: any;
  allTestList: any;
  eventDialog: any;
  dateArgs: any;
  createEventResponseMessage: any;
  testInfo: any;
  allTestInfo: any;
  windowWidth: any;
  windowHeight: any;
  currentDate: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  GetApiJobPostItemCallId: string = "";
  GetNylasEventListCallId: string = "";
  GetInvitedTestsListCallId: string = "";
  GetAllTestsListCallId: string = "";
  createEventCallId: string = "";
  GetTestDraftListApiId: string = "";
  GetAllTestDraftListApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    if (!localStorage.getItem('token')) {
      window.location.href = '/'
    }
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      jobPostData: [],
      nylasEventData: [],
      role: "",
      errorMsg: "",
      token: "",
      loading: false,
      guide: false,
      user: 'test',
      modalTab: 1,
      testModal: false,
      positionFilter: false,
      selectedPositionFilter: 'Roles',
      agendaFilter: false,
      selectedAgendaFilter: 'Day',
      expandCalendar: false,
      calendarTitle: new Date(2021, (new Date().getMonth()), 21).toLocaleString('default', { month: 'long' }) + ' ' + new Date().getUTCFullYear(),
      positions: configJSON.positions,
      anchorEl: false,
      calFilter: false,
      calendarView: "dayGridMonth",
      invitedTestsList: [],
      testId: "",
      allTestList: [],
      eventDialog: false,
      dateArgs: undefined,
      createEventResponseMessage: '',
      testDescription: '',
      testTitle: '',
      testInfo: [],
      allTestInfo: {},
      windowHeight: '',
      windowWidth: '',
      currentDate: moment(new Date()).format('X'),
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getAllTests();
    if (localStorage.getItem('role') === 'candidate') {
      // this.getAllTests();
      // this.getInvitedTests();
    }
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }
  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }


  // To get all nylas calendar events from thirdparty api
  getNylasEventList(to: string, from: string): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.nylasEventListApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetNylasEventListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.nylasEventListApiGetUrl + `/?start=${to}&end=${from}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.nylasEventListApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }


  // To get job positions listing
  getJobPostData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.jobPostContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetApiJobPostItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobPostGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.jobPostApiMethodTYPE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  // Function to get all invited test list of the candidate
  getInvitedTests(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.invitedTestsListApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetInvitedTestsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.invitedTestsListApiGetUrl + "?account_id=" + localStorage.getItem('user_id')
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.invitedTestsListApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }


  // Funion to get all tests list from third party api
  getAllTests() {
    // Customizable Area Start
    const header = {
      'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllTestsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allTestListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }


  // Function to get all draft answers
  getAllDraftAnswers(): boolean {
    const userId: any = localStorage.getItem('user_id');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetAllTestDraftListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestDraftAPIEndpoit + "?user_id=" + parseInt(userId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  // Function to get draft tests
  getDraftAnswers(id: number): boolean {
    const userId: any = localStorage.getItem('user_id');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetTestDraftListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestDraftAPIEndpoit + "?test_id=" + id + "&user_id=" + parseInt(userId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token: any = localStorage.getItem('token');
      let role = localStorage.getItem('role');
      this.setState({ token: token, loading: true, role: role }, () => {
        this.getDashboardData();
        this.getJobPostData();
        // this.getNylasEventList('', '');
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        // Response of jobpost listing api
        if (apiRequestCallId === this.GetApiJobPostItemCallId) {
          if (responseJson && !responseJson.error && responseJson.job_posting.data) {
            if (responseJson.job_posting.data.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                jobPostData: responseJson.job_posting.data,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              });
            }
          } else {
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        // Response of Calendar nylas events list api
        if (apiRequestCallId === this.GetNylasEventListCallId) {
          if (responseJson && !responseJson.error && responseJson.data) {
            if (responseJson.data.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              let eveArr: object[] = [];
              responseJson.data.map((nylasEvent: any, index: any) => {
                eveArr.push({ title: nylasEvent?.attributes?.title, date: moment.unix(nylasEvent?.attributes?.time).format() });
              })
              this.setState({
                nylasEventData: eveArr,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              });
            }
          } else {
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        if (apiRequestCallId === this.apiDashboardItemCallId) {
          if (responseJson && !responseJson.errors && responseJson.data) {
            if (responseJson.data.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                dashboardData: responseJson.data,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              });
            }
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
                loading: false
              });
            }
          }
        }
        // Response of candidate invited tests
        if (apiRequestCallId === this.GetInvitedTestsListCallId) {
          if (responseJson && !responseJson.errors && responseJson.data) {
            if (responseJson.data.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              let filteredTest: any = [];
              let invitedTestIds: any = responseJson.data?.attributes.test_id;
              filteredTest = this.state.allTestList.filter((item: any) => invitedTestIds.some((i: any) => item.test_id === i));
              this.setState({
                invitedTestsList: filteredTest,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              });
            }
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
                loading: false
              });
            }
          }
        }
        // Response of all test list thirdparty api
        if (apiRequestCallId === this.GetAllTestsListCallId) {
          if (responseJson && !responseJson.errors) {
            if (responseJson.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                allTestList: responseJson,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              }, () => {
                this.getInvitedTests();
              });
            }
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
                loading: false
              });
            }
          }
        }

        // Response of create event api
        if (apiRequestCallId === this.createEventCallId) {
          if (responseJson && !responseJson.errors) {
            if (responseJson.length === 0) {
              this.setState({
                createEventResponseMessage: "Something went wrong",
                eventDialog: false
              });
            } else {
              this.setState({
                createEventResponseMessage: "Event created successfully",
                eventDialog: false
              }, () => {
                this.getInvitedTests();
              });
            }
          } else {
            var errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (errorReponse === undefined) {
              this.setState({
                createEventResponseMessage: "Something went wrong",
                eventDialog: false
              });
            } else {
              this.setState({
                createEventResponseMessage: errorReponse,
                eventDialog: false
              });
            }
          }
        }
        if (apiRequestCallId === this.GetTestDraftListApiId) {
          if (responseJson && !responseJson.error && responseJson.data.length) {
            let testInfo: any = {};
            responseJson.data.map((item: any, index: any) => {
              testInfo = {
                "test_id": item.attributes.test_id,
                "test_title": item.attributes.title,
                "test_description": item.attributes.description,
                "account_id": item.attributes.account_id,
                "company_id": item.attributes.company_id,
                "status": item.attributes.status,
                "answers": item.attributes.answers,
                "questions": []
              }
              item.attributes.questions.map((q: any, i: any) => {
                testInfo.questions[i] = {
                  "answer": q.attributes.answer,
                  "question_id": q.attributes.question_id,
                  "question_title": q.attributes.question_title,
                  "question_type": q.attributes.question_type,
                  "question_level": q.attributes.question_level,
                  "category_name": q.attributes.category_name,
                  "category_description": q.attributes.category_description,
                  "hierarchy": q.attributes.hierarchy
                }
              });
            });
            testInfo.questions.sort(function (a: any, b: any) {
              return a.question_id - b.question_id;
            });
            this.setState({
              testInfo: testInfo
            })
          }
        }
        if (apiRequestCallId === this.GetAllTestDraftListApiId) {
          if (responseJson && !responseJson.error && responseJson.data.length) {
            this.setState({
              allTestInfo: responseJson.data.sort(function (a: any, b: any) {
                return a.attributes.test_id - b.attributes.test_id;
              })
            })
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getFilterData(filter: string) {
    let p: any;
    if (filter === "Roles") {
      p = configJSON.positions
    } else {
      p = configJSON.test
    }
    this.setState({ positions: p })
  }

  closeFilter = () => {
    this.setState({
      anchorEl: false
    });
  };

  openFilter = () => {
    this.setState({
      anchorEl: true
    });
  }

  closeCalendarFilter = () => {
    this.setState({
      calFilter: false
    });
  };

  openCalendarFilter = () => {
    this.setState({
      calFilter: true
    });
  }

  createEvent = (values: object) => {
    // Customizable Area Start
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEventCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURL + '/bx_block_calendar/nylas_events'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  // Customizable Area End

}
