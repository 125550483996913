import React from "react";

export default function Mail(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={props.color} enableBackground="new 0 0 72 72" viewBox="0 0 72 72">
      <path d="M36,72C16.1,72,0,55.9,0,36S16.1,0,36,0s36,16.1,36,36S55.9,72,36,72z M36,3C17.8,3,3,17.8,3,36s14.8,33,33,33	s33-14.8,33-33S54.2,3,36,3z"></path>
      <path d="M12,47.8V23.4l11.6,10.5l0.8,0.8l-0.8,0.8L12,47.8z M36,40l0.7-0.6l21.3-18.7H12.9l22.4,18.8L36,40z M45.7,37.6	L45,36.8l-0.8,0.7L36,44.3l-8.2-6.9L27,36.8l-0.7,0.8L12.7,51.9h46.6L45.7,37.6z M48.4,33.9l-0.8,0.8l0.8,0.8L60,47.8V23.4
	L48.4,33.9z"></path>
    </svg>

  );
}
