// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-redux';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import FieldGuide from '../../components/src/FieldGuide/FieldGuide';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Favourites from '../../blocks/Favourites/src/Favourites';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import SingleSignonsso from '../../blocks/SingleSignonsso/src/SingleSignonsso';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import VisualAnalytics from '../../blocks/VisualAnalytics/src/VisualAnalytics';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import Surveys from '../../blocks/Surveys/src/Surveys';
import UploadMedia from '../../blocks/UploadMedia/src/UploadMedia';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import ProjecttaskTracking from '../../blocks/ProjecttaskTracking/src/ProjecttaskTracking';
import DocumentOpener from '../../blocks/DocumentOpener/src/DocumentOpener';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import CfApiLibrary from '../../blocks/CfApiLibrary/src/CfApiLibrary';
import SaveAsPdf from '../../blocks/SaveAsPdf/src/SaveAsPdf';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CvresumeCandidateManagement from '../../blocks/CvresumeCandidateManagement/src/CvresumeCandidateManagement';
import VideoManagement from '../../blocks/VideoManagement/src/VideoManagement';
import TaskViews from '../../blocks/TaskViews/src/TaskViews';
import AccountScoreranking from '../../blocks/AccountScoreranking/src/AccountScoreranking';
import DashboardGuests from '../../blocks/DashboardGuests/src/DashboardGuests';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import Calendar from '../../blocks/Calendar/src/Calendar';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import { Redirect, withRouter } from 'react-router-dom';
import EventDialog from '../../components/src/EventDialog';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isDesktop,
  deviceType,
  getUA
} from 'react-device-detect';
import Popper from '../../components/src/Popper';
import { baseURL } from '../../components/src/config';
import { updateTheme } from '../../components/src/actions/index';

const routeMap = {
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  SingleSignonsso: {
    component: SingleSignonsso,
    path: '/SingleSignonsso'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/candidate/:id'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  UploadMedia: {
    component: UploadMedia,
    path: '/UploadMedia'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard/:type?'
  },
  ProjecttaskTracking: {
    component: ProjecttaskTracking,
    path: '/test/:id'
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: '/DocumentOpener'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  CfApiLibrary: {
    component: CfApiLibrary,
    path: '/CfApiLibrary'
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: '/SaveAsPdf'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  CvresumeCandidateManagement: {
    component: CvresumeCandidateManagement,
    path: '/CvresumeCandidateManagement'
  },
  VideoManagement: {
    component: VideoManagement,
    path: '/VideoManagement'
  },
  TaskViews: {
    component: TaskViews,
    path: '/TaskViews'
  },
  AccountScoreranking: {
    component: AccountScoreranking,
    path: '/results:id?'
  },
  DashboardGuests: {
    component: DashboardGuests,
    path: "/filters/:type/:id?"
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  Calendar: {
    component: Calendar,
    path: '/Calendar'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },

  Home: {
    component: SingleSignonsso,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../components/src/theme';
import { Box, Typography } from '@material-ui/core';
import MailInvitation from '../../components/src/FieldGuide/MailInvitation';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailInvitationModal: false,
      trigger: 'topBar',
      renderDashboard: false
    };
  }
  handleState = childData => {
    this.setState(childData);
  };

  getTheme(theme) {
    let themeFromState = this.props.state.updateTheme.present
    let primary, secondary, extra = '';
    if (theme) {
      switch (themeFromState.setting) {
        case 'light_on_dark':
          primary = themeFromState.colors.primary;
          secondary = themeFromState.colors.secondary;
          extra = themeFromState.colors.extra;
          break;
        case 'dark_on_light':
          primary = themeFromState.colors.extra;
          secondary = themeFromState.colors.primary;
          extra = themeFromState.colors.secondary;
          break;
        default:
          primary = themeFromState.colors.secondary;
          secondary = themeFromState.colors.extra;
          extra = themeFromState.colors.primary;
          break;
      }
      const themeUpdate = { ...theme, palette: { ...theme.palette, primary: { main: primary }, secondary: { main: secondary }, info: { main: extra } } }
      return themeUpdate
    }
    return theme
  }

  render() {
    const path = this.props.location.pathname.split('/', 2).pop();
    const background = path == 'results' || path == 'results/' ? 'linear-gradient(rgba(100, 203, 186, 0.72), transparent 25%)' : '';
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const fg =
      this.props.location.pathname !== '/' ? (
        <FieldGuide mail={this.handleState} />
      ) : null;
    return (
      <ThemeProvider theme={this.getTheme(theme)}>
        <View>
          <TopNav stateHandler={this.handleState} searchURL={baseURL} />
          <Box
            style={{
              background: background,
              paddingTop: '10em',
              justifyContent: 'space-between'
            }}
          >
            {WebRoutesGenerator({ routeMap }, { renderDashboard: this.state.renderDashboard })}
            <ModalContainer />
            {/* field guide will be accessble only when a used is logged-in */}
          </Box>
          {fg}
          <Box style={{ position: 'fixed', bottom: 0, right: 0 }}>
            <Popper display={this.state.mailInvitationModal}>
              <MailInvitation mail={this.handleState} trigger={this.state.trigger} />
            </Popper>
          </Box>
        </View>
        <EventDialog postEventCreateCall={this.handleState} open={this.props.state.eventDialog} args={{ date: new Date() }} />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  state: state
});
export default withRouter(connect(mapStateToProps)(App));
