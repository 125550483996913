// index.js - WEB
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import store from './store'
import { Provider } from 'react-redux'
import './i18n';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </Provider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
