import React from "react";

export default function Play(props: any) {
    return (
        <svg viewBox="0 0 24 24" fill={props.color}>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M8 5v14l11-7z"></path>
        </svg>
    );
}
