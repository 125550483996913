import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { openEventDialog } from './actions';
import KnowMeButton from './Button';
import { useTranslation } from 'react-i18next';
import FormField from './FormField';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as config from "./config";
import axios from 'axios';
import { eventDialog } from './reducers/locale';

export default function EventDialog(props: any) {
    const styles = makeStyles({
        dialogContent: {
            padding: "2rem"
        },
        fullWidth: {
            width: "100%"
        }
    })
    const dispatch = useDispatch();
    const classes = styles();
    const formik = useFormik({
        initialValues: {
            event_title: '',
            event_location: '',
            email: '',
            event_date: '',
            event_start_time: '',
            event_end_time: '',
            event_description: '',
        },
        validationSchema: yup.object({
            event_title: yup.string().required('Event Title is required'),
            event_location: yup.string().required('Event Location is required'),
            email: yup.string().email('Enter a valid email').required('Email is required'),
            event_date: yup.date().required('Date is required'),
            event_start_time: yup.string().required('Start Time is required'),
            event_end_time: yup.string().required('End Time is required'),
            event_description: yup.string().min(10, 'Minimum 10 characters').max(250, 'Maximum 250 characters allowed').required('Description is required'),
        }),
        onSubmit: (values) => {
            createEvent({
                "nylas_event": {
                    "title": values.event_title,
                    "account_id": localStorage.getItem('user_id'),
                    "calendar_id": "1ypdhq0jr5bqgfc17xb8lsdpj", // passing static calendar ID for now
                    "participants_attributes": [
                        {
                            "email": values.email,
                            "status": "yes"
                        }
                    ],
                    "description": values.event_description,
                    "time": moment(`"+${values.event_date + ' ' + values.event_start_time}+"`, "YYYY-MM-DD hh:mm A").valueOf() / 1000,
                    "location": values.event_location,
                    "recurrence_rrule": "RRULE:FREQ=WEEKLY;BYDAY=MO",
                    "timezone": "America/New_York",
                    "start_time": moment(`"+${values.event_date + ' ' + values.event_start_time}+"`, "YYYY-MM-DD hh:mm A").valueOf() / 1000,
                    "end_time": moment(`"+${values.event_date + ' ' + values.event_end_time}+"`, "YYYY-MM-DD hh:mm A").valueOf() / 1000,
                }
            })
        },
    });
    const eventDate = props.args ? moment(props.args.date.toISOString()).format('YYYY-MM-DD') : moment(new Date().toISOString()).format('YYYY-MM-DD');
    const isEventDialogOpen = useSelector((state: any) => state.eventDialog);
    const theme = useSelector((state: any) => state.updateTheme);
    const [selectedDate, handleDateChange] = useState(eventDate);
    const { t } = useTranslation();

    const createEvent = (event: any) => {
        axios.post(config.baseURL + '/bx_block_calendar/nylas_events', event,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            .then((response: any) => {
                dispatch(openEventDialog(false))
                props.postEventCreateCall({ renderDashboard: true })
            }).catch((error: any) => {
                console.log('error', error);
            })
    }
    return (
        <div>
            <Dialog maxWidth="md" open={isEventDialogOpen}>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" flexDirection="row">
                        <Typography variant="h5" color="primary">Event</Typography>
                        <IconButton aria-label="close" onClick={() => dispatch(openEventDialog(false))}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    size="h6"
                                    name="event_title"
                                    onChange={formik.handleChange}
                                    text={"Event Title"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    passwordfield={false}
                                    width={100}
                                    height="100px"
                                    error={formik.touched.event_title && Boolean(formik.errors.event_title)}
                                    helperText={formik.touched.event_title && formik.errors.event_title}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    size="h6"
                                    name="event_location"
                                    onChange={formik.handleChange}
                                    text={"Event Location"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    passwordfield={false}
                                    width={100}
                                    height="100px"
                                    error={formik.touched.event_location && Boolean(formik.errors.event_location)}
                                    helperText={formik.touched.event_location && formik.errors.event_location}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    size="h6"
                                    name="email"
                                    onChange={formik.handleChange}
                                    text={"Email Address"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    passwordfield={false}
                                    width={100}
                                    height="100px"
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    size="h6"
                                    name="event_date"
                                    onChange={formik.handleChange}
                                    text={"Event Date"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    type="date"
                                    passwordfield={false}
                                    width={100}
                                    height="100px"
                                    error={formik.touched.event_date && Boolean(formik.errors.event_date)}
                                    helperText={formik.touched.event_date && formik.errors.event_date}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    size="h6"
                                    name="event_start_time"
                                    onChange={formik.handleChange}
                                    text={"Event Start Time"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    passwordfield={false}
                                    type="time"
                                    width={100}
                                    height="100px"
                                    error={formik.touched.event_start_time && Boolean(formik.errors.event_start_time)}
                                    helperText={formik.touched.event_start_time && formik.errors.event_start_time}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormField
                                    size="h6"
                                    name="event_end_time"
                                    onChange={formik.handleChange}
                                    text={"Event End Time"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    passwordfield={false}
                                    type="time"
                                    width={100}
                                    height="100px"
                                    error={formik.touched.event_end_time && Boolean(formik.errors.event_end_time)}
                                    helperText={formik.touched.event_end_time && formik.errors.event_end_time}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={12} className={classes.fullWidth}>
                                <FormField
                                    size="h6"
                                    fullWidth
                                    value={formik.values.event_description}
                                    onChange={formik.handleChange}
                                    name="event_description"
                                    text={"Event Description"}
                                    bgcolor={theme.present.colors.secondary + "30"}
                                    inputcolor={theme.present.colors.primary}
                                    passwordfield={false}
                                    width={100}
                                    rows={5}
                                    readOnly={false}
                                    multiline={true}
                                    error={formik.touched.event_description && Boolean(formik.errors.event_description)}
                                    helperText={formik.touched.event_description && formik.errors.event_description}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={3} mb={3} display="flex" justifyContent="space-between">
                            <Typography color="primary" variant="body2">{props.message}</Typography>
                            <KnowMeButton width="30%" type="submit" bgcolor={theme.present.colors.primary}>
                                <Typography variant="body2" color="secondary">{t('create_event')}</Typography>
                            </KnowMeButton>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}