import React from 'react';
import { View, Text, Platform, Image, StyleSheet, TouchableOpacity } from 'react-native'
import TestHeader from './TestHeader'
import { RFPercentage } from "react-native-responsive-fontsize"
import * as Theme from '../../blocks/ProjecttaskTracking/src/theme'
import { Divider } from 'react-native-elements'
import { useSelector, useDispatch } from 'react-redux'
import { TypoGraphy } from './TypoGraphy'
import { Button, Box, Container, Typography, Grid, Modal, ListItem, List, ListItemText, SvgIcon, Checkbox, FormControl, FormGroup, FormControlLabel, Icon, TextField } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'// a plugin!
import styled from 'styled-components'
import { palette, secondaryPalette } from './Palette'
import FormField from './FormField'
import KnowMeButton from './Button'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { toggleContactModal } from "./actions/index";
// @ts-ignore
import Pdf from "react-to-pdf";
// @ts-ignore
import { CSVLink } from "react-csv";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const StyledWrapper = styled.div`
  * {
    font-family : Campton-Bold;
    color: #10069f
  }
  .fc-day-today .fc-daygrid-day-frame {
    background: #ffffff
  }
  .fc-daygrid-day-number {
    color: #10069f;
    font-weight: 500
  }
  .fc-day-today .fc-daygrid-day-number{
    color: #64CBBA;
    padding:0.3em;
    background: #10069f;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc-timegrid-slot-label-cushion{
    text-transform: uppercase
  }
  .fc-timegrid-axis-cushion, .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
  }
  .fc-timegrid-slot-minor {
    border: 0!important;
  }
  .fc-col-header-cell-cushion {
    word-spacing: 20px;
  }
`
const StyledWrapperSub = styled(StyledWrapper)`
  .fc-daygrid-day-events {
    display: none
  }
  .fc-daygrid-day-number{
    font-size: 0.5em
  }
  a.fc-col-header-cell-cushion {
    font-size: 0.5em;
  }
  .fc-day-today .fc-daygrid-day-frame {
    background-color: #e9e9e9
  }
  .fc-col-header {
    display:none
  }
`
const calendarRef: any = React.createRef()
const subcalendarRef: any = React.createRef()


const Accordion = withStyles({
    root: {
        width: "100%",
        fontFamily: "Campton-Bold",
        //   borderBottom: '5px solid #fff',
        boxShadow: 'none',
        '&:last-child': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: Theme.palette.primary.main,
        borderBottom: 'none',
        marginBottom: -1,
        padding: '20px',
        paddingLeft: '55px',
        paddingRight: '55px',
        color: '#fff',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '0px',
        width: '100%',
        background: Theme.palette.primary.main
    },
}))(MuiAccordionDetails);
const ref = React.createRef();
export default function ProseResults(props: any) {
    const mainIconDimensions = RFPercentage(4)
    const [drawer, openDrawer] = React.useState(localStorage.getItem('role') == 'candidate' ? props.resultId ? true : false : true)
    const [options, openOptions] = React.useState(true)
    const scroll = React.useRef()
    const dispatch = useDispatch()
    const [expanded, setExpanded] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [errorText, setErrorText] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const baseConfig = require("../../blocks/dashboard/src/config");

    React.useEffect(() => {
        if(props.top){
            scrollToImportantStuff()
        }
    }, [])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const changeValue = (e: any) => {
        const value: any = e.target.value;
        console.log("EMAIL", value)
        setEmail(value)
        let regEmail: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(value)) {
            console.log("INSIDE")
            setErrorText(true)
        } else {
            setErrorText(false)
            setEmail(value);
        }
    }

    const shareViaEmail = () => {
        const requestOptions: any = {
            method: "GET",
            headers: {
                token: localStorage.getItem("token"),
            },
        };
        fetch(
            baseConfig.baseURL +
            "/bx_block_test/candidate_test_results/send_email?id=" + props.id + "&email=" + email + "&title=prose_literacy",
            requestOptions
        )
            .then((response) => {
                if (response.status === 200) {
                    console.log("SUCCESS");
                    setOpen(false);
                    setErrorText(false);
                } else {
                    console.log("FAILED", response)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const headers: any = [
        { label: "Test Title", key: "test_title" },
        { label: "Test Description", key: "test_desc" },
        { label: "You are level", key: "your_level" },
        { label: "Level Description", key: 'level_desc' },
    ];

    const data: any = [
        {
            test_title: props.testName,
            test_desc: "Description here - Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, aperiam quisquam tenetur expedita cum officia obcaecati neque, voluptatem tempore esse perspiciatis molestias necessitatibus debitis consectetur ipsam reiciendis nesciunt voluptas saepe!",
            your_level: props.results ? props.results[0].user_level : '0',
            level_desc: props.results ? props.results[0].level_description : 'Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        }
    ];

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    const scrollToImportantStuff = () => {
        // @ts-ignore
        document.getElementById('ImportantStuff').scrollIntoView()
    }

    return (
        // @ts-ignore
        <Box ref={ref} id="ImportantStuff">
            <Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            borderRadius: '0px'
                        },
                    }}
                >
                    <DialogTitle id="form-dialog-title">
                        <Text style={{ fontSize: RFPercentage(1.5), color: Theme.palette.primary.main }}>Share Via Email</Text>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide email ID where you would like us to send results.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            error={errorText}
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            name="email"
                            fullWidth
                            helperText={errorText ? "Invalid Email" : null}
                            onChange={e => changeValue(e)}
                            onBlur={e => changeValue(e)}
                            style={{ color: 'red' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <TouchableOpacity disabled={errorText} onPress={shareViaEmail} style={{ backgroundColor: Theme.palette.primary.main, padding: '10px' }}>
                            <Text style={{ fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Share</Text>
                        </TouchableOpacity>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box style={{ padding: "2em", backgroundColor: Theme.palette.primary.main, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontFamily: "Campton-Bold", fontSize: RFPercentage(2), color: "#ffffff" }}>
                    {props.testName}
                </Text>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <TouchableOpacity onPress={() => { openDrawer(!drawer), scroll.current }}>
                        <Image style={{ height: "3em", width: "3em" }} source={drawer ? require(`./close.svg`) : require(`./add.svg`)} />
                    </TouchableOpacity>
                    {
                        props.type == 'declared' ? (
                            <TouchableOpacity onPress={() => { openOptions(!options), scroll.current }}>
                                <Image style={{ marginLeft: "1em", height: "3em", width: "3em" }} source={require(`./options.svg`)} />
                            </TouchableOpacity>
                        ) : (<></>)
                    }
                </Box>
            </Box>
            {
                props.type == 'declared' ? (
                    <TestHeader ref={scroll}>
                        <Box style={{ display: drawer ? "flex" : "none", flexDirection: 'column' }}>
                            <Box style={{ padding: "3.5em", border: '3px solid', borderColor: Theme.palette.primary.main }}>
                                <Box style={{ display: options ? "flex" : "none" }}>
                                    <Box style={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "row" }}>
                                        <TouchableOpacity onPress={handleClickOpen} style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                            <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                <Image style={{ height: "2em", width: "2em" }} source={require(`./share.svg`)} />
                                                <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Share Via Email</Text>
                                            </Box>
                                        </TouchableOpacity>
                                        <Pdf targetRef={ref} scale={0.69} filename="Prose.pdf">
                                            {({ toPdf }: any) => {
                                                return (
                                                    <TouchableOpacity onPress={toPdf} style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                                        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                            <Image style={{ height: "2em", width: "2em" }} source={require(`./download.svg`)} />
                                                            <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Download PDF</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                )
                                            }}
                                        </Pdf>
                                        <CSVLink data={data} headers={headers} filename={"prose-result.csv"} style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                                <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                    <Image style={{ height: "2em", width: "2em" }} source={require(`./download.svg`)} />
                                                    <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Download CSV</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </CSVLink>
                                    </Box>
                                    <Divider style={{
                                        marginVertical: '2em', backgroundColor: "transparent"
                                    }} />
                                </Box>
                                <Box style={{ marginTop: '3em', display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontFamily: "Campton-Light", fontSize: RFPercentage(1.2), lineHeight: 26 }}>
                                        {props.mainDescription}
                                    </Text>
                                    <Text style={{ fontFamily: "Campton-Bold", marginTop: "1.5em", fontSize: RFPercentage(1.2) }}>
                                        Please click on any of the sections in the graph to explore them further
                                    </Text>
                                </Box>
                            </Box>
                            <Box style={{ border: '3px solid', borderColor: Theme.palette.primary.main }}>
                                <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography>You are level...</Typography>
                                            <Typography>{props.results ? props.results[0].user_level : '0'}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                backgroundColor: Theme.palette.primary.main,
                                                padding: '4em',
                                                paddingTop: '1em',
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: '#fff',
                                                        fontFamily: 'Campton-Light',
                                                        fontSize: RFPercentage(1.2),
                                                        lineHeight: 26
                                                    }}
                                                >
                                                    {props.results ? props.results[0].level_description :
                                                        'Explanation of level and what it means here...Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                                                    }
                                                </Text>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" flexDirection="row">
                            <Button onClick={(e: any) => { dispatch(toggleContactModal(true)) }}>
                                <Typography color="textSecondary" variant="h6">Need Help?</Typography>
                                <Image style={{ marginLeft: "1em", height: 24, width: 24 }} source={require(`./help.svg`)} />
                            </Button>
                        </Box>
                    </TestHeader>
                ) : (
                    /**
                     * show calendar for undeclared tests
                     */
                    <Box display={drawer ? "block" : "none"} style={{ padding: "3em", borderStyle: "solid", borderWidth: 3, borderColor: palette.primary.main }}>
                        <Box mb="3em">
                            <TypoGraphy transform="none" variant="h5" color="#000000">Your EQ-i 2.0 results are not yet ready to view. Please start by booking an appointment with one of our coaches</TypoGraphy>
                        </Box>
                        <Grid container>
                            <Grid item sm={8}>
                                <StyledWrapper>
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin, timeGridPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={false}
                                        allDayText="All Day"
                                    />
                                </StyledWrapper>
                            </Grid>
                            <Grid item sm={4} style={{ backgroundColor: '#e9e9e9' }}>
                                <Box display="flex" flexDirection="column" paddingX={5} paddingY={5}>
                                    <TypoGraphy color={palette.primary.main} variant="subtitle1">Please select a time and fill out the form below</TypoGraphy>
                                    <Box>
                                        <FormControl required component="fieldset" >
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Name</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('fullName')} readOnly={true} />
                                            </Box>
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Employee #</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('applicationId')} readOnly={true} />
                                            </Box>
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Email Address</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('email')} readOnly={true} />
                                            </Box>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box display="flex" mt="2em" justifyContent="flex-end">
                            <KnowMeButton btnText="Submit" type="disabled" />
                        </Box>
                    </Box>
                )
            }
        </Box >
    )
}
const styles = StyleSheet.create({
    text: {
        fontFamily: "Campton-Bold"
    },
    inlineView: {
        display: "flex", justifyContent: "space-between", flexDirection: "row"
    }
})