import React from "react";

export default function Lang(props: any) {
  return (
    <svg viewBox="0 0 72 72" fill={props.color}>
      <path d="M61.3901 56.9936C59.4954 54.5683 57.6006 52.0672 56.9185 51.0062C59.4954 47.0651 61.0112 42.2904 61.0112 37.2125C61.0112 23.2672 49.7185 11.9746 36.3038 11.9746C22.8891 11.9746 11.5964 23.2672 11.5964 37.2125C11.5964 51.1578 22.8891 62.5262 36.3038 62.5262C40.6238 62.5262 44.7922 61.4651 47.748 59.5704C48.2785 60.4041 49.8701 62.6778 51.6133 65.103C55.327 63.0567 58.6617 60.2525 61.3901 56.9936ZM36.3038 51.5367C28.7249 51.5367 22.4343 45.1704 22.4343 37.2125C22.4343 29.2546 28.7249 22.8883 36.3038 22.8883C43.9585 22.8883 50.1733 29.5578 50.1733 37.2125C50.0975 44.8672 43.8828 51.5367 36.3038 51.5367Z" />
      <path d="M34.1058 40.1684C34.1058 39.6379 34.1816 39.2589 34.3332 38.88C34.4848 38.501 34.7122 38.1979 35.0153 37.8947C35.4701 37.44 35.9248 37.061 36.3037 36.7579C36.6827 36.4547 37.0616 36.1516 37.289 35.8484C37.5922 35.5452 37.8195 35.3179 37.9711 35.0147C38.1227 34.7873 38.1985 34.4842 38.1985 34.181C38.1985 33.6505 38.0469 33.1958 37.668 32.8926C37.3648 32.5895 36.9101 32.4379 36.3795 32.4379C35.6216 32.4379 35.0153 32.6652 34.6364 33.0442C34.2574 33.4989 34.0301 34.0294 33.9543 34.6358L30.1648 34.4084C30.3164 32.6652 30.9985 31.301 32.1353 30.3916C33.2722 29.4821 34.6364 29.0273 36.3795 29.0273C37.1374 29.0273 37.8195 29.1031 38.5016 29.3305C39.1837 29.5579 39.7901 29.861 40.2448 30.24C40.7753 30.6189 41.1543 31.1495 41.4574 31.7558C41.7606 32.3621 41.9122 33.0442 41.9122 33.8779C41.9122 34.3326 41.8364 34.7873 41.7606 35.1663C41.6848 35.5452 41.4574 35.9242 41.2301 36.3031C41.0027 36.6821 40.6995 37.061 40.3206 37.44C39.9416 37.8189 39.4111 38.2737 38.8806 38.7284C38.5016 39.0316 38.2743 39.3347 38.1227 39.5621C37.9711 39.8652 37.8953 40.0926 37.8953 40.3958V41.1537H34.1816L34.1058 40.1684ZM33.7269 44.9431C33.7269 44.64 33.8027 44.3368 33.8785 44.0337C34.0301 43.7305 34.1816 43.5031 34.3332 43.2758C34.5606 43.0484 34.788 42.8968 35.0911 42.821C35.3943 42.6694 35.6974 42.6694 36.0006 42.6694C36.3037 42.6694 36.6069 42.7452 36.9101 42.821C37.2132 42.9726 37.4406 43.1242 37.668 43.2758C37.8953 43.5031 38.0469 43.7305 38.1227 44.0337C38.2743 44.3368 38.2743 44.64 38.2743 44.9431C38.2743 45.2463 38.1985 45.5494 38.1227 45.8526C37.9711 46.1558 37.8195 46.3831 37.668 46.6105C37.4406 46.8379 37.2132 46.9894 36.9101 47.0652C36.6069 47.2168 36.3037 47.2168 36.0006 47.2168C35.6974 47.2168 35.3943 47.141 35.0911 47.0652C34.788 46.9137 34.5606 46.7621 34.3332 46.6105C34.1058 46.3831 33.9543 46.1558 33.8785 45.8526C33.7269 45.5494 33.7269 45.2463 33.7269 44.9431Z" />
      <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
    </svg>
  );
}
