import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  Platform,
  Dimensions,
} from "react-native";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { palette } from "../../../components/src/Palette"
import { Button, Box, Typography, Grid } from '@material-ui/core';
import FormField from "../../../components/src/FormField";
import { TypoGraphy } from "../../../components/src/TypoGraphy";
import OauthPopup from "react-oauth-popup";
import { AuthProvider, AuthService } from 'react-oauth2-pkce'

const isPotrait = () => {
  return Dimensions.get('window').height >= Dimensions.get('window').width
}
// Customizable Area End

import SingleSignonssoController, {
  Props,
  configJSON
} from "./SingleSignonssoController";
export default class SingleSignonsso extends SingleSignonssoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    if (localStorage.getItem('token')) {      
      window.location.href = '/Dashboard'
    }
    // Customizable Area End
  }

  // Customizable Area Start
  enterPressed = (e: any) => {
    var code: any = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) { //Enter keycode
        this.login()
    }
}
  // Customizable Area End
// Customizable Area Start
   onCode = (code: any, params: any) => {
    console.log("wooooo a code", code);
    console.log("alright! the URLSearchParams interface from the popup url", params);
  }
   onClose = () => console.log("closed!");
  // Customizable Area End

  // Customizable Area Start
   // react-oauth2-pkce for Identity flow of OAuth2.0
  authService = new AuthService({
    clientId: process.env.REACT_APP_CLIENT_ID || 'CHANGEHERE',
    location: window.location,
    provider: process.env.REACT_APP_PROVIDER || '',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
    scopes: ['openid', 'profile']
  });
  // Customizable Area End

  render() {
    let errorMessage: any;
    if (this.state.loginError) {
      errorMessage =
        (<View>
          <Text style={styles.errorMessage}>{this.state.loginError}</Text>
        </View>)
    } else {
      errorMessage = null
    }

    return (
      //Merge Engine DefaultContainer
      <View>
        <View style={styles.container}>
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <TypoGraphy variant="h3" color={palette.primary.main}>Welcome!</TypoGraphy>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Text style={styles.subHeading}>
                Please log in to KnowMeQ using your application # and your email address below:
              </Text>
              <Box display="flex" flexDirection="row" justifyContent="space-between" mt="2em">
                <TypoGraphy cursor={"pointer"} onClick={() => this.setState({ loginType: "candidate",  loginError: ''})} color={palette.primary.main} variant="subtitle1" textDecoration={this.state.loginType == 'candidate' ? "underline" : ""}>Candidate Login</TypoGraphy>
                <TypoGraphy cursor={"pointer"} onClick={() => this.setState({ loginType: "employee", loginError: '' })} color={palette.primary.main} variant="subtitle1" textDecoration={this.state.loginType == 'employee' ? "underline" : ""}>Employee Login</TypoGraphy>
                <TypoGraphy cursor={"pointer"} onClick={() => this.setState({ loginType: "hr", loginError: '' })} color={palette.primary.main} variant="subtitle1" textDecoration={this.state.loginType == 'hr' ? "underline" : ""}>HR Login</TypoGraphy>
              </Box>
              <View style={[styles.formContainer, {display : this.state.loginType == "candidate" ? "flex" : "none"}]}>
                <View style={styles.formGroup}>                  
                  <FormField
                      text={"Email Address"}
                      onChange={() => { this.handleInput('email', event) }}                       
                      bgcolor={palette.secondary.main + "30"}
                      inputcolor={palette.primary.main}
                      passwordfield={false}
                      width={100}
                      height={"auto"}    
                      onKeyPress={(e: any) => this.enterPressed(e)}                
                  />   
                  {
                    this.state.emailError ? <Text style={{ marginTop: '2px', color: 'red', fontFamily: 'Campton-Bold'}}>{this.state.emailError}</Text> : null
                  }               
                </View>
                <View>                  
                  <FormField
                      text={"Application #"}
                      onChange={() => { this.handleInput('applicationNumber', event) }} 
                      passwordfield={false}
                      bgcolor={palette.secondary.main + "30"}
                      inputcolor={palette.primary.main}
                      width={100}
                      height={"auto"} 
                      onKeyPress={(e: any) => this.enterPressed(e)}                   
                  />
                  {
                    this.state.pwdError ? <Text style={{marginTop: '2px', color: 'red', fontFamily: 'Campton-Bold'}}>{this.state.pwdError}</Text> : null
                  } 
                </View>
              </View>
              <View style={[styles.formContainer, {display : this.state.loginType == "hr" || this.state.loginType == "employee" ? "flex" : "none"}]}>
                <View style={styles.formGroup}>                  
                  <FormField
                      text={"Email Address"}
                      onChange={() => { this.handleInput('email', event) }}                       
                      bgcolor={palette.secondary.main + "30"}
                      inputcolor={palette.primary.main}
                      passwordfield={false}
                      width={100}
                      height={"auto"}   
                      onKeyPress={(e: any) => this.enterPressed(e)}                 
                  />   
                  {
                    this.state.emailError ? <Text style={{marginTop: '2px', color: 'red', fontFamily: 'Campton-Bold'}}>{this.state.emailError}</Text> : null
                  }                 
                </View>
                <View>                  
                  <FormField
                      text={"Password"}
                      onChange={() => { this.handleInput('applicationNumber', event) }} 
                      passwordfield={true}
                      bgcolor={palette.secondary.main + "30"}
                      inputcolor={palette.primary.main}
                      width={100}
                      height={"auto"}   
                      onKeyPress={(e: any) => this.enterPressed(e)}                 
                  />
                  {
                    this.state.pwdError ? <Text style={{marginTop: '2px', color: 'red', fontFamily: 'Campton-Bold'}}>{this.state.pwdError}</Text> : null
                  } 
                </View>
              </View>
              <View style={[styles.formContainer, styles.buttonContainer]}>
                <TouchableOpacity style={styles.button} onFocus={() => this.login()} onPress={() => this.login()}>
                  <Text style={{ textAlign:"center", padding: 24, color: '#64cbba', fontSize: 18, fontFamily: 'Campton-Bold' }}>Submit</Text>
                </TouchableOpacity>
              </View>
              {errorMessage}
            </Grid>
          </Grid>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </View>
      </View>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    paddingBottom: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: Dimensions.get('window').width <= 992 ? 'wrap' : 'nowrap'
  },
  button: {
    width: Dimensions.get('window').width <= 768 ? '100%' : '35%',
    backgroundColor: '#10069F',
    // textAlign: "center"
  },
  col1: {
    width: Dimensions.get('window').width >= 1024 ? '35%' : '100%'
  },
  mainHeading: {
    fontSize: RFPercentage(1.2),
    color: '#10069F',
    fontFamily: 'Campton-Bold',
    // textAlign: "center",
  },
  subHeading: {
    fontSize: RFPercentage(2),
    color: '#10069F',
    fontFamily: 'Campton-Bold'
  },
  formLabel: {
    fontSize: RFPercentage(1.5),
    color: '#10069F',
    fontFamily: 'Campton-Bold',
    marginBottom: 18,
  },
  col2: {
    width: Dimensions.get('window').width >= 1024 ? '65%' : '100%',
    marginTop: isPotrait() ? '10%' : '',    
  },
  input: {
    borderColor: "#10069F",
    backgroundColor: "#E0F4F1",
    borderWidth: 3,
    borderRadius: 2,
    height: 64,
    fontSize: 15,
    paddingLeft: 18,
    color: '#10069F'
  },
  formContainer: {
    marginTop: "3em"
  },
  formGroup: {
    marginBottom: 48
  },
  buttonContainer: {
    alignItems: isPotrait() ? 'center' : 'flex-start'
  },
  errorMessage: {
    fontSize: RFPercentage(1),
    fontWeight: 'bold',
    color: palette.error.main,
    marginTop: 10
  }
});
// Customizable Area End
