import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { testInfoURL } from "../../../components/src/config"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  type: any;
  state: any;
  classes: any;
  dispatch: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  candidateListData: any;
  filteredCandidateListData: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  completeScrollBarHeight: number;
  visibleScrollBarHeight: number;
  candidateCard: boolean;
  candidate: any;
  editingUser: boolean;
  positionFilter: boolean;
  selectedFilter: string;
  tests: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  guide: boolean;
  candidateListCount: number;
  candidateDetailsData: any;
  resumes: any;
  coverLetters: any;
  noteView: boolean;
  notes: any;
  anchorEl: boolean;
  candidateResults: any;
  candidateId: any;
  resumeFiles: any;
  resumeName: any;
  typing: any;
  typingTimeout: any;
  openSnackBar: any;
  profileError: any;
  snackType: any;
  openDocViewer: any;
  openResumeViewer: any;
  pathState: any;
  filteredMetaData: any;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DashboardGuestsController extends BlockComponent<
  Props,
  S,
  SS
> {

  GetCandidateListApiCallId: string = "";
  GetCandidateStatusApiCallId: string = "";
  GetCandidateDetailsApiCallId: string = "";
  GetAddNotesApiCallId: string = "";
  GetResumeCoverApiCallId: string = "";
  GetAllTestsApiCallId: string = "";
  GetFilteredCandidateListApiCallId: string = "";
  GetCandidateResultsApiId: string = "";
  GetCompanyResultsApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];    

    this.state = {
      candidateListData: [],
      filteredCandidateListData: [],
      candidateListCount: 0,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      completeScrollBarHeight: 1,
      visibleScrollBarHeight: 0,
      candidateCard: false,
      candidate: {
        name: "",
        applied: "",
        type: "",
        resume: "",
        cover: ""
      },
      editingUser: false,
      positionFilter: false,
      selectedFilter: '',
      tests: [],
      token: "",
      errorMsg: "",
      loading: true,
      guide: false,
      candidateDetailsData: [],
      resumes: [],
      coverLetters: [],
      noteView: false,
      notes: "",
      anchorEl: false,
      candidateResults: [],
      candidateId: '',
      resumeFiles: [],
      resumeName: '',
      typing: false,
      typingTimeout: 0,
      openSnackBar: false,
      profileError: '',
      snackType: 'error',
      openDocViewer: false,
      openResumeViewer: false,
      pathState: '',
      filteredMetaData: {},
      currentPage: 1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const path = window.location.href.split('/', 5).pop();
    const pathId = window.location.href.split('/', 7).pop();
    let livePath = window.location.href;
    if(path === 'all-results' && localStorage.getItem('role') == 'hr'){
      this.getCompanyResults();
    }
    if(livePath.includes('searched') && pathId){
      this.setState({ candidateCard: true, candidateId: pathId }, () => this.getCandidateResults())
      this.getAllTests();
      this.getCandidateDetails(pathId);
    }
    if(livePath.includes('.dev.')){
      this.setState({
        pathState: 'https://knowmeqcore-28018-ruby.28018.dev.ap-southeast-1.aws.svc.builder.cafe'
      })
    } else {
      this.setState({
        pathState: 'https://knowmeqcore-28018-ruby.28018.dev.ap-southeast-1.aws.svc.builder.cafe'
      })
    }
    if(path === 'roles'){
      this.getCandidateList();
    } else {
      this.getFilteredCandidateList();
    }
    this.getAllTests();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getCandidateList(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.candidateListApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCandidateListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidateListApiGetUrl+"/"+this.props.navigation.getParam('id')
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.candidateListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getFilteredCandidateList(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.filteredCandidateListApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetFilteredCandidateListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filteredCandidateListApiGetUrl+`?page=${this.state.currentPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.filteredCandidateListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getCandidateDetails(id: any): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.candidateDetailsApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCandidateDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidateDetailsApiGetUrl+"/"+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.candidateDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  updateCandidateCardStatus(candidateId: any, statusType: any): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.candidateStatusApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCandidateStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.candidateStatusUpdateApiPatchUrl + candidateId + "?" + configJSON.candidateStatusType + statusType
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   configJSON.candidateStatusType + statusType
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.candidateStatusApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  addCandidateNotes(id: any): boolean {
    // Customizable Area Start
    let formData = {
      "accounts":
        {
            "add_notes": this.state.notes
        }
      }
    const header = {
      "Content-Type": configJSON.addNotesApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAddNotesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNotesApiPostUrl+'/'+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addNotesApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  uploadResumeCover(id: any): boolean {
    let formData: any = new FormData()
    if(this.state.resumeFiles[0] && (this.state.coverLetters[0] == undefined || this.state.coverLetters[0] == null)){
      formData.append('accounts[resume_uri]', this.state.resumeFiles[0])
    } else if(this.state.coverLetters[0] && (this.state.resumeFiles[0] == undefined || this.state.resumeFiles[0] == null)){
      formData.append('accounts[cover_letter_uri]', this.state.coverLetters[0])
    } else {
      formData.append('accounts[resume_uri]', this.state.resumeFiles[0])
      formData.append('accounts[cover_letter_uri]', this.state.coverLetters[0])
    }
    const header = {
      // "Content-Type": configJSON.uploadResumeCoverContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetResumeCoverApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNotesApiPostUrl+'/'+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addNotesApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getAllTests(){
    const header = {
      'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllTestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      testInfoURL + 'tests?format=json'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getCompanyResults(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCompanyResultsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompanyResultsApiEndPoint+"?company_id="+localStorage.getItem('company_id')
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  getCandidateResults(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateResultsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateResultsApiEndPoint+"?user_id="+this.state.candidateId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      // let token = message.getData(getName(MessageEnum.SessionResponseToken));
      let token = localStorage.getItem('token');
      if(token){
        this.setState({ token: token, loading: true }, () => {
          const path = window.location.href.split('/', 5).pop();
          if(path === 'roles'){
            this.getCandidateList();
          } else {
            this.getFilteredCandidateList();
          }
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(apiRequestCallId != null){
        if(apiRequestCallId === this.GetCandidateListApiCallId){
          if(responseJson && !responseJson.error && responseJson.data){
            if(responseJson.data.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                candidateListData: responseJson.data,
                candidateListCount: responseJson.data.attributes.ceridian_employees.length,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              }, () => {
                this.sortJobPostCandidateList();
              });
            }
          } else{
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        if(apiRequestCallId === this.GetFilteredCandidateListApiCallId){
          if(responseJson && !responseJson.error && responseJson.employee_listing.data){
            if(responseJson.employee_listing.data.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                filteredCandidateListData: responseJson.employee_listing.data,
                filteredMetaData: responseJson.meta.pagination,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              }, () => {
                const path = window.location.href.split('/', 5).pop();
                this.sortCandidateList(path);
              });
            }
          } else{
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        if(apiRequestCallId === this.GetCandidateStatusApiCallId){
          if(responseJson && !responseJson.error && responseJson.data){
            if(responseJson.data.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              const path = window.location.href.split('/', 5).pop();
              if(path === 'roles'){
                this.getCandidateList();
              } else {
                this.getFilteredCandidateList();
              }
              this.setState({
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              });
            }
          } else{
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        if(apiRequestCallId === this.GetCandidateDetailsApiCallId){
          if(responseJson && !responseJson.error && responseJson.data){
            if(responseJson.data.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                candidateDetailsData: responseJson.data,
                candidateListCount: responseJson.count,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              }, () => {
                this.setState({
                  notes: this.state.candidateDetailsData?.attributes?.add_notes
                })
              });
            }
          } else{
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        if(apiRequestCallId === this.GetAddNotesApiCallId){
          if(responseJson && !responseJson.error && responseJson.data){
            if(responseJson.data.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false,
                openSnackBar: true,
                profileError: 'Something went wrong!',
                snackType: 'error'
              });
            } else {
              this.setState({
                candidateDetailsData: responseJson.data,
                candidateListCount: responseJson.count,
                errorMsg: "",
                loading: false,
                openSnackBar: true,
                profileError: 'Note added successfully!',
                snackType: 'success'
              });
            }
          } else{
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false,
                openSnackBar: true,
                profileError: 'Something went wrong!',
                snackType: 'error'
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false,
                openSnackBar: true,
                profileError: 'Something went wrong!',
                snackType: 'error'
              });
            }
          }
        }
        if(apiRequestCallId === this.GetResumeCoverApiCallId){
          if(responseJson && !responseJson.error && responseJson.data){
            if(responseJson.data.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false,
                openSnackBar: true,
                profileError: 'Something went wrong!',
                snackType: 'error'
              });
            } else {
              this.setState({
                candidateDetailsData: responseJson.data,
                candidateListCount: responseJson.count,
                errorMsg: "",
                loading: false,
                coverLetters: {},
                resumeFiles: {},
                guide: true, // set the guide to true
              });
            }
          } else{
            if (responseJson.errors) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false,
                openSnackBar: true,
                profileError: responseJson.errors[0].resume_uri,
                snackType: 'error'
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false,
                openSnackBar: true,
                profileError: 'Something went wrong!',
                snackType: 'error'
              });
            }
          }
        }
        if(apiRequestCallId === this.GetAllTestsApiCallId){
          if(responseJson && !responseJson.error){
            if(responseJson.length === 0){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                tests: responseJson,
                errorMsg: "",
                loading: false,
                guide: true // set the guide to true
              });
            }
          } else{
            if (responseJson.error) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: responseJson.error,
                loading: false
              });
            }
          }
        }
        if(apiRequestCallId === this.GetCandidateResultsApiId){
          if(responseJson && !responseJson.error){
            if(responseJson.data.length){
              this.setState({
                candidateResults: responseJson.data
              })
            } else {
              console.log("Something Went Wrong!")
            }
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // getTests() {
  //   this.setState({ tests: configJSON.test });    
  // }  

  onResumeDrop = (resumes: any) => {
    this.setState({resumes});
  }

  onCoverDrop = (coverLetters: any) => {
    this.setState({coverLetters});
  }

  changeNoteView = () => {
    this.setState(prevState => ({
      noteView: !prevState.noteView
    }));
  }

  formatDate = (eveDate: any) => {
    const date = new Date(eveDate);
    return date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+date.getDate();
  }

  closeFilter = () => {
    this.getFilteredCandidateList();
    this.setState({
      anchorEl: false
    });
  };

  openFilter = () => {
    this.setState({
      anchorEl: true
    });
  }


  sortCandidateList = (type: any) => {
    // this.getFilteredCandidateList();
    // let path = window.location.href.split('/', 5).pop();
    let tempArr = this.state.filteredCandidateListData;
    if(type === 'hire-date'){
      tempArr.sort((a: any, b: any) => {
        let da: any = new Date(a.attributes.hire_date),
        db: any = new Date(b.attributes.hire_date);
        return da - db;
      });
      this.setState({
        filteredCandidateListData: tempArr
      })
    } else if(type === 'interview-date'){
      tempArr.sort((a: any, b: any) => {
        let da: any = new Date(a.attributes.interview_date),
        db: any = new Date(b.attributes.interview_date);
        return da - db;
      });
      this.setState({
        filteredCandidateListData: tempArr
      })
    } else if(type === 'application-submission-date'){
      tempArr.sort((a: any, b: any) => {
        let da: any = new Date(a.attributes.application_submission_date),
        db: any = new Date(b.attributes.application_submission_date);
        return da - db;
      });
      this.setState({
        filteredCandidateListData: tempArr
      })
    } else if (type === 'star-rating'){
      tempArr.sort((a: any, b: any) => {
        let fa: any;
        let fb: any;

        fa = a.attributes.candidate_card_colour_type ? a.attributes.candidate_card_colour_type.toLowerCase() : 'standard';
        fb = b.attributes.candidate_card_colour_type ? b.attributes.candidate_card_colour_type.toLowerCase() : 'standard';
    
        if (fa < fb) {
            return 1;
        }
        if (fa > fb) {
            return -1;
        }
        return 0;
      });
      this.setState({
        filteredCandidateListData: tempArr
      })
    }
  }

  sortJobPostCandidateList = () => {
    let tempArr: any = this.state.candidateListData;
    tempArr.attributes.ceridian_employees.data.sort((a: any, b: any) => {
        let fa: any;
        let fb: any;

        fa = a.attributes.candidate_card_colour_type ? a.attributes.candidate_card_colour_type.toLowerCase() : 'standard';
        fb  = b.attributes.candidate_card_colour_type ? b.attributes.candidate_card_colour_type.toLowerCase() : 'standard';
  
        if (fa < fb) {
            return 1;
        }
        if (fa > fb) {
            return -1;
        }
        return 0;
    });
    this.setState({
      candidateListData: tempArr
    })
  }


  // Customizable Area End
}
