import React from "react";

export default function Lang(props: any) {
  return (
   <svg viewBox="0 0 72 72" fill={props.color}>
    <path d="M36,72C16.1,72,0,55.9,0,36S16.1,0,36,0s36,16.1,36,36S55.9,72,36,72z M36,3C17.8,3,3,17.8,3,36s14.8,33,33,33
      s33-14.8,33-33S54.2,3,36,3z"/>
    <path d="M37.3,9.6C37.3,9.6,37.2,9.6,37.3,9.6L37.3,9.6c-0.5,0-0.9,0-1.3,0c-0.3,0-0.7,0-1,0l0,0c0,0,0,0-0.1,0
      C20.8,10.2,9.6,21.8,9.6,36c0,14.6,11.8,26.4,26.4,26.4S62.4,50.6,62.4,36C62.4,21.8,51.3,10.3,37.3,9.6z M56.9,27.8h-6.7
      c-1.5-5.6-4.5-10.1-7.3-13.2C49.3,16.7,54.4,21.6,56.9,27.8z M58.4,36c0,1.5-0.2,3.1-0.5,4.5h-7.6c0.6-2.9,0.8-5.7,0.6-8.5
      c0-0.1,0-0.1,0-0.2H58C58.3,33.2,58.4,34.6,58.4,36z M13.6,36c0-1.4,0.1-2.8,0.4-4.2h7.3c0,0.1,0,0.1,0,0.2
      c-0.3,2.8-0.1,5.7,0.6,8.5H14C13.7,39.1,13.6,37.5,13.6,36z M25.2,32.4c0-0.2,0-0.4,0.1-0.6h8.8v8.7h-8.2
      C25.2,37.8,25,35.1,25.2,32.4z M46,27.8h-7.9V15.5C40.7,18,44.3,22.3,46,27.8z M34.1,15.5v12.3h-7.9C27.9,22.3,31.5,18,34.1,15.5z
       M34.1,44.5V55c-3-3.5-5.3-7-6.7-10.5H34.1z M38.1,44.5h6.7c-1.5,3.5-3.7,7-6.7,10.5V44.5z M38.1,40.5v-8.7h8.8
      c0,0.2,0.1,0.4,0.1,0.6c0.3,2.7,0,5.4-0.7,8.1H38.1z M29.4,14.5c-2.8,3.1-5.9,7.6-7.4,13.3h-6.9C17.6,21.5,22.9,16.6,29.4,14.5z
       M15.2,44.5H23c1.7,4.5,4.5,9,8.3,13.4C24,56.4,18,51.3,15.2,44.5z M40.9,57.9c3.9-4.5,6.6-8.9,8.3-13.4h7.6
      C54,51.2,48.1,56.3,40.9,57.9z"/>
    </svg>
  );
}
