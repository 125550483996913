import React from "react";

export default function Mute(props: any) {
    return (
        <svg fill={props.color} viewBox="0 0 72 72">
            <line stroke={props.color} strokeWidth="2" x1="32" x2="42" y1="20" y2="30" />
            <line stroke={props.color} strokeWidth="2" x1="42" x2="32" y1="20" y2="30" />
            <path d="M10,33H3  c-1.103,0-2-0.898-2-2V19c0-1.102,0.897-2,2-2h7" />
            <path d="M9.604,32.43  C9.256,32.129,9,31.391,9,30.754V19.247c0-0.637,0.256-1.388,0.604-1.689L22.274,4.926C23.905,3.27,26,3.898,26,6.327v36.988  c0,2.614-1.896,3.604-3.785,1.686L9.604,32.43z" />
        </svg>
    )
}