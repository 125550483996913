import React from "react";

export default function Back(props: any) {
  return (
    <svg
      viewBox="0 0 72 72"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
      <path d="M29.4959 60.4805L23.8874 54.5689L49.0495 31.832L54.658 37.7436L29.4959 60.4805Z" />
      <path d="M53.7483 37.0611L47.8367 42.5938L24.7967 17.0527L30.7083 11.5201L53.7483 37.0611Z" />
    </svg>
  );
}
