Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjecttaskTracking";
exports.labelBodyText = "ProjecttaskTracking Body";

exports.btnExampleTitle = "CLICK ME";
exports.testInfoAPIEndpoint = "https://knowmeq.me/api/test/"

exports.testCalculatorAPIEndpoit = "https://knowmeq.me/api/submit-test"

exports.testQuestionDraftAPIEndpoit = "bx_block_test/tests"
exports.testQuestionDraftUpdateAPIEndpoint = "bx_block_test/tests/update_draft"
exports.postResultsAPIEndPoint = "bx_block_test/candidate_test_results"

exports.getCnadidateTestResult = "https://knowmeq.me/api/user-result"

exports.getTestDraftAPIEndpoit = "bx_block_test/tests/get_draft"
// Customizable Area End