import React from "react";

export default function Back(props: any) {
  return (
    <svg fill={props.color} viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z"        
      />
      <path d="M43.0496 11.52L48.658 17.4316L23.4959 40.1684L17.8875 34.2569L43.0496 11.52Z" />
      <path d="M18.7971 34.9394L24.7087 29.4067L47.7487 54.9478L41.8371 60.4804L18.7971 34.9394Z" />
    </svg>
  );
}
