import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  // Button,
  Platform
} from "react-native";
import * as Styled from './theme'
import { ThemeProvider } from 'react-native-elements'
import { Divider } from 'react-native-elements'
import _ from 'lodash'
import { Typography, Box, Button, Modal, withStyles } from "@material-ui/core";
import { TypoGraphy } from "../../../components/src/TypoGraphy";
import { palette } from "../../../components/src/Palette";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import * as Helper from '../../../components/helpers/Helpers';
import CustomSVGIcon from "../../../components/src/SVGIcon";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
// Customizable Area End

import ProjecttaskTrackingController, {
  Props,
  configJSON
} from "./ProjecttaskTrackingController"
// import { Theme } from "@fullcalendar/react"
import * as Themes from "../../ProjecttaskTracking/src/theme"
import { RFPercentage } from "react-native-responsive-fontsize";
import { secondaryPalette } from "../../../components/src/Palette";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const modalStyle = (theme: any) => ({
  modal: {
    elevation: -99999,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexShrink: 1,
  },
  animatedItem: {
    animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
    backgroundColor: palette.primary.main,
    overflow: "auto"
  },
  animatedItemExiting: {
    animation: `$myEffectExit 500ms ${theme.transitions.easing.easeInOut
      }`,
    opacity: 0,
    transform: "translate(200%, 200%)",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "translate(200%, 200%)",
    },
    "100%": {
      opacity: 1,
      transform: "translate(0)",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      opacity: 1,
      transform: "translate(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translate(800%, 0%)",
    },
  },
})
class ProjecttaskTracking extends ProjecttaskTrackingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkTest2forNo(item: any) {
    if(item.question_id != 5){
      return item.answer == 'no' || item.answer == 0
    }
  }

  checkTest2forYes(item: any) {
    if(item.question_id != 5){
      return item.answer == null
    }
  }

  checkTest6forNo(item: any) {
    if(item.question_id != 2){
      return item.answer == 'no' || item.answer == 0
    }
  }

  checkTest6forYes(item: any) {
    if(item.question_id != 2){
      return item.answer == null
    }
  }

  checkForNull(item: any){
    return item.answer == null
  }

  setAnswers(questionId: any, answer: any) {
    let ansDefaultObj: any = this.state.ansDefaultObj;
    let payload: any = {};
    let draftPayload: any = {};
    let companyId: any = localStorage.getItem('company_id');
    let userId: any = localStorage.getItem('user_id');
    let ansArray: any = [];
    
    ansDefaultObj.questions.map((item: any, index: any) => {
      if (item.question_id === questionId) {
        if (item.question_type === "Yes-No") {
          if (item.answer === null || (answer === 'yes' || answer === 'no')) {
            ansDefaultObj.questions[index]['answer'] = answer === 'yes' ? 1 : 0
          }
        } else {
          if (item.answer === null || (answer === 1 || answer === 2 || answer === 3 || answer === 4 || answer === 5 || answer === 6 || answer === 7)) {
            ansDefaultObj.questions[index]['answer'] = answer
          }
        }
      }
    })
    ansDefaultObj['status'] = 'draft';
    ansDefaultObj['company_id'] = parseInt(companyId);
    ansDefaultObj['account_id'] = parseInt(userId);

    if(Number(window.location.href.split('/').pop()) == 2){
      let condition = ansDefaultObj.questions.some(this.checkTest2forNo);
      if(!condition){
        ansDefaultObj.questions.map((i: any, index: any) => {
          if(i.question_id == 5){
            ansDefaultObj.questions[index]['answer'] = null
          }
        })
      }
    }

    if(Number(window.location.href.split('/').pop()) == 6 || Number(window.location.href.split('/').pop()) == 8){
      let condition = ansDefaultObj.questions.some(this.checkTest6forNo);
      if(!condition){
        ansDefaultObj.questions.map((i: any, index: any) => {
          if(i.question_id == 2){
            ansDefaultObj.questions[index]['answer'] = null
          }
        })
      }
    }

    if(Number(window.location.href.split('/').pop()) == 7){
      if(questionId == 1 && (ansDefaultObj.questions[0].answer == 'yes' || ansDefaultObj.questions[0].answer == 1)){
        ansDefaultObj.questions[2]['answer'] = null
      } else if(questionId == 1 && (ansDefaultObj.questions[0].answer == 'no' || ansDefaultObj.questions[0].answer == 0)){
        ansDefaultObj.questions[1]['answer'] = null
      }

      if(questionId == 2 && (ansDefaultObj.questions[1].answer == 'yes' || ansDefaultObj.questions[1].answer == 1)){
        ansDefaultObj.questions[2]['answer'] = null
      } else if(questionId == 2 && (ansDefaultObj.questions[1].answer == 'no' || ansDefaultObj.questions[1].answer == 0)){
        ansDefaultObj.questions[2]['answer'] = null
      }
    }

    ansArray = ansDefaultObj.questions.filter((item: any) => item.answer !== null)
    let testAnswers: any = [];
    let tempObj: any = {};
    ansArray.map((item: any) => {
      tempObj[item.question_id] = item.answer;
    })

    testAnswers.push(tempObj)

    payload = {
      "test_id": Number(window.location.href.split('/').pop()),
      "user_id": parseInt(userId),
      "company_id": parseInt(companyId),
      "answers": testAnswers
    }

    draftPayload = ansDefaultObj

    this.setState({
      setAns: payload,
      draftPayload: draftPayload,
      ansObj: ansDefaultObj
    }, () => {
      this.updateDraftAnswers();
      this.setPayload(payload, draftPayload);
    });
  }

  setPayload(payload: any, draftPayload: any) {
    this.setState({
      ansPayload: payload,
      halfwayMark: Object.keys(payload.answers[0]).length == Math.ceil(draftPayload.questions.length / 2)
    }, () => {
      setTimeout(() => {
        this.setState({ halfwayMark: false })
      }, 5000)
    })
  }

  submitTest() {
    let ansDefaultObj: any = this.state.ansDefaultObj;
    let payload: any = {};
    let companyId: any = localStorage.getItem('company_id');
    let userId: any = localStorage.getItem('user_id');
    let ansArray: any = [];

    if(Number(window.location.href.split('/').pop()) == 2){
      let condition = ansDefaultObj.questions.some(this.checkTest2forNo);
      let condition2 = ansDefaultObj.questions.some(this.checkTest2forYes);
      if(condition){
        if(ansDefaultObj.questions[4].answer == null){
          this.setState({
            testError: 'Please select answer for all the given questions',
            openSnackBar: true
          })
          return;
        }
      } else if(condition2){
        this.setState({
          testError: 'Please select answer for all the given questions',
          openSnackBar: true
        })
        return;
      }
    }

    if(Number(window.location.href.split('/').pop()) == 6 || Number(window.location.href.split('/').pop()) == 8){
      let condition = ansDefaultObj.questions.some(this.checkTest6forNo);
      let condition2 = ansDefaultObj.questions.some(this.checkTest6forYes);
      if(condition){
        if(ansDefaultObj.questions[1].answer == null){
          this.setState({
            testError: 'Please select answer for all the given questions',
            openSnackBar: true
          })
          return;
        }
      } else if(condition2){
        this.setState({
          testError: 'Please select answer for all the given questions',
          openSnackBar: true
        })
        return;
      }
    }

    if(Number(window.location.href.split('/').pop()) == 7){
      if(ansDefaultObj.questions[0].answer == 1 || ansDefaultObj.questions[0].answer == 'yes'){
        if(ansDefaultObj.questions[0].answer == null){
          this.setState({
            testError: 'Please select answer for all the given questions',
            openSnackBar: true
          })
          return;
        } else if((ansDefaultObj.questions[1].answer == 'no' || ansDefaultObj.questions[1].answer == 0) && ansDefaultObj.questions[2].answer == null){
          this.setState({
            testError: 'Please select answer for all the given questions',
            openSnackBar: true
          })
          return;
        } else if(ansDefaultObj.questions[1].answer == null ){
          this.setState({
            testError: 'Please select answer for all the given questions',
            openSnackBar: true
          })
          return;
        }
      } else if((ansDefaultObj.questions[0].answer == 0 || ansDefaultObj.questions[0].answer == 'no') && ansDefaultObj.questions[2].answer == null){
        this.setState({
          testError: 'Please select answer for all the given questions',
          openSnackBar: true
        })
        return;
      } else if(ansDefaultObj.questions[0].answer == null){
        this.setState({
          testError: 'Please select answer for all the given questions',
          openSnackBar: true
        })
        return;
      }
    }

    if(Number(window.location.href.split('/').pop()) == 1 || Number(window.location.href.split('/').pop()) == 3 || Number(window.location.href.split('/').pop()) == 4 || Number(window.location.href.split('/').pop()) == 5){
      if(ansDefaultObj.questions.some(this.checkForNull)){
        this.setState({
          testError: 'Please select answer for all the given questions',
          openSnackBar: true
        })
        return;
      }
    }

    ansArray = ansDefaultObj.questions.filter((item: any) => item.answer !== null)
    let testAnswers: any = [];
    let tempObj: any = {};
    ansArray.map((item: any) => {
      tempObj[item.question_id] = item.answer;
    })

    testAnswers.push(tempObj)

    payload = {
      "test_id": Number(window.location.href.split('/').pop()),
      "user_id": parseInt(userId),
      "company_id": parseInt(companyId),
      "answers": testAnswers
    }

    this.setState({
      ansPayload: payload
    }, () => {
      this.getTestCalculator();
    })
  }



  buttonTypes(question: any, key: any) {
    switch (question.question_type) {
      case "Yes-No":
        return (
          question.answer === 1 ?
            <>
              <View style={{ display: 'flex', alignItems: "center" }}>
                <Styled.SelectedAnswerButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, "yes") }}><Styled.directions>Yes</Styled.directions></Styled.SelectedAnswerButton>
                <Divider style={{
                  marginVertical: '10px', backgroundColor: "transparent"
                }} />
              </View>
              <TypoGraphy align="center" variant="h6" color="#000000" fontWeight="light">OR</TypoGraphy>
              <View style={{ display: 'flex', alignItems: "center" }}>
                <Styled.AnswerButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, "no") }}><Styled.directions>No</Styled.directions></Styled.AnswerButton>
                <Divider style={{
                  marginVertical: '10px', backgroundColor: "transparent"
                }} />
              </View>
            </>
            : question.answer === 0 ?
              <>
                <View style={{ display: 'flex', alignItems: "center" }}>
                  <Styled.AnswerButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, "yes") }}><Styled.directions>Yes</Styled.directions></Styled.AnswerButton>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                </View>
                <TypoGraphy align="center" variant="h6" color="#000000" fontWeight="light">OR</TypoGraphy>
                <View style={{ display: 'flex', alignItems: "center" }}>
                  <Styled.SelectedAnswerButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, "no") }}><Styled.directions>No</Styled.directions></Styled.SelectedAnswerButton>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                </View>
              </>
              : <>
                <View style={{ display: 'flex', alignItems: "center" }}>
                  <Styled.AnswerButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, "yes") }}><Styled.directions>Yes</Styled.directions></Styled.AnswerButton>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                </View>
                <TypoGraphy align="center" variant="h6" color="#000000" fontWeight="light">OR</TypoGraphy>
                <View style={{ display: 'flex', alignItems: "center" }}>
                  <Styled.AnswerButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, "no") }}><Styled.directions>No</Styled.directions></Styled.AnswerButton>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                </View>
              </>
        )

      case "Multiple Choice":
        return (<>
          {
            this.state.testInfo.test_id == 4 ?
              key == 'EM' || key == 'EU' || key == 'EP' ?
                this.state.testInfo.answers[0].answer_group.slice(0, 6).sort(function (a: any, b: any) {
                  return a.answer_point - b.answer_point;
                }).reverse().map((item: any, index: any) => {
                  return (
                    <View style={{ display: 'flex', alignItems: "center" }} key={index}>
                      {
                        question.answer === item.answer_point ?
                          <>
                            <Styled.SelectedRoundButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, item.answer_point) }}><Styled.directions>{this.state.testInfo.answers[0].answer_group.slice(0, 6).length - index}</Styled.directions></Styled.SelectedRoundButton>
                            <Text style={{ marginTop: '10px' }}>{item.answer_title}</Text>
                          </>
                          : <>
                            <Styled.Button disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, item.answer_point) }}><Styled.directions>{this.state.testInfo.answers[0].answer_group.slice(0, 6).length - index}</Styled.directions></Styled.Button>
                            <Text style={{ marginTop: '10px' }}>{item.answer_title}</Text>
                          </>
                      }
                      <Divider style={{
                        marginVertical: '10px', backgroundColor: "transparent"
                      }} />
                    </View>
                  )
                })
                :
                this.state.testInfo.answers[0].answer_group.slice(6).sort(function (a: any, b: any) {
                  return a.answer_point - b.answer_point;
                }).reverse().map((item: any, index: any) => {
                  return (
                    <View style={{ display: 'flex', alignItems: "center" }} key={index}>
                      {
                        question.answer === item.answer_point ?
                          <>
                            <Styled.SelectedRoundButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, item.answer_point) }}><Styled.directions>{this.state.testInfo.answers[0].answer_group.slice(6).length - index}</Styled.directions></Styled.SelectedRoundButton>
                            <Text style={{ marginTop: '10px' }}>{item.answer_title}</Text>
                          </>
                          : <>
                            <Styled.Button disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, item.answer_point) }}><Styled.directions>{this.state.testInfo.answers[0].answer_group.slice(6).length - index}</Styled.directions></Styled.Button>
                            <Text style={{ marginTop: '10px' }}>{item.answer_title}</Text>
                          </>
                      }
                      <Divider style={{
                        marginVertical: '10px', backgroundColor: "transparent"
                      }} />
                    </View>
                  )
                })
              :
              this.state.testInfo.answers[0].answer_group.sort(function (a: any, b: any) {
                return a.answer_point - b.answer_point;
              }).reverse().map((item: any, index: any) => {
                return (
                  <View style={{ display: 'flex', alignItems: "center" }} key={index}>
                    {
                      question.answer === item.answer_point ?
                        <>
                          <Styled.SelectedRoundButton disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, item.answer_point) }}><Styled.directions>{this.state.testInfo.answers[0].answer_group.length - index}</Styled.directions></Styled.SelectedRoundButton>
                          <Text style={{ marginTop: '10px' }}>{item.answer_title}</Text>
                        </>
                        : <>
                          <Styled.Button disabled={this.state.testInfo.status === 'completed' ? true : false} onPress={() => { this.setAnswers(question.question_id, item.answer_point) }}><Styled.directions>{this.state.testInfo.answers[0].answer_group.length - index}</Styled.directions></Styled.Button>
                          <Text style={{ marginTop: '10px' }}>{item.answer_title}</Text>
                        </>
                    }
                    <Divider style={{
                      marginVertical: '10px', backgroundColor: "transparent"
                    }} />
                  </View>
                )
              })
          }
        </>)
      default:
        break;
    }
  }
  /**
   * 
   * @param value questions array of each category
   * @returns return JSX with all questions for the calling category
   */

  test2questionList(question: any) {
    return question.answer === 0;
  }

  listQuestions(value: any, key: any) {
    let questions: any = []
    let test2condition = value.slice(0, value.length - 1).some(this.test2questionList);
    value.map((question: any, index: any) => {
      if (this.state.testInfo.test_id == 2) {
        if (test2condition) {
          questions.push(
            <Box>
              <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                <Divider style={{
                  marginVertical: '10px', backgroundColor: "transparent"
                }} />
                <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                <Divider style={{
                  marginVertical: '40px', backgroundColor: "transparent"
                }} />
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                  {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                </Box>
              </Box>
              <Divider style={{
                marginVertical: '20px', backgroundColor: "transparent"
              }} />
            </Box>
          )
        } else {
          if (index + 1 !== value.length) {
            questions.push(
              <Box>
                <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                  <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                  <Divider style={{
                    marginVertical: '40px', backgroundColor: "transparent"
                  }} />
                  <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                    {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                  </Box>
                </Box>
                <Divider style={{
                  marginVertical: '20px', backgroundColor: "transparent"
                }} />
              </Box>
            )
          }
        }
      } else if (this.state.testInfo.test_id == 6 || this.state.testInfo.test_id == 8) {
        if ((value[index].answer == 1 || value[index].answer == null) && value[index].hierarchy == "high") {
          questions.push(
            <Box>
              <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                <Divider style={{
                  marginVertical: '10px', backgroundColor: "transparent"
                }} />
                <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                <Divider style={{
                  marginVertical: '40px', backgroundColor: "transparent"
                }} />
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                  {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                </Box>
              </Box>
              <Divider style={{
                marginVertical: '20px', backgroundColor: "transparent"
              }} />
            </Box>
          )
        } else {
          if (value[0].answer == 0) {
            questions.push(
              <Box>
                <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                  <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                  <Divider style={{
                    marginVertical: '40px', backgroundColor: "transparent"
                  }} />
                  <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                    {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                  </Box>
                </Box>
                <Divider style={{
                  marginVertical: '20px', backgroundColor: "transparent"
                }} />
              </Box>
            )
          }
        }
      } else if (this.state.testInfo.test_id == 7) {
        if ((value[index].answer == 1 || value[index].answer == 0 || value[index].answer == null) && value[index].hierarchy == "high") {
          questions.push(
            <Box>
              <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                <Divider style={{
                  marginVertical: '10px', backgroundColor: "transparent"
                }} />
                <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                <Divider style={{
                  marginVertical: '40px', backgroundColor: "transparent"
                }} />
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                  {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                </Box>
              </Box>
              <Divider style={{
                marginVertical: '20px', backgroundColor: "transparent"
              }} />
            </Box>
          )
        } else {
          if (value[0].answer == 1 && value[index].hierarchy == "medium") {
            questions.push(
              <Box>
                <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                  <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                  <Divider style={{
                    marginVertical: '40px', backgroundColor: "transparent"
                  }} />
                  <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                    {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                  </Box>
                </Box>
                <Divider style={{
                  marginVertical: '20px', backgroundColor: "transparent"
                }} />
              </Box>
            )
          }
          if ((value[0].answer == 0 || value[1].answer == 0) && value[index].hierarchy == "low") {
            questions.push(
              <Box>
                <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                  <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
                  <Divider style={{
                    marginVertical: '40px', backgroundColor: "transparent"
                  }} />
                  <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                    {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
                  </Box>
                </Box>
                <Divider style={{
                  marginVertical: '20px', backgroundColor: "transparent"
                }} />
              </Box>
            )
          }
        }
      } else {
        questions.push(
          <Box>
            <Box style={{ padding: "3em", backgroundColor: secondaryPalette.gray.shade5 }}>
              <Divider style={{
                marginVertical: '10px', backgroundColor: "transparent"
              }} />
              <TypoGraphy transform="none" align="center" variant="h5" color="#000000" fontWeight="light">{question.question_title}</TypoGraphy>
              <Divider style={{
                marginVertical: '40px', backgroundColor: "transparent"
              }} />
              <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", alignItems: "center" }}>
                {localStorage.getItem('role') == 'candidate' ? this.buttonTypes(question, key) : ''}
              </Box>
            </Box>
            <Divider style={{
              marginVertical: '20px', backgroundColor: "transparent"
            }} />
          </Box>
        )
      }

    })
    return questions
  }

  /**
   * 
   * @returns the category block, which in turn calls the listQuestions method
   */
  listCategories() {
    if (this.state.testInfo != undefined) {
      let testData = this.state.testInfo.questions
      testData = _.groupBy(testData, 'category_name')
      let content = []
      for (const [key, value] of Object.entries(testData)) {
        content.push(
          <View>
            {
              this.state.testInfo.test_id == 4 ? key === "EP" || key === "EU" || key === "EM" ?
                <>
                  <Divider style={{ marginVertical: '20px', backgroundColor: "transparent" }} />
                  <Styled.directions>Please rate each of the statements below</Styled.directions>
                  <Divider style={{
                    marginVertical: '20px', backgroundColor: "transparent"
                  }} />
                  <Box style={{ backgroundColor: palette.secondary.light, borderStyle: "solid", borderWidth: "3px", borderColor: palette.primary.main, padding: "2em" }}>
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-around">
                      <Box display="flex" flexDirection="row">
                        <TypoGraphy color={palette.primary.main} variant="h5">Rating Scale:</TypoGraphy>
                      </Box>
                      {
                        this.state.testInfo.answers[0].answer_group.slice(0, 6).sort(function (a: any, b: any) {
                          return a.answer_point - b.answer_point;
                        }).reverse().map((item: any, index: any) => {
                          return (
                            <Box display="flex" flexDirection="row" key={index}>
                              <TypoGraphy color={palette.primary.main} variant="h5">{item.answer_point}</TypoGraphy>
                              <TypoGraphy variant="h5" color="#000000" fontWeight="light">({item.answer_title})</TypoGraphy>
                            </Box>
                          )
                        })
                      }
                    </Box>
                  </Box>
                </>
                : <>
                  <Divider style={{ marginVertical: '20px', backgroundColor: "transparent" }} />
                  <Styled.directions>Please rate each of the statements below</Styled.directions>
                  <Divider style={{
                    marginVertical: '20px', backgroundColor: "transparent"
                  }} />
                  <Box style={{ backgroundColor: palette.secondary.light, borderStyle: "solid", borderWidth: "3px", borderColor: palette.primary.main, padding: "2em" }}>
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-around">
                      <Box display="flex" flexDirection="row">
                        <TypoGraphy color={palette.primary.main} variant="h5">Rating Scale:</TypoGraphy>
                      </Box>
                      {
                        this.state.testInfo.answers[0].answer_group.slice(6).sort(function (a: any, b: any) {
                          return a.answer_point - b.answer_point;
                        }).reverse().map((item: any, index: any) => {
                          return (
                            <Box display="flex" flexDirection="row" key={index}>
                              <TypoGraphy color={palette.primary.main} variant="h5">{item.answer_point}</TypoGraphy>
                              <TypoGraphy variant="h5" color="#000000" fontWeight="light">({item.answer_title})</TypoGraphy>
                            </Box>
                          )
                        })
                      }
                    </Box>
                  </Box>
                </>
              : null
            }
            <Divider style={{
              marginVertical: '20px', backgroundColor: "transparent"
            }} />
            <Styled.SubTitle>{key === null || key === "null" ? '' : key}</Styled.SubTitle>
            <Divider style={{
              marginVertical: '20px', backgroundColor: "transparent"
            }} />
            {this.listQuestions(value, key)}
          </View>
        )
      }
      return content
    }
  }

  handleClose = () => {
    this.setState({
      openSnackBar: false
    })
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Snackbar open={this.state.openSnackBar} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={this.handleClose} severity="error">
            <AlertTitle>Error</AlertTitle>
            {this.state.testError}
          </Alert>
        </Snackbar>
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            {
              this.state.testInfo !== undefined ?
                <ThemeProvider theme={Styled.palette}>
                  <Styled.StyledView>
                    <Styled.Title>{this.state.testInfo.test_title}</Styled.Title>
                    {/* <Divider style={{ marginVertical: '20px', backgroundColor: "transparent" }} />
                    <Styled.directions>{this.state.testError}</Styled.directions> */}
                    {/* <Styled.description>{this.state.testInfo.test_description ?? 'No description found!'}</Styled.description> */}
                    {this.state.testInfo.questions[0].question_type === 'Multiple Choice' && this.state.testInfo.test_id != 4 ?
                      <>
                        <Divider style={{ marginVertical: '20px', backgroundColor: "transparent" }} />
                        <Styled.directions>Please rate each of the statements below</Styled.directions>
                        <Divider style={{
                          marginVertical: '20px', backgroundColor: "transparent"
                        }} />
                        <Box style={{ backgroundColor: palette.secondary.light, borderStyle: "solid", borderWidth: "3px", borderColor: palette.primary.main, padding: "2em" }}>
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-around">
                            <Box display="flex" flexDirection="row">
                              <TypoGraphy color={palette.primary.main} variant="h5">Rating Scale:</TypoGraphy>
                            </Box>
                            {
                              this.state.testInfo.answers[0].answer_group.sort(function (a: any, b: any) {
                                return a.answer_point - b.answer_point;
                              }).reverse().map((item: any, index: any) => {
                                return (
                                  <Box display="flex" flexDirection="row" key={index}>
                                    <TypoGraphy color={palette.primary.main} variant="h5">{this.state.testInfo.answers[0].answer_group.length - index}</TypoGraphy>
                                    <TypoGraphy variant="h5" color="#000000" fontWeight="light">({item.answer_title})</TypoGraphy>
                                  </Box>
                                )
                              })
                            }
                          </Box>
                        </Box>
                      </>
                      : null
                    }
                    {this.listCategories()}
                  </Styled.StyledView>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.halfwayMark}
                    closeAfterTransition
                    BackdropProps={{
                      timeout: 500,
                      invisible: true,
                    }}>
                    <Box p={2} className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: false, })} width={isMobile ? (Helper.isPotrait() ? '100%' : '100%') : wp('15%')} height={isMobile ? (Helper.isPotrait() ? '40vh' : '20vh') : wp('8%')} overflow="none">
                      <Box display="flex" justifyContent="space-between">
                        <Box>
                          <Typography variant="h5" color="textPrimary">
                            You are at the halfway point.
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                          <Button onClick={() => { this.setState({ halfwayMark: false }) }}>
                            <CustomSVGIcon width="48px" icon="Affirmations" />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </ThemeProvider>
                : <></>
            }
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView >
        {
          localStorage.getItem('role') === 'candidate' ? 
          this.state?.testInfo?.status !== 'completed' ? 
            <Box style={{position: 'fixed', bottom: 10, left: 0, right: 0}}>
              <View style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: "row", alignItems: "center" }}> 
                <Box></Box>
                <Styled.ProgressButton onPress={() => this.props.navigation.navigate('Dashboard')}>
                  <Styled.ProgressButtonText>
                    Please save, I'll be back
                  </Styled.ProgressButtonText>
                </Styled.ProgressButton>
                <TouchableOpacity 
                  onPress={() => {
                    this.submitTest();
                  }}
                  style={{ 
                    padding: "1em", 
                    backgroundColor: Themes.palette.secondary.main, 
                    borderWidth: 3, 
                    borderColor: Themes.palette.primary.main 
                  }}
                >
                  <Text style={[styles.text, { color: Themes.palette.primary.main, fontSize: RFPercentage(1.7) }]}>I'm done, let's go</Text>
                </TouchableOpacity>
                <Box></Box>
              </View>
            </Box>
          : null
          : null
        }
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "80%" : "100%",
    backgroundColor: "#ffffffff",
    marginBottom: '30px'
  },
  text: {
    fontFamily: "Campton-Bold"
  },
});

export default withStyles(modalStyle)(ProjecttaskTracking);
// Customizable Area End
