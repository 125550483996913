import React from 'react';
import { View, Text, Platform, Image, StyleSheet } from 'react-native'
import { RFPercentage } from "react-native-responsive-fontsize"
import {
    deviceType
} from "react-device-detect"
import { Divider } from 'react-native-elements';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core';
import { palette } from './Palette';
import clsx from "clsx"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        animatedItem: {
            animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
        },
        animatedItemExiting: {
            animation: `$myEffectExit 500ms ${theme.transitions.easing.easeInOut}`,
            opacity: 0,
            transform: "translate(200%, 200%)"
        },
        "@keyframes myEffect": {
            "0%": {
                opacity: 0,
                transform: "translate(200%, 200%)"
            },
            "100%": {
                opacity: 1,
                transform: "translate(0)"
            }
        },
        "@keyframes myEffectExit": {
            "0%": {
                opacity: 1,
                transform: "translate(0)"
            },
            "100%": {
                opacity: 0,
                transform: "translate(800%, 0%)"
            }
        },
    })
);

export default function Popper(props: any) {
    const classes = useStyles();
    const [exit, setExit] = React.useState(false);
    return (
        <Box className={props.reRender ? clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit }) : ''} display={props.display == true ? 'flex' : 'none'}>
            {props.children}
        </Box >
    )
}

const styles = StyleSheet.create({
    popperBack: {
        backgroundColor: palette.primary.main,
        width: deviceType !== 'mobile' ? '50vw' : '100vw',
    },
    text: {
        fontFamily: "Campton-Bold"
    }
})