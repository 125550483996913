Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.baseURL = "https://knowmeqcore-28018-ruby.28018.dev.ap-southeast-1.aws.svc.builder.cafe";
exports.baseURLFront = "https://knowmeqcore-28018-react-native.28018.dev.ap-southeast-1.aws.svc.builder.cafe"
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.jobPostGetUrl = "bx_block_ceridian_job_posting/ceridian_job_postings/job_posting";
exports.jobPostApiMethodTYPE = "GET";
exports.jobPostApiRoleType = "?role="
exports.jobPostContentType = "application/json";
exports.dashboarContentType = "application/json";

exports.nylasEventListApiGetUrl = "bx_block_calendar/nylas_events";
exports.nylasEventListApiMethodType = "GET";
exports.nylasEventListApiContentType = "application/json";

exports.invitedTestsListApiGetUrl = "bx_block_notifications/notifications/test_lists";
exports.allTestListApiEndPoint = "https://knowmeq.me/api/tests";
exports.invitedTestsListApiMethodType = "GET";
exports.invitedTestsListApiContentType = "application/json";
exports.getTestDraftAPIEndpoit = "bx_block_test/tests/get_draft"

exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.greet = "Welcome to React";
exports.labelTitleText = "Your recruiter has set up an assessment to complete for your job application.";
exports.labelBodyText1 = "With these assessments provided by KnowMeQ, your recruiter will get a much more holistic understanding of who you are and how you will best fit into this position.";
exports.labelBodyText2 = "Take a minute, get set up, and when you are ready please click on the assessment available below to get started!";
exports.fieldGuideTitle = "I'm your Field Guide. & I'm here to support you throughout your KnowMeQ journey."
exports.positions = [
  {
    name: 'Delivery Associate Manager',
    status: 'Recent',
    org: 'Kelseys',
    candidates: 180
  },
  {
    name: 'Server',
    status: 'Starred',
    org: 'Swiss Chalet',
    candidates: 180
  },
  {
    name: 'Delivery Associate',
    status: 'Starred',
    org: 'Kelseys',
    candidates: 180
  }
];
exports.test = [
  {
    name: 'Digital competencies - Self-assessment grid',
    id : 1,
    status: 'Starred',
  },
];
// Customizable Area End