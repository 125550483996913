import i18n from "i18next";
import { palette } from "../Palette";

const lang = 'en';
const colors = localStorage.getItem('colors') as string;
const theme = {
    updated: false,
    setting: colors && localStorage.getItem('setting') !== 'null' ? localStorage.getItem('setting') as string : 'light_on_dark',
    colors: {
        primary: colors ? JSON.parse(colors).primary : palette.primary.main,
        secondary: colors ? JSON.parse(colors).secondary : palette.secondary.main,
        extra: colors ? JSON.parse(colors).extra : "#ffffff"
    }
};
const logo = '';
const expandRoles = false;
const test = false;
const expandContact = false;
const dialog = false;
export const changeLocale = (state = lang, action: any) => {
    if (action.type == 'LANGUAGE') {
        i18n.changeLanguage(action.payload)
        return action.payload
    }
    return lang;
}

export const updateTheme = (state = theme, action: any) => {
    if (action.type == 'theme') {
        let t = JSON.parse(JSON.stringify(theme));
        t.updated = action.payload.updated ?? t.updated;
        t.setting = action.payload.setting ?? t.setting;
        t.colors = action.payload.colors ?? t.colors;
        return t;
    }
    return theme;
}

export const updateLogo = (state = logo, action: any) => {
    if (action.type == 'logo') {
        return action.payload;
    }
    return logo;
}

export const expandFilter = (state = expandRoles, action: any) => {
    if (action.type == 'ROLE') {
        return action.payload;
    }
    return expandRoles;
}

export const testFilter = (state = test, action: any) => {
    if (action.type == 'TEST') {
        return action.payload;
    }
    return test;
}

export const contact = (state = expandContact, action: any) => {
    if (action.type == 'CONTACT') {
        return action.payload;
    }
    return test;
}

export const eventDialog = (state = dialog, action: any) => {
    if (action.type == 'EVENT') {
        return action.payload;
    }
    return dialog;
}