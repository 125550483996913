import React from "react";

export default function Lang(props: any) {
  return (
   <svg viewBox="0 0 72 72" fill={props.color}>
    <path d="M61.4,57c-1.9-2.4-3.8-4.9-4.5-6c2.6-3.9,4.1-8.7,4.1-13.8C61,23.3,49.7,12,36.3,12S11.6,23.3,11.6,37.2
      s11.3,25.3,24.7,25.3c4.3,0,8.5-1.1,11.4-3c0.5,0.8,2.1,3.1,3.9,5.5C55.3,63.1,58.7,60.3,61.4,57z M36.3,51.5
      c-7.6,0-13.9-6.4-13.9-14.3s6.3-14.3,13.9-14.3c7.7,0,13.9,6.7,13.9,14.3C50.1,44.9,43.9,51.5,36.3,51.5z"/>
    <path d="M36,72C16.1,72,0,55.9,0,36S16.1,0,36,0s36,16.1,36,36S55.9,72,36,72z M36,3C17.8,3,3,17.8,3,36s14.8,33,33,33
      s33-14.8,33-33S54.2,3,36,3z"/>
    </svg>
  );
}
