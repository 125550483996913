import React from 'react';
import { View, Text, Platform, Image, StyleSheet, TouchableOpacity } from 'react-native'
import { RFPercentage } from "react-native-responsive-fontsize"

export default function TestHeader(props: any) {
    const mainIconDimensions = RFPercentage(4)
    const scroll = React.useRef()
    return (
        <View>
            {props.children}
        </View>
    )
}
const styles = StyleSheet.create({
    text: {
        fontFamily: "Campton-Bold"
    }
})