import React from "react";

export default function Lang(props: any) {
	return (
		<svg viewBox="0 0 72 72" fill={props.color}>
			<path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
			<path d="M14.1726 28.421L14.021 20.3115H57.5999L57.7515 28.421H14.1726Z" />
			<path d="M19.5537 42.0631L19.4021 33.9536H52.2189L52.3705 42.0631H19.5537Z" />
			<path d="M24.9347 55.7052L24.7831 47.5957L46.8378 47.6715L46.9894 55.7052H24.9347Z" />
		</svg>
	);
}
