import React from "react";

export default function Lang(props: any) {
	return (
		<svg viewBox="0 0 72 72" fill={props.color}>
			<path d="M36 1.47829e-06C55.8568 -2.5765e-07 72 16.1432 72 36C72 55.8568 55.8568 72 36 72C16.1432 72 -3.08021e-06 55.8568 -4.81615e-06 36C-6.55209e-06 16.1432 16.1431 3.21423e-06 36 1.47829e-06ZM36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158C17.8105 3.03158 3.03157 17.8105 3.03157 36C3.03158 54.1895 17.8105 68.9684 36 68.9684Z"/>
			<path d="M35.1663 31.8315L20.0084 31.8315L22.661 29.1789L18.1136 24.6315L12.0505 30.6189L7.5031 35.242L12.0505 39.7894L18.1136 45.8525L22.661 41.2294L19.7052 38.2736L35.1663 38.2736L35.1663 31.8315Z"/>
			<path d="M23.8736 42.4423L23.8736 52.9771L57.3726 52.9771L57.3726 17.5076L23.8736 17.5076L23.8736 28.1939L31.0736 28.1939L31.0736 24.7834L50.2483 24.7834L50.2483 45.7013L31.0736 45.7013L31.0736 42.4423L23.8736 42.4423Z"/>
		</svg>

	);
}
