import React from "react";
import { Box, Typography, Button, Slide } from "@material-ui/core";
import { Image, Dimensions } from "react-native";
import { TypoGraphy } from "./TypoGraphy";
import { palette } from "./Palette";
import { Divider } from 'react-native-elements';
import Test from "./Test";

export default function Candidate(props: any) {
    const [editCandidate, setEditCandidate] = React.useState(false);
    return (
        <Box>
            <Box style={{ padding: "1.5em", backgroundColor: palette.primary.main }}>
                <Box>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                            <TypoGraphy variant="h3" color="secondary">
                                {props.data.name}
                            </TypoGraphy>
                        </Box>
                        <Box>
                            <Button onClick={() => setEditCandidate(true)}>
                                <Image style={{ height: "2em", width: "2em" }} source={editCandidate ? require(`./editing.svg`) : require(`./edit.svg`)} />
                            </Button>
                        </Box>
                        <Box>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Image style={{ height: "2em", width: "2em" }} source={require(`./profile.svg`)} />
                                <TypoGraphy variant="h5" color="#ffffff"> &nbsp;&nbsp;Current Employee</TypoGraphy>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Image style={{ height: "2em", width: "2em" }} source={require(`./expand-secondary.svg`)} />
                            <Image style={{ marginLeft: "1.5em", marginRight: "1.5em", height: "2em", width: "2em" }} source={require(`./starred.svg`)} />
                            <Image style={{ height: "2em", width: "2em" }} source={require(`./minimize.svg`)} />
                        </Box>
                    </Box>
                </Box>
                <Box style={{ marginTop: Dimensions.get('window').width >= 768 ? "8em" : "2.5em", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box flex="1">
                        <TypoGraphy color="#ffffff">
                            Resume:
                            <Typography style={{ display: editCandidate ? 'flex' : 'none', textDecorationLine: "underline" }}>DRAG &amp; DROP FILE</Typography>
                        </TypoGraphy>
                    </Box>
                    <Box flex="1.1">
                        <TypoGraphy color="#ffffff">
                            Cover Letter:
                            <Typography style={{ display: editCandidate ? 'flex' : 'none', textDecorationLine: "underline" }}>DRAG &amp; DROP FILE</Typography>
                        </TypoGraphy>
                    </Box>
                </Box>
            </Box>
            <Slide in={editCandidate} direction="down" mountOnEnter unmountOnExit>
                <Box style={{ padding: 10, borderWidth: 3, borderColor: palette.primary.main }}>
                    <Typography style={{ padding: "3em", borderColor: palette.primary.main, borderWidth: 2, borderStyle: "dashed", textAlign: "center", color: palette.primary.main, textDecorationLine: "underline" }}>
                        ADD NOTES
                    </Typography>
                </Box>
            </Slide>
            <Box style={{ marginTop: "5em" }}>
                <Test type="declared" />
            </Box>
            <Divider style={{
                marginVertical: '5em', backgroundColor: "transparent"
            }} />
        </Box>
    )
}