export const changeLocale = (locale:string) => {
    return {
        type: "LANGUAGE",
        payload : locale
    }
}

export const updateTheme = (theme: object) => {
    return {
        type: "theme",
        payload: theme
    }
}

export const updateLogo = (logo: string) => {
    return {
        type: "logo",
        payload: logo
    }
}

export const expandFilter = (expandRoles: boolean) => {
    return {
        type: "ROLE",
        payload: expandRoles
    }
}

export const testFilter = (test: boolean) => {
    return {
        type: "TEST",
        payload: test
    }
}

export const toggleContactModal = (test: boolean) => {
    return {
        type: "CONTACT",
        payload: test
    }
}

export const openEventDialog = (test: boolean) => {
    return {
        type: "EVENT",
        payload: test
    }
}