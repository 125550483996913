Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DashboardGuests";
exports.labelBodyText = "DashboardGuests Body";

exports.candidateListApiGetUrl = "/bx_block_ceridian_job_posting/ceridian_job_postings";
exports.candidateListApiMethod = "GET";
exports.candidateListApiContentType = "application/json";

exports.filteredCandidateListApiGetUrl = "bx_block_ceridian_employee/ceridian_employees";
exports.filteredCandidateListApiMethod = "GET";
exports.filteredCandidateListApiContentType = "application/json";

exports.candidateDetailsApiGetUrl = "bx_block_ceridian_employee/ceridian_employees";
exports.candidateDetailsApiMethod = "GET";
exports.candidateDetailsApiContentType = "application/json";

exports.candidateStatusUpdateApiPatchUrl = "/bx_block_ceridian_employee/ceridian_employees/";
exports.candidateStatusApiMethod = "PATCH";
exports.candidateStatusApiContentType = "application/json";
exports.candidateStatusType = "accounts[candidate_card_colour_type]=";

exports.addNotesApiPostUrl = "bx_block_ceridian_employee/ceridian_employees";
exports.addNotesApiMethod = "PATCH";
exports.addNotesApiContentType = "application/json";

exports.getCandidateResultsApiEndPoint = "bx_block_test/candidate_test_results";

exports.getCompanyResultsApiEndPoint = "https://knowmeq.me/api/company-result";

exports.btnExampleTitle = "CLICK ME";
exports.test = [
  {
    name: 'Digital competencies - Self-assessment grid',
    id: 1,
    status: 'Starred',
  },
];
// Customizable Area End