import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import { palette } from "./Palette"

interface Props {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    align?: string;
    transform?: string;
    pointer?: boolean;
}

export const TypoGraphy = (Props: any) => {
    const useStyles = makeStyles((theme) => ({
        colors: {
            color: Props.color ? Props.color : palette.primary.main,
            textAlign: Props.align ?? 'left',
            textTransform: Props.transform ?? 'capitalize',
            fontSize: Props.fontSize,
            fontFamily: Props.fontWeight == 'light' ? 'Campton-Light' : 'Campton-Bold',
            textDecoration: Props.textDecoration ?? 'none',
            cursor: Props.cursor ?? 'auto'
        }
    }));
    const classes = useStyles();
    return (
        <Typography {...Props} variant={Props.variant} className={classes.colors} align={Props.align}>
            {Props.children}
        </Typography>
    )
}