import React, { useEffect } from 'react';
import { View, Text, Platform, Image, StyleSheet, TouchableOpacity } from 'react-native'
import TestHeader from './TestHeader'
import { RFPercentage } from "react-native-responsive-fontsize"
import * as Theme from '../../blocks/ProjecttaskTracking/src/theme'
import { Divider } from 'react-native-elements'
import { useSelector, useDispatch } from 'react-redux'
import { TypoGraphy } from './TypoGraphy'
import Badge from '@material-ui/core/Badge';
import { Button, Box, Container, TextareaAutosize, Typography, Grid, Modal, ListItem, List, ListItemText, SvgIcon, Checkbox, FormControl, FormGroup, FormControlLabel, Icon, TextField } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'// a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import styled from 'styled-components'
import { palette, secondaryPalette } from './Palette'
import FormField from './FormField'
import KnowMeButton from './Button'
import { withStyles, useTheme } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CustomSVGIcon from "../../components/src/SVGIcon";
import moment from "moment";
import { date } from 'yup';
import { toggleContactModal } from "./actions/index";

// @ts-ignore
import Pdf from "react-to-pdf";
// @ts-ignore
import { CSVLink } from "react-csv";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as config from "./config";
import axios from 'axios';

const StyledBadge = withStyles((theme) => ({
    badge: {
        marginLeft: '10px',
        marginRight: '10px',
        border: 'none',
        padding: '0 4px',
        fontFamily: 'Campton-Bold',
        fontSize: RFPercentage(1),
        color: '#10069f',
        backgroundColor: '#64CBBA'
    },
}))(Badge);

const StyledWrapper = styled.div`
    * {
        font-family : Campton-Bold;
    }
    .fc-day-today .fc-daygrid-day-frame {
        background: #ffffff
    }
    .fc-daygrid-day-number {
        color: #10069f;
        font-weight: 500
    }
    .fc-day-today .fc-daygrid-day-number{
        color: #64CBBA;
        padding:0.3em;
        background: #10069f;
        border-radius: none;
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .fc-timegrid-slot-label-cushion{
        text-transform: uppercase
    }
    .fc-timegrid-axis-cushion, .fc-direction-ltr .fc-timegrid-slot-label-frame {
        text-align: center;
    }
    .fc-timegrid-slot-minor {
        border: 0!important;
    }
    .fc-col-header-cell-cushion {
        word-spacing: 20px;
        display: none;
    } 
    .fc-event {
        border-width: 3px;
        border-radius: 0;
        padding: 5px;
        padding-top: 30%;
        justify-content: center;
        align-items: center;
    }
    .fc-direction-ltr .fc-timegrid-col-events {
        margin: 0;
    }
    .fc .fc-timegrid-slot {
        height: 2.2em;
    }
`
const calendarRef: any = React.createRef()

const Accordion = withStyles({
    root: {
        width: "100%",
        fontFamily: "Campton-Bold",
        borderBottom: '5px solid #fff',
        boxShadow: 'none',
        '&:last-child': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: Theme.palette.primary.main,
        borderBottom: 'none',
        marginBottom: -1,
        padding: '20px',
        paddingLeft: '55px',
        paddingRight: '55px',
        color: '#fff',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        display: 'flex',
        paddingTop: '7px',
        paddingBottom: '0px',
        paddingRight: '0px',
        paddingLeft: '0px',
        width: '100%',
    },
}))(MuiAccordionDetails);

const ref = React.createRef();

export default function Test(props: any) {
    const mainIconDimensions = RFPercentage(4)
    const [drawer, openDrawer] = React.useState(localStorage.getItem('role') == 'candidate' ? props.resultId ? true : false : true)
    const [options, openOptions] = React.useState(true)
    const scroll = React.useRef()
    const dispatch = useDispatch()
    const [expanded, setExpanded] = React.useState('');
    const [textAreaOpen, setTextArea] = React.useState<any>(false);
    const [noteText, setNoteText] = React.useState<any>('');
    const [currentDate, setCurrentDate] = React.useState('');
    const [weekDays, setWeekDays] = React.useState<any>([]);
    const [isInitialRender, setIsInitialRender] = React.useState(true);
    const [newDate, setNewDate] = React.useState<any>();
    const [btnEnable, setBtnEnable] = React.useState<any>(false);
    const [open, setOpen] = React.useState(false);
    const [errorText, setErrorText] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [event, setEvent] = React.useState<any>({});
    const baseConfig = require("../../blocks/dashboard/src/config");

    React.useEffect(() => {
        if (props.top) {
            scrollToImportantStuff()
        }
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeValue = (e: any) => {
        const value: any = e.target.value;
        console.log("EMAIL", value)
        setEmail(value)
        let regEmail: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(value)) {
            console.log("INSIDE")
            setErrorText(true)
        } else {
            setErrorText(false)
            setEmail(value);
        }
    }

    const shareViaEmail = () => {
        const requestOptions: any = {
            method: "GET",
            headers: {
                token: localStorage.getItem("token"),
            },
        };
        fetch(
            baseConfig.baseURL +
            "/bx_block_test/candidate_test_results/send_email?id=" + props.id + "&email=" + email + "&title=prose_literacy",
            requestOptions
        )
            .then((response) => {
                if (response.status === 204) {
                    setOpen(false);
                    setErrorText(false);
                } else {
                    console.log("FAILED", response)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const messageRef: any = React.useRef();

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        setCurrentDate(moment(new Date()).format('MMMM D, YYYY'));
        let date: any = new Date()
        setNewDate(date);
        if (isInitialRender) {
            if (calendarRef.current) {
                let calendarApi = calendarRef?.current.getApi();
                setIsInitialRender(false);
                getWeek(calendarApi.view.title);
            }
        }
    }, [weekDays, isInitialRender]);

    const getWeek = (title: any) => {
        let str = title;
        let arr = str.split(" ")
        if (arr.length === 6) {
            arr.splice(2, 3)
        } else {
            arr.splice(2, 2)
        }
        let date = new Date(arr)
        let nextDay = new Date(date)
        let weekDays = [];
        let firstDate;
        let day;
        let month;
        let tomorrow;
        for (var i = 0; i < 7; i++) {
            tomorrow = moment(nextDay).add(i, 'days');
            firstDate = moment(tomorrow).date();
            day = moment(tomorrow).format('ddd');
            month = moment(tomorrow).format('MMM');
            weekDays.push({ firstDate, day, month })
        }
        setWeekDays(weekDays);
    }

    const changeWeek = (type: string) => {
        let calendarApi = calendarRef?.current.getApi();
        switch (type) {
            case "next":
                calendarApi.next();
                getWeek(calendarApi.view.title);
                break;
            case "prev":
                calendarApi.prev();
                getWeek(calendarApi.view.title);
                break;
            default:
                calendarApi.today();
                getWeek(calendarApi.view.title);
                break;
        }
    }

    const getFirstName = (fullName: any) => {
        let name = fullName.split(" ");
        return name.splice(0, 1);
    }

    const getLastName = (fullName: any) => {
        let name = fullName.split(" ");
        return name.splice(1, 1);
    }

    const openTextArea = () => {
        setTextArea(true)
    }

    const eventClicked = (info: any, type: any) => {
        if (!info.event?._def.title) {
            setEvent(info);
            setBtnEnable(true)
        } else {
            setBtnEnable(false)
        }
    }

    const updateResult = (result: any) => {
        let userId: any = props.results.user_id;
        let data: any = {
            "candidate_test_results":
            {
                "user_id": parseInt(userId),
                "result": result
            }
        }
        const requestOptions: any = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
            },
            body: JSON.stringify(data)
        };

        fetch(
            baseConfig.baseURL + "/bx_block_test/candidate_test_results/" + props.resultId,
            requestOptions
        )
            .then((response) => {
                if (response.status === 200) {
                    setTextArea(false)
                    setNoteText(messageRef.current.value)
                    console.log("RESPONSE JSON", response)
                    console.log("NOTE TEXT", noteText)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getResult = () => {
        let userId: any = props.results.user_id;
        const requestOptions: any = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
            }
        };
        fetch(
            "https://knowmeq.me/api/user-result?user_id=" + userId + "&test_id=" + props.results.test_id,
            requestOptions
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson) {
                    updateResult(responseJson);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const submitNote = () => {
        let userId: any = props.results.user_id;
        const data: any = {
            "user_id": parseInt(userId),
            "test_id": props.results.test_id,
            "hr_note": messageRef.current.value
        }
        const requestOptions: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        };
        fetch(
            "https://knowmeq.me/api/submit-acer-note",
            requestOptions
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.Success === "Note added successfully") {
                    getResult();
                } else {
                    console.log("SOMETHING WENT WRONG")
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const scrollToImportantStuff = () => {
        // @ts-ignore
        document.getElementById('ImportantStuff').scrollIntoView()
    }

    /**
     * function to create nylas event by the candidate
     */
    const createEvent = () => {
        let eventData = {
            "nylas_event": {
                "title": localStorage.getItem('fullName'),
                "account_id": localStorage.getItem('user_id'),
                "calendar_id": config.calendarID, // passing static calendar ID for now
                "participants_attributes": [
                    {
                        "email": localStorage.getItem('email'),
                        "status": "yes"
                    }
                ],
                "description": '',
                "location": '',
                "recurrence_rrule": "RRULE:FREQ=WEEKLY;BYDAY=MO",
                "timezone": "America/New_York",
                "time": moment(event?.dateStr).startOf('hour').format('X'),
                "start_time": moment(event?.dateStr).startOf('hour').format('X'),
                "end_time": moment(event?.dateStr).add(1, 'hour').startOf('hour').format('X'),
            }
        };
        axios.post(config.baseURL + '/bx_block_calendar/nylas_events', eventData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            }).then((response: any) => {
                props.updateState({ showAlert: true, alertType: 'success', alertMessage: 'Event created successfully!', eventCreated: true })
            }).catch((error: any) => {
                props.updateState({ showAlert: true, alertType: 'error', alertMessage: 'Event not created!' })
            })
        setBtnEnable(false);
    }

    const theme = useTheme();

    return (
        // @ts-ignore
        <Box ref={ref} id="ImportantStuff">
            <Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            borderRadius: '0px'
                        },
                    }}
                >
                    <DialogTitle id="form-dialog-title">
                        <Text style={{ fontSize: RFPercentage(1.5), color: Theme.palette.primary.main }}>Share Via Email</Text>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide email ID where you would like us to send results.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            error={errorText}
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            name="email"
                            fullWidth
                            helperText={errorText ? "Invalid Email" : null}
                            onChange={e => changeValue(e)}
                            onBlur={e => changeValue(e)}
                            style={{ color: 'red' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <TouchableOpacity disabled={errorText} onPress={shareViaEmail} style={{ backgroundColor: Theme.palette.primary.main, padding: '10px' }}>
                            <Text style={{ fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Share</Text>
                        </TouchableOpacity>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box style={{ padding: "2em", backgroundColor: Theme.palette.primary.main, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontFamily: "Campton-Bold", fontSize: RFPercentage(2), color: "#ffffff" }}>
                    {props.testName}
                </Text>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <TouchableOpacity onPress={() => { openDrawer(!drawer), scroll.current }}>
                        <Image style={{ height: "3em", width: "3em" }} source={drawer ? require(`./close.svg`) : require(`./add.svg`)} />
                    </TouchableOpacity>
                    {/* {
                        props.type == 'declared' ? (
                            <TouchableOpacity onPress={() => { openOptions(!options), scroll.current }}>
                                <Image style={{ marginLeft: "1em", height: "3em", width: "3em" }} source={require(`./options.svg`)} />
                            </TouchableOpacity>
                        ) : (<></>)
                    } */}
                </Box>
            </Box>
            {
                props.results?.hr_flag == 'Completed' ? (
                    <TestHeader ref={scroll}>
                        <Box style={{ display: drawer ? "flex" : "none", flexDirection: 'row' }}>
                            <Box style={{ padding: "3.5em", border: '3px solid', borderColor: Theme.palette.primary.main }}>
                                <Box style={{ display: options ? "flex" : "none" }}>
                                    <Box style={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "row" }}>
                                        <TouchableOpacity onPress={handleClickOpen} style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                            <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                <Image style={{ height: "2em", width: "2em" }} source={require(`./share.svg`)} />
                                                <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Share Via Email</Text>
                                            </Box>
                                        </TouchableOpacity>
                                        <Pdf targetRef={ref} scale={0.69} filename="Prose.pdf">
                                            {({ toPdf }: any) => {
                                                return (
                                                    <TouchableOpacity onPress={toPdf} style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                                        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                            <Image style={{ height: "2em", width: "2em" }} source={require(`./download.svg`)} />
                                                            <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Download PDF</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                )
                                            }}
                                        </Pdf>
                                        <CSVLink filename={"prose-result.csv"} style={{ textDecoration: 'none' }}>
                                            <TouchableOpacity style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }}>
                                                <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                                                    <Image style={{ height: "2em", width: "2em" }} source={require(`./download.svg`)} />
                                                    <Text style={{ marginLeft: "1em", fontFamily: 'Campton-Bold', fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }}>Download CSV</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </CSVLink>
                                    </Box>
                                    <Divider style={{
                                        marginVertical: '2em', backgroundColor: "transparent"
                                    }} />
                                </Box>
                                <Box style={{ marginTop: '3em', display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontFamily: "Campton-Light", fontSize: RFPercentage(1.2), lineHeight: 26 }}>
                                        {props.mainDescription}
                                    </Text>
                                    <Text style={{ fontFamily: "Campton-Bold", marginTop: "1.5em", fontSize: RFPercentage(1.2) }}>
                                        Please click on any of the sections in the graph to explore them further
                                    </Text>
                                </Box>
                                {
                                    textAreaOpen ?
                                        <FormField
                                            inputRef={messageRef}
                                            readOnly={false}
                                            inputcolor={"#10069F"}
                                            multiline={true}
                                            bgcolor={"#ffffff"}
                                            rows={10}
                                            width={100}
                                            passwordfield={false}
                                            placeholder={"Write a note..."}
                                            autoFocus
                                        />
                                        : null
                                }
                                {
                                    !textAreaOpen ?
                                        props.results?.hr_flag === "Completed" || noteText ?
                                            <Text style={{ fontFamily: "Campton-Light", fontSize: RFPercentage(1.2), lineHeight: 26 }}>
                                                {noteText ? noteText : props.results.hr_note}
                                            </Text>
                                            : <Text style={{ fontFamily: "Campton-Light", fontSize: RFPercentage(1.2), lineHeight: 26 }}>
                                                The meeting is still not completed with the HR.
                                            </Text>
                                        : null

                                }
                                {/* <Text style={{ fontFamily: "Campton-Bold", marginTop: "1.5em", fontSize: RFPercentage(1.2) }}>
                                    Please click on any of the sections in the graph to explore them further
                                </Text> */}
                                {
                                    props.results?.hr_flag === "Completed" ?
                                        null
                                        : textAreaOpen ?
                                            <Box style={{ display: options ? "flex" : "none" }}>
                                                <Box style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '3em',
                                                    flexDirection: "row"
                                                }}>
                                                    <TouchableOpacity style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }} onPress={() => submitNote()}>
                                                        <View style={[styles.inlineView, { alignItems: "center" }]}>
                                                            <Text style={[styles.text, { marginLeft: "1em", fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }]}>Submit Note</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </Box>
                                            </Box>
                                            :
                                            <Box style={{ display: options ? "flex" : "none" }}>
                                                <Box style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '3em',
                                                    flexDirection: "row"
                                                }}>
                                                    <TouchableOpacity style={{ backgroundColor: Theme.palette.primary.main, padding: '1em' }} onPress={() => openTextArea()}>
                                                        <View style={[styles.inlineView, { alignItems: "center" }]}>
                                                            <Text style={[styles.text, { marginLeft: "1em", fontSize: RFPercentage(1.5), color: Theme.palette.secondary.main }]}>Update Note</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </Box>
                                            </Box>
                                }
                            </Box>
                            <View style={{ borderWidth: 3, borderColor: Theme.palette.primary.main }}>
                                <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography>Total EI</Typography>
                                            <Typography>87</Typography>
                                        </View>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <View style={{ display: "flex", width: '100%', flexDirection: "row", justifyContent: "space-between", backgroundColor: Theme.palette.primary.main }}>
                                            <View style={{ borderRightWidth: 2, borderColor: "#ffffff", backgroundColor: "#4038b2", padding: "5em", flex: 1 }}>
                                                <Text style={{ color: "#ffffff", textAlign: "center" }}>A</Text>
                                            </View>
                                            <View style={{ borderRightWidth: 2, borderColor: Theme.palette.primary.main, backgroundColor: "#706ac5", padding: "5em", flex: 3 }}>
                                                <Text style={{ color: "#ffffff", textAlign: "center" }}>C</Text>
                                            </View>
                                            <View style={{ borderRightWidth: 2, borderColor: Theme.palette.primary.main, backgroundColor: "#9f9bd9", padding: "5em", flex: 2 }}>
                                                <Text style={{ textAlign: "center" }}>E</Text>
                                            </View>
                                            <View style={{ backgroundColor: "#cfcdec", padding: "5em", flex: 4 }}>
                                                <Text style={{ textAlign: "center" }}>R</Text>
                                            </View>
                                        </View>
                                    </AccordionDetails>
                                </Accordion>
                            </View>
                        </Box>
                        <Box style={{ marginTop: "2em", marginBottom: "2em", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
                            <Text style={{ fontFamily: "Campton-Bold", fontSize: RFPercentage(1) }}>Need Help</Text>
                            <Button onClick={(e: any) => { dispatch(toggleContactModal(true)) }}>
                                <Typography color="textSecondary" variant="h6">Need Help?</Typography>
                                <Image style={{ marginLeft: "1em", height: 24, width: 24 }} source={require(`./help.svg`)} />
                            </Button>
                        </Box>
                    </TestHeader>
                ) : (
                    /**
                     * show calendar for undeclared tests
                     */
                    <Box display={drawer ? "block" : "none"} style={{ padding: "3em", borderStyle: "solid", borderWidth: 3, borderColor: palette.primary.main, marginBottom: '40px' }}>
                        <Box mb="3em">
                            <TypoGraphy transform="none" variant="h5" color="#000000">Your results are not yet ready to view. Please start by booking an appointment with one of our coaches</TypoGraphy>
                        </Box>
                        <View style={{ padding: "0.3em", paddingTop: "0.7em", paddingBottom: "0.7em", backgroundColor: Theme.palette.primary.main, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Grid item sm={1} md={1} style={{ display: 'flex', marginLeft: '-5px' }}>
                                <KnowMeButton onClick={() => changeWeek("prev")} style={{ minWidth: '10px', marginLeft: '-15px', marginTop: '5px', padding: '0px!important' }} bgcolor={'transparent'}>
                                    <CustomSVGIcon width="1.6em" height="1.6em" icon="Back" color={palette.secondary.main} />
                                </KnowMeButton>
                                <KnowMeButton onClick={() => changeWeek("next")} style={{ minWidth: '10px', marginLeft: '-35px', marginTop: '5px', padding: '0px!important' }} bgcolor={'transparent'}>
                                    <CustomSVGIcon width="1.6em" height="1.6em" icon="Next" color={palette.secondary.main} />
                                </KnowMeButton>
                            </Grid>
                            <Grid item sm={8} md={8} style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '-30px', marginTop: '15px' }}>
                                {
                                    weekDays.map((item: any, index: any) => {
                                        return (
                                            <Box key={index}>
                                                {item.firstDate === newDate.getDate() ?
                                                    <Text style={{ color: palette.secondary.main, fontFamily: 'Campton-Bold', fontSize: RFPercentage(0.8) }}>
                                                        <span style={{ marginRight: '20px' }}>{item.day} &nbsp;</span>
                                                        <StyledBadge badgeContent={item.firstDate} color="secondary">

                                                        </StyledBadge>
                                                        <span style={{ marginLeft: '5px' }}>{item.month} &nbsp;</span>
                                                    </Text>
                                                    : <Text style={{ color: palette.secondary.main, fontFamily: 'Campton-Bold', fontSize: RFPercentage(0.8) }}>
                                                        {item.day} &nbsp;
                                                        {item.firstDate} &nbsp;
                                                        {item.month}
                                                    </Text>
                                                }
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item sm={3} md={3}>
                                <Box style={{ marginTop: '8px', marginLeft: '30px' }}>
                                    <Text style={{ color: palette.secondary.main, fontFamily: 'Campton-Bold', fontSize: RFPercentage(2) }}>
                                        {moment(currentDate).format('ll')}
                                    </Text>
                                </Box>
                            </Grid>
                        </View>
                        <Grid container style={{ border: `3px solid ${palette.primary.main}` }}>
                            <Grid item sm={9} md={9} style={{ height: '600px', overflowY: 'scroll' }}>
                                <StyledWrapper>
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={false}
                                        height='auto'
                                        allDayText="All Day"
                                        displayEventTime={false}
                                        dateClick={(arg: any) => {
                                            eventClicked(arg, 'dateClicked')
                                        }}
                                        eventClick={(arg: any) => eventClicked(arg, 'eventClicked')}
                                        events={props.events}
                                        eventBackgroundColor={theme.palette.primary.main}
                                        eventTextColor={theme.palette.secondary.main}
                                        eventBorderColor={theme.palette.primary.main}
                                    />
                                </StyledWrapper>
                            </Grid>
                            <Grid item sm={3} style={{ backgroundColor: '#e9e9e9', borderLeft: `3px solid ${palette.primary.main}` }}>
                                <Box display="flex" flexDirection="column" paddingX={5} paddingY={5}>
                                    <TypoGraphy color={palette.primary.main} variant="subtitle1">Please select a time and fill out the form below</TypoGraphy>
                                    <Box>
                                        <FormControl required component="fieldset" >
                                            <Box mt="2em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">First Name</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={getFirstName(localStorage.getItem('fullName'))} readOnly={true} />
                                            </Box>
                                            <Box mt="0.5em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Last Name</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={getLastName(localStorage.getItem('fullName'))} readOnly={true} />
                                            </Box>
                                            <Box mt="0.5em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">{localStorage.getItem('role') == 'candidate' ? 'Candidate #' : 'Employee #'}</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('applicationId')} readOnly={true} />
                                            </Box>
                                            <Box mt="0.5em">
                                                <TypoGraphy color={palette.primary.main} variant="subtitle1">Email Address</TypoGraphy>
                                                <FormField bgcolor={secondaryPalette.gray.shade4} passwordfield={false} height={'auto'} value={localStorage.getItem('email')} readOnly={true} />
                                            </Box>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid sm={4} md={4}>
                                <Box mt="2em">
                                    <KnowMeButton
                                        onClick={createEvent}
                                        variant="contained"
                                        disabled={!btnEnable}
                                        bgcolor="#10069f"
                                        color="#64CBBA"
                                        height={'56px'}
                                    >
                                        Submit
                                    </KnowMeButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
        </Box >
    )
}

const styles = StyleSheet.create({
    text: {
        fontFamily: "Campton-Bold"
    },
    inlineView: {
        display: "flex", justifyContent: "space-between", flexDirection: "row"
    },
    updateButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3em',
        flexDirection: "row"
    }
})