import React from "react";

export default function Pause(props: any) {
    return (
        <svg viewBox="0 0 24 24" fill={props.color}>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
        </svg>
    );
}
