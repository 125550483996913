import React from "react";

export default function Time(props: any) {
    return (
        <svg viewBox="0 0 72 72" fill={props.color}>
            <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z"/>
            <path d="M32.4395 38.6527L48.7342 49.5664L52.6752 43.6548L36.3805 32.8169L32.4395 38.6527Z"/>
            <path d="M32.4385 10.0801L39.5627 10.1559L39.4869 38.7285L32.4385 38.6527V10.0801Z"/>
        </svg>
    );
}
