
import React from "react";

export default function Result(props: any) {
    return (
        <svg viewBox="0 0 72 72" fill={props.color}>
            <path d="M15.0808 15.8398V61.4651C17.9608 63.8904 21.2955 65.7851 24.9334 67.0735L26.2977 67.6798L27.965 67.983C29.3292 68.3619 30.7692 68.5893 32.2092 68.7409L25.6913 62.4504L28.5713 59.4946L33.725 64.6483L42.4408 55.8567L45.3966 58.7367L35.0892 68.9683C35.3924 68.9683 35.6955 68.9683 35.9987 68.9683C43.8808 68.9683 51.1566 66.1641 56.8408 61.5409V15.8398H15.0808ZM33.725 52.6735L25.6913 44.7914L28.5713 41.8356L33.725 46.9893L42.4408 38.1977L45.3966 41.0777L33.725 52.6735ZM33.725 35.0146L25.6913 27.2083L28.5713 24.2525L33.725 29.4062L42.4408 20.6146L45.3966 23.4946L33.725 35.0146Z" />
            <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
        </svg>
    );
}
