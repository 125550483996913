import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { Divider } from 'react-native-elements'
import CompetencyResults from "../../../components/src/CompetencyResults"
import ProseResults from "../../../components/src/ProseResults"
import OceanResults from "../../../components/src/OceanResults"
import EiResults from "../../../components/src/EiResults"
import Test from "../../../components/src/Test"
import * as Themes from "../../ProjecttaskTracking/src/theme"
import Popper from "../../../components/src/Popper"
import { RFPercentage } from "react-native-responsive-fontsize"
import { Box, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { connect } from "react-redux";
import moment from "moment";
// Customizable Area End

import AccountScorerankingController, {
  Props
} from "./AccountScorerankingController";
import { palette } from "../../../components/src/Palette";
import { TypoGraphy } from "../../../components/src/TypoGraphy";
class AccountScoreranking extends AccountScorerankingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start    
    this.getCandidateResults();
    // Customizable Area End
  }

  // Customizable Area Start
  path: any = window.location.href.split('/', 5).pop();
  async componentDidMount() {
    setTimeout(() => {
      this.setState({ reRender: false }) // To stop rerendering of the component
    }, 1000)
  }

  /**
   * This will fetch the events only after a new event gets created
   * the 'eventCreated' will be set to true in the Test.tsx component
   * and will be updated to false to prevent request loop
   */
  async componentDidUpdate() {
    if (this.state.eventCreated) {
      this.setState({ eventCreated: false }, () => {
        this.getNylasEventList(moment().startOf('week').format('X'), moment().endOf('week').format('X'));
      })
    }
  }

  stateHandler = (event: any) => {
    this.setState(event)
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        <Snackbar open={this.state.showAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={() => { this.setState({ showAlert: false }); this.getNylasEventList(moment().startOf('week').format('X'), moment().endOf('week').format('X')) }} severity={this.state.alertType}>
            <AlertTitle>{this.state.alertType}</AlertTitle>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <View>
              <View style={styles.contentContainer}>
                <View>
                  <Text style={{ fontSize: RFPercentage(4.5), color: Themes.palette.primary.main, fontFamily: "Campton-Bold" }}>Hi, {localStorage.getItem('fullName')}! {this.state.candidateResults.length ? 'Thank you for completing your assessment.' : 'you are waiting on results and need to complete assessments to view results.'}</Text>
                  <Divider style={{
                    marginVertical: '10px', backgroundColor: "transparent"
                  }} />
                </View>
              </View>
              {/* transitioning sidebar */}
              <Divider style={{
                marginVertical: '48px', backgroundColor: "transparent"
              }} />

              {/* start transition popup */}
              <Popper display={true} reRender={this.state.reRender}>
                <Box style={{ backgroundColor: palette.primary.main, display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "4em" }}>
                  <Box style={{ flex: 2 }}>
                    <Box width="99%">
                      <TypoGraphy transform="none" variant="h3" color={palette.secondary.main}>
                        Your assessment is completed
                      </TypoGraphy>
                    </Box>
                    <Divider style={{
                      marginVertical: '10px', backgroundColor: "transparent"
                    }} />
                    <TypoGraphy transform="none" variant="h4" color="#ffffff">
                      We have compiled your results
                    </TypoGraphy>
                  </Box>
                  <Box>
                    <Image style={{ marginLeft: "1em", height: "3em", width: "3em" }} source={require(`../assets/affirmations.svg`)} />
                  </Box>
                </Box>
              </Popper>
              {/* end transition popup */}

              <Divider style={{
                marginVertical: '48px', backgroundColor: "transparent"
              }} />
              <Box margin="auto" width="80%">
                {
                  this.state.candidateResults.map((item: any) => {
                    if (item.attributes.result.test_id == 1) {
                      return <CompetencyResults events={this.state.nylasEventData} top={this.path == 1 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 1 ? this.path : null} route="results" results={item.attributes.result?.result} mainDescription={item.attributes?.description} testName="Digital Competencies - Self-assessment grid" desc={item.attributes.result} id={item.id} />
                    } else if (item.attributes.result.test_id == 2) {
                      return <ProseResults top={this.path == 2 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 2 ? this.path : null} route="results" results={item.attributes.result?.result} mainDescription={item.attributes?.description} testName="Problem Solving" id={item.id} />
                    } else if (item.attributes.result.test_id == 3) {
                      return <OceanResults top={this.path == 3 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 3 ? this.path : null} route="results" results={item.attributes.result?.result} mainDescription={item.attributes?.description} testName="OCEAN" id={item.id} />
                    } else if (item.attributes.result.test_id == 4) {
                      return <EiResults top={this.path == 4 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 4 ? this.path : null} route="results" results={item.attributes.result} mainDescription={item.attributes?.description} testName="Emotional Intelligence" id={item.id} />
                    } else if (item.attributes.result.test_id == 5) {
                      return <Test top={this.path == 5 ? true : false} type={"pending"} route="results" results={item.attributes.result?.result} resultId={this.path == 'results' ? true : this.path == 5 ? this.path : null} mainDescription={item.attributes?.description} testName="ACER: Your Emotional Intelligence at a Glance" id={item.id} />
                    } else if (item.attributes.result.test_id == 6) {
                      return <ProseResults top={this.path == 6 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 6 ? this.path : null} mainDescription={item.attributes?.description} route="results" results={item.attributes.result?.result} testName="Document Literacy" id={item.id} />
                    } else if (item.attributes.result.test_id == 7) {
                      return <ProseResults top={this.path == 7 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 7 ? this.path : null} mainDescription={item.attributes?.description} route="results" results={item.attributes.result?.result} testName="Numeracy" id={item.id} />
                    } else if (item.attributes.result.test_id == 8) {
                      return <ProseResults top={this.path == 8 ? true : false} type={item.attributes.result === null ? "pending" : "declared"} resultId={this.path == 'results' ? true : this.path == 8 ? this.path : null} mainDescription={item.attributes?.description} route="results" results={item.attributes.result?.result} testName="Prose Literacy" id={item.id} />
                    } else {
                      return <Test updateState={this.stateHandler} events={this.state.nylasEventData} type={"pending"} route="results" results={item.attributes.result?.result} resultId={this.path == 'results' ? true : this.path == 5 ? this.path : null} mainDescription={item.attributes?.description} testName="ACER: Your Emotional Intelligence at a Glance" id={item.id} />
                    }
                  })
                }
              </Box>
              <Divider style={{
                marginVertical: '5em', backgroundColor: "transparent"
              }} />
            </View>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView >
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    margin: "auto", width: Platform.OS == 'web' ? "90%" : "95%"
  }
});
const mapStateToProps = (state: any) => ({
  state: state,
});

export default connect(mapStateToProps)(AccountScoreranking);
// Customizable Area End
