import React, { FunctionComponent, ReactNode } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { palette, secondaryPalette } from "./Palette";
import { connect } from "react-redux";

interface Props {
  children: ReactNode;
  title?: string;
  type?: string;
  cardFor?: string;
}

const GenericCard: FunctionComponent<Props> = (props: any) => {
  let color: string = "";
  const colors = props.state.updateTheme.present.colors
  switch (props.type) {
    case "Recent":
      color = colors.primary;
      break;
    case "Starred":
      color = colors.secondary + '80';
      break;
    case 'starred':
      color = palette.primary.main
      break;
    case 'standard':
      color = palette.secondary.light
      break;
    case 'minimized':
      color = secondaryPalette.gray.shade4
      break;
    case "Closed":
      color = colors.secondary;
      break;
    default:
      break;
  }
  const useStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: color,
      border: props.cardFor === 'jobs' ? 'none' : "2px solid" +  palette.primary.main,
    },
  }));
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={classes.card}
    >
      {props.children}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  state: state,
});

export default connect(mapStateToProps)(GenericCard);
