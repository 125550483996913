import React from "react";

export default function Knowmeq(props: any) {
  return (
    <svg fill={props.color} version="1.1" id="icn-check" x="0px" y="0px" viewBox="0 0 72 72">
      <path d="M 36 72 C 16.1 72 0 55.9 0 36 S 16.1 0 36 0 s 36 16.1 36 36 S 55.9 72 36 72 z M 36 3 C 17.8 3 3 17.8 3 36 s 14.8 33 33 33 s 33 -14.8 33 -33 S 54.2 3 36 3 z"/>
      <path d="M 38.2 45.5 L 20.7 28.2 l -6.3 6.5 l 17.7 17.2 L 38.2 45.5 z"/>
      <path d="M 32.1 51.9 l -6.3 -6.1 l 25.4 -25.6 l 6.5 6.3 L 32.1 51.9 z"/>
    </svg>
  );
}
