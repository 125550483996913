import { changeLocale, updateTheme, updateLogo, expandFilter, testFilter, contact, eventDialog } from './locale';
import { combineReducers } from 'redux';
import undoable from 'redux-undo';

const rootReducer = combineReducers({
    changeLocale,
    updateTheme: undoable(updateTheme) ?? '',
    updateLogo,
    expandFilter,
    testFilter,
    contact,
    eventDialog
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
