import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  location: any;
  // Customizable Area Start
  state: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  candidateResults: any;
  errorMsg: string;
  nylasEventData: any;
  reRender: boolean;
  showAlert: boolean;
  alertType: undefined;
  alertMessage: string;
  eventCreated: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AccountScorerankingController extends BlockComponent<
  Props,
  S,
  SS
> {
  GetNylasEventListCallId: string = "";
  GetCandidateResultsApiId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getNylasEventList(moment().startOf('week').format('X'), moment().endOf('week').format('X'));

    // Customizable Area Start

    if (!localStorage.getItem('token')) {
      window.location.href = '/'
    }

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      candidateResults: [],
      errorMsg: '',
      nylasEventData: [],
      reRender: true,
      showAlert: false,
      alertType: undefined,
      alertMessage: '',
      eventCreated: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCandidateResults();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {

        //Test questions list third party api response
        if (apiRequestCallId === this.GetCandidateResultsApiId) {
          if (responseJson && !responseJson.error) {
            if (responseJson.data.length) {
              this.setState({
                candidateResults: responseJson.data
              })
            } else {
              console.log("Something Went Wrong!")
            }
          }
        }
      }
    }

    // Nylas calendar events listing response

    if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.GetNylasEventListCallId) {
      if (responseJson && !responseJson.error && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
          });
        } else {
          let eveArr: object[] = [];
          responseJson.data.map((nylasEvent: any, index: any) => {
            eveArr.push({ title: nylasEvent?.attributes?.title, start: moment.unix(nylasEvent?.attributes?.start_time).format(), end: moment.unix(nylasEvent?.attributes?.end_time).format() });
          })
          // formatting eveArr to round off events to nearest hours and changing the title of each event
          eveArr.map((event: any) => {
            event.start = moment(event.start).startOf('hour').toISOString();
            var endTime = moment(event.end);
            var roundUp = endTime.minute() || endTime.second() || endTime.millisecond() ? endTime.add(1, 'hour').startOf('hour') : endTime.startOf('hour');
            event.end = roundUp.toISOString();
            event.title = "Not Available"
          })
          this.setState({
            nylasEventData: eveArr,
          });
        }
      } else {
        this.setState({ errorMsg: "Data not found" });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Function to get candidate results from third party api using User ID
  getCandidateResults(): boolean {
    const userId: any = localStorage.getItem('user_id');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCandidateResultsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateResultsApiEndPoint + "?user_id=" + localStorage.getItem('user_id')
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  //Function to get Nylas calendar event from third party api
  getNylasEventList(to: string, from: string): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetNylasEventListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNylasEvents + `/?start=${to}&end=${from}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.nylasEventListApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  // Customizable Area End
}
