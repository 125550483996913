
import React from "react";

export default function Expand(props: any) {
    return (
        <svg viewBox="0 0 72 72" fill={props.color}>
            <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z"/>
            <path d="M49.8661 17.1284H47.6682H39.2556V23.5705H47.6682V56.3874H54.1104V17.1284H49.8661Z"/>
            <path d="M39.7123 34.3326L29.026 23.5705H32.7397V17.1284H24.2513H17.7334V23.5705V32.1347H24.2513V27.8905L35.165 38.88L39.7123 34.3326Z"/>
            <path d="M24.2506 49.8693V38.5767H17.7327V49.8693V56.3114H47.6695V49.8693H24.2506Z"/>
        </svg>
    );
}
