import React from 'react';
import { TextField, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TypoGraphy } from "./TypoGraphy";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export default function FormField(props: any) {
    const [password, showPassword] = React.useState(props.passwordfield ?? "false");
    const useStyles = makeStyles((theme) => ({
        input: {
            height: props.height + '%',
            borderRadius: 0,
            fontSize: "1em",
            color: props.inputcolor,
            backgroundColor: props.bgcolor ? props.bgcolor : '#ffffff',
            '& > .MuiInputBase-input': {
                height: '100%'
            }
        },
        field: {
            width: props.width + '%' ?? '100%',
            height: props.height + '%' ?? '100%',
        }
    }));
    const classes = useStyles();

    return (
        <Box height="100%" width="100%">
            <Typography variant={props.size} color="primary">{props.text}</Typography>
            <TextField
                type={props.passwordfield ? password ? "password" : "text" : "text"}
                className={classes.field}
                placeholder={props.placeholder}
                label={false}
                defaultValue={props.value}
                InputProps={{
                    readOnly: props.readOnly,
                    className: classes.input,
                    endAdornment: props.passwordfield == true ? (<Button onClick={() => showPassword(!password)}>{password ? <VisibilityOffIcon /> : <VisibilityIcon />}</Button>) : <></>,
                }}
                multiline={props.multiline ?? false}
                rows={props.multiline ? props.rows : 0}
                variant="outlined"
                {...props}
            />
        </Box>
    )
}