import React from "react";

export default function Knowmeq(props: any) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92.0373 65.56"
    >
      <g id="logoMark" fill={props.color}>
        <rect
          id="logoMark_PathItem_"
          data-name="logoMark &lt;PathItem&gt;"
          x="41.344"
          y="10"
          width="9.349"
          height="24.743"
        />
        <rect
          id="logoMark_PathItem_2"
          data-name="logoMark &lt;PathItem&gt;"
          x="56.7444"
          y="30.9102"
          width="24.7428"
          height="9.3493"
          transform="translate(-8.2305 43.384) rotate(-33)"
        />
        <rect
          id="logoMark_PathItem_3"
          data-name="logoMark &lt;PathItem&gt;"
          x="18.2468"
          y="23.2136"
          width="9.3493"
          height="24.7428"
          transform="translate(-19.4065 35.4274) rotate(-56.9997)"
        />
        <path
          id="logoMark_PathItem_4"
          data-name="logoMark &lt;PathItem&gt;"
          d="M45.9527,55.56a5.751,5.751,0,1,0,0-11.484,5.7453,5.7453,0,1,0,0,11.484"
        />
      </g>
    </svg>
  );
}
