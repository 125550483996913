import React from "react";

export default function Lang(props: any) {
	return (
		<svg viewBox="0 0 72 72" fill={props.color}>
			<path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z"/>
			<path d="M36.8337 40.1685H51.9916L49.339 42.8211L53.8864 47.3685L59.9495 41.3811L64.4969 36.758L59.9495 32.2106L53.8864 26.1475L49.339 30.7706L52.2948 33.7264H36.8337V40.1685Z"/>
			<path d="M48.1264 29.5577V19.0229H14.6274V54.4924H48.1264V43.8061H40.9264V47.2166H21.7517V26.2987H40.9264V29.5577H48.1264Z"/>
		</svg>
	);
}
