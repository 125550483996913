
import React from "react";

export default function Lang(props: any) {
    return (
        <svg viewBox="0 0 72 72" fill={props.color} xmlns="http://www.w3.org/2000/svg">
            <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z" />
            <path d="M24.2527 10.8379V62.7537L56.9937 36.7579L24.2527 10.8379ZM37.2127 45.6253C37.0611 45.9284 36.9095 46.1558 36.7579 46.3832C36.5306 46.6105 36.3032 46.7621 36.0001 46.8379C35.6969 46.9895 35.3937 46.9895 35.0906 46.9895C34.7874 46.9895 34.4843 46.9137 34.1811 46.8379C33.8779 46.6863 33.6506 46.5347 33.4232 46.3832C33.1958 46.1558 33.0443 45.9284 32.9685 45.6253C32.8169 45.3221 32.8169 45.0189 32.8169 44.7158C32.8169 44.4126 32.8927 44.1095 32.9685 43.8063C33.1201 43.5032 33.2716 43.2758 33.4232 43.0484C33.6506 42.821 33.8779 42.6695 34.1811 42.5937C34.4843 42.4421 34.7874 42.4421 35.0906 42.4421C35.3937 42.4421 35.6969 42.5179 36.0001 42.5937C36.3032 42.7453 36.5306 42.8968 36.7579 43.0484C36.9853 43.2758 37.1369 43.5032 37.2127 43.8063C37.3643 44.1095 37.3643 44.4126 37.3643 44.7158C37.3643 45.0189 37.2885 45.3221 37.2127 45.6253ZM40.3201 36.0758C40.0927 36.4547 39.7895 36.8337 39.4106 37.2126C39.0316 37.5916 38.5011 38.0463 37.9706 38.501C37.5916 38.8042 37.3643 39.1074 37.2127 39.3347C37.0611 39.6379 36.9853 39.8653 36.9853 40.1684V40.9263H33.2716L33.1958 39.9411C33.1958 39.4105 33.2716 39.0316 33.4232 38.6526C33.5748 38.2737 33.8022 37.9705 34.1053 37.6674C34.5601 37.2126 35.0148 36.8337 35.3937 36.5305C35.7727 36.2274 36.1516 35.9242 36.379 35.621C36.6822 35.3179 36.9095 35.0905 37.0611 34.7874C37.2127 34.56 37.2885 34.2568 37.2885 33.9537C37.2885 33.4232 37.1369 32.9684 36.7579 32.6653C36.4548 32.3621 36.0001 32.2105 35.4695 32.2105C34.7116 32.2105 34.1053 32.4379 33.7264 32.8168C33.3474 33.2716 33.1201 33.8021 33.0443 34.4084L29.2548 34.181C29.4064 32.4379 30.0885 31.0737 31.2253 30.1642C32.3622 29.2547 33.7264 28.8 35.4695 28.8C36.2274 28.8 36.9095 28.8758 37.5916 29.1032C38.2737 29.3305 38.8801 29.6337 39.3348 30.0126C39.8653 30.3916 40.2443 30.9221 40.5474 31.5284C40.8506 32.1347 41.0022 32.8168 41.0022 33.6505C41.0022 34.1053 40.9264 34.56 40.8506 34.9389C40.7748 35.3179 40.5474 35.6968 40.3201 36.0758Z" />
        </svg>
    );
}
