import React, {useEffect} from "react";
import { Animated, View, Image, StyleSheet, TouchableWithoutFeedback, TouchableOpacity, Text, } from "react-native";
import {
    makeStyles, Theme, createStyles, withStyles, styled
} from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Button,
    Slide,
    Typography,
    Link,
    MenuItem,
    Select,
    FormControl,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    Tooltip
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import clsx from "clsx";
import Slider from "@material-ui/core/Slider";
import ReactPlayer from "react-player";
import LinearProgress from "@material-ui/core/LinearProgress";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeLocale, updateTheme, updateLogo, toggleContactModal } from "../actions/index";
import { useTranslation } from "react-i18next";
import { Dimensions } from "react-native";
import { palette } from "../Palette";
import { TypoGraphy } from "../TypoGraphy";
import FormField from "../FormField";
import KnowMeButton from "../Button";
import { baseURL, frontURL } from "../config";
import axios from "axios";
import { connect } from "react-redux";
import CustomSVGIcon from "../SVGIcon";
import { ActionCreators } from 'redux-undo';
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as Helper from '../../helpers/Helpers';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
let status: any = [];
import Player from "./fieldGuideTabs/Player.web";

function FieldGuide(Props: any) {
    let propColors: any;
    propColors = Props.state.updateTheme.present.colors;
    const colors = Props.state.updateTheme.present.colors;
    const role: any = localStorage.getItem("role");

    // component state start
    const [drawer, openDrawer] = React.useState(false);
    const [subMenu, setSubMenu] = React.useState(false);
    const [subMenuAffirmations, setSubMenuAffirmations] = React.useState(false);
    const [exit, setExit] = React.useState(false);
    const [fgDashbaord, setfgDashbaord] = React.useState(false);
    const [surveyModal, setSurveyModal] = React.useState(false);
    const [animation] = React.useState(new Animated.Value(0));
    const [success, setSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [mediaPlayer, setMediaPlayer] = React.useState(false);
    const [mute, mutePlayer] = React.useState(true);
    const [playing, setPlaying] = React.useState(true);
    const [seek, setSeek] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const [error, setError] = React.useState(false);
    const [overlay, setOverlay] = React.useState("flex");
    const [langDropdown, setlang] = React.useState(false);
    const [customizer, setCustomizer] = React.useState(false);
    const [publish, setPublish] = React.useState(false);
    const [logo, setLogo] = React.useState('no_file_selected');
    const [colorSelected, setcolorSelected] = React.useState(false);
    const [selectedTheme, setselectedTheme] = React.useState(localStorage.getItem('setting') ?? '');
    const [invitedTestList, setInvitedTestList] = React.useState<any>([]);
    const [count, setCount] = React.useState<Number>(0);
    const baseConfig = require("../../../blocks/dashboard/src/config");
    const [affirmation, setAffirmation] = React.useState('');
    const [videoURL, setvideoURL] = React.useState('');
    const [validate, setValidate] = React.useState<any>(false);
    const [testStatus, setInvitedTestStatus] = React.useState<any>();
    // component state start

    // defining refs start
    const ref: any = React.useRef();
    const messageRef: any = React.useRef();
    const themeColor1: any = React.useRef();
    const themeColor2: any = React.useRef();
    const themeColor3: any = React.useRef();
    const logoFile: any = React.useRef();
    // defining refs stop

    // defining component specific styles start
    const fieldGuideStyles = makeStyles((theme: Theme) =>
        createStyles({
            subContainer: {
                paddingLeft: 50,
                paddingBottom: 50,
            },
            insetMenu: {
                position: "absolute",
                bottom: 0,
                right: 0,
            },
            jumpBack: {
                display: "flex",
                justifyContent: "flex-end",
            },
            heading: {
                paddingTop: 50,
                paddingLeft: 50,
            },
            modal: {
                elevation: -99999,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                flexShrink: 1,
            },
            animatedItem: {
                animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
                backgroundColor: propColors.primary,
                overflow: "auto"
            },
            animatedItemExiting: {
                animation: `$myEffectExit 500ms ${theme.transitions.easing.easeInOut
                    }`,
                opacity: 0,
                transform: "translate(200%, 200%)",
            },
            "@keyframes myEffect": {
                "0%": {
                    opacity: 0,
                    transform: "translate(200%, 200%)",
                },
                "100%": {
                    opacity: 1,
                    transform: "translate(0)",
                },
            },
            "@keyframes myEffectExit": {
                "0%": {
                    opacity: 1,
                    transform: "translate(0)",
                },
                "100%": {
                    opacity: 0,
                    transform: "translate(800%, 0%)",
                },
            },
            playContainer: {
                position: "absolute",
                bottom: 0,
                width: "100%",
                backgroundColor: "#0000008f",
                top: 0,
                margin: "auto",
            },
            controls: {
                width: 100,
                height: 100,
            },
            link: {
                height: 100,
            },
            guideTitle: {
                color: propColors.extra,
            },
            selected: {
                color: propColors.primary,
                fontFamily: 'Campton-Bold'
            },
            rootMenuItem: {
                color: propColors.primary,
                fontFamily: 'Campton-Bold',
                "&$selected": {
                    color: propColors.primary,
                    fontFamily: 'Campton-Bold',
                },
            },
            selectRoot: {
                fontSize: "1.2em",
                fontWeight: 'lighter',
                fontFamily: "Campton-Bold",
                padding: "0.5em",
                paddingTop: "0.3em",
                paddingBottom: "0.2em",
                color: propColors.primary,
                marginTop: '1.5em',
                backgroundColor: propColors.secondary,
                "&:before": {
                    borderBottom: "none",
                },
                "&:after": {
                    borderBottom: "none",
                },
                "&::hover": {
                    borderBottom: "none"
                },
                "& .MuiSvgIcon-root": {
                    color: propColors.primary,
                },
            },
            drawer: {
                position: "fixed",
                bottom: 0,
                right: 0,
            },
            margin: {
                marginTop: 100,
                marginBottom: 100,
            },
            collapse: {
                backgroundColor: propColors.secondary,
                textAlign: "center",
            },
            publishActions: {
                position: "fixed",
                bottom: "2em",
                width: "100%"
            },
            checkboxes: {
                color: propColors.secondary,
                '&$checked': {
                    color: propColors.secondary,
                },
            },
            menuButtons: {
                padding: "0.25em",
                backgroundColor: propColors.primary,
                cursor: 'pointer'
            },
            inputFileContainer: {
                position: "relative"
            },
            inputFile: {
                zIndex: 9999,
                width: "100%",
                opacity: 0,
                height: "100%",
                position: "absolute",
            },
            formGrid: {
                [theme.breakpoints.up('md')]: {
                    marginRight: 40
                }
            },
            /**
             * menu drawer style
             */
            drawerOpener: {
                justifyContent: "space-around",
                alignItems: "center",
                display: "flex",
                backgroundColor: colors.primary,
                height: 100,
                width: 100,
            },
            drawerOpenerButton: {
                padding: 0
            }
        })
    );
    // defining component specific styles end

    // hooks start
    const dispatch = useDispatch();
    const classes = fieldGuideStyles();
    const locale = useSelector((state: any) => state.changeLocale);
    const theme = useSelector((state: any) => state.updateTheme);
    const isContactEnabled = useSelector((state: any) => state.contact);
    const { t, i18n } = useTranslation();
    // hooks end

    // a HOC for video player seekbar start
    const Seeker = withStyles({
        root: {
            color: "#282826",
            height: 8,
            padding: 0,
            bottom: 12,
            zIndex: 9,
            opacity: 0,
        },
        thumb: {
            display: "none",
        },
        active: {},
        valueLabel: {
            left: "calc(-50% + 4px)",
        },
        track: {
            height: 20,
            backgroundColor: "red",
        },
        rail: {
            height: 20,
        },
    })(Slider);

    const SeekBar = withStyles((theme) => ({
        root: {
            height: 20,
            borderRadius: 0,
            bottom: 24,
        },
        colorPrimary: {
            backgroundColor: "#282826",
        },
        bar: {
            borderRadius: 0,
            backgroundColor: propColors.secondary,
        },
    }))(LinearProgress);
    // a HOC for video player seekbar stop

    const startAnimation = (drawerOpen: boolean) => {
        Animated.timing(animation, {
            toValue: drawerOpen ? 200 : 0,
            duration: 1000,
        }).start();
        setSuccess(false);
    };

    function enterPressed(e: any) {
        var code: any = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) { //Enter keycode
            if (messageRef.current.value) {
                setValidate(false)
                submitEnquiry()
            } else {
                setValidate(true)
            }
        }
    }

    const submitEnquiry = () => {
        if (messageRef.current.value != "" && localStorage.getItem("token") != undefined) {
            let query = messageRef.current.value;
            let token = localStorage.getItem("token");
            messageRef.current.value = '';
            var formData = new FormData();
            formData.append("assistance[issue]", query);
            // formData.append("token", token ?? '');
            formData.append("assistance[role]", role);

            // let requestData = {
            //     assistance: {
            //         'issue': query,
            //         'role': role
            //     }
            // }
            const requestOptions: any = {
                method: "POST",
                headers: {
                    'token': token,
                },
                body: formData,
            };

            setLoading(true);

            fetch(
                baseURL + "/bx_block_assistance/assistances",
                requestOptions
            )
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(false);
                        setSuccess(true);
                        setTimeout(() => { setSuccess(false) }, 5000);
                    } else {
                        setError(true);
                        setTimeout(() => { setError(false) }, 5000);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setError(true);
                    setTimeout(() => { setError(false) }, 5000);
                    setLoading(false);
                });
        }
    };

    const handleSeekChange = (e: any) => {
        setSeek((e / duration) * 100);
    };

    const handleSeek = (e: any, value: any) => {
        setSeek((value / duration) * 100);
        ref.current.seekTo(value);
    };

    const handleDuration = (e: any) => {
        setDuration(e);
    };

    const handleProgress = (state: any) => {
        setSeek((state.playedSeconds / duration) * 100);
    };

    const handleClickFullscreen = () => {
        if (screenfull.isEnabled) {
            if (ref.current !== null) {
                screenfull.request(findDOMNode(ref.current) as Element);
            }
        }
    };

    const switchLang = (e: any) => {
        dispatch(changeLocale(e.target.value));
        updateLanguage(e.target.value);
    }

    const updateLanguage = (value: any) => {
        let data: any = {
            "account": {
                "language": value == 'fr' ? 1 : 0
            }
        }
        const requestOptions: any = {
            method: "PUT",
            headers: {
                token: localStorage.getItem("token"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        };

        let userId: any = localStorage.getItem("user_id");

        fetch(
            baseConfig.baseURL +
            "/account_block/accounts/" + parseInt(userId),
            requestOptions
        )
            .then((response) => {
                if (response.status == 200) {
                    if (value == 'fr') {
                        localStorage.setItem('language', 'French')
                    } else {
                        localStorage.setItem('language', 'English')
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * update the theme and set the returned colors in the global state
     */
    const storeColors = () => {
        // store the colors in localstorage
        setcolorSelected(true);
        localStorage.setItem('preview_colors', JSON.stringify({
            primary: themeColor1.current.value,
            secondary: themeColor2.current.value,
            extra: themeColor3.current.value
        }))
        // after storing the colors in LS, update the state to render second tab
    };

    /**
     * Function to undo theme if manager decides to go back
     */
    const undoTheme = () => {
        localStorage.removeItem('preview_colors');
        dispatch(ActionCreators.undo())
    }

    /**
     * Function to publish managers theme colors/settings
     * by setting necessary settings in local storage
     * redux store and updating company profile settings in the database
     */
    const publishTheme = () => {
        const theme = JSON.parse(localStorage.getItem('preview_colors') as string);
        if (theme) {
            var formData = new FormData();
            formData.append("company[theme_colour_type]", localStorage.getItem('setting') ?? '');
            formData.append("company[theme_hash_code_1]", theme.primary);
            formData.append("company[theme_hash_code_2]", theme.secondary);
            formData.append("company[theme_hash_code_3]", theme.extra);
            let config = {
                headers: { 'token': localStorage.getItem('token') }
            }
            axios.put(baseURL + '/account_block/accounts/update_theme', formData, config)
                .then((response: any) => {
                    localStorage.setItem('colors', localStorage.getItem('preview_colors') as string);
                    localStorage.removeItem('preview_colors');
                    setPublish(false);
                    dispatch(
                        updateTheme({
                            colors: {
                                primary: theme.primary,
                                secondary: theme.secondary,
                                extra: theme.extra
                            }
                        })
                    );
                });
        }
    }

    /**
     * Function to get affirmation from API to show appropriate status
     */
    const getAffirmations = () => {
        const user = localStorage.getItem('role');
        if (user !== undefined) {
            switch (user) {
                case 'candidate':
                    var url = baseURL + '/bx_block_test/tests/get_affirmation?account_id=' + localStorage.getItem('user_id')
                    break;
                default:
                    var url = baseURL + '/bx_block_test/tests/get_hr_affirmation'
                    break;
            }
            let config = {
                headers: { 'token': localStorage.getItem('token') }
            }
            axios.get(url, config)
                .then((response: any) => {
                    setAffirmation(response.data.message);
                });
        }
    }

    /**
     * Function to get video URL from the server to show the player
     */
    const getVideoUrl = () => {
        var url = baseURL + '/bx_block_video_management/vlogs'
        let config = {
            headers: { 'token': localStorage.getItem('token') }
        }
        axios.get(url, config)
            .then((response: any) => {
                setvideoURL(response.data.data[0].attributes.video.url);
            });
    }

    /**
     * Function to load colors from local storage into state
     */
    const previewTheme = () => {
        const theme = JSON.parse(localStorage.getItem('preview_colors') ?? '');
        if (theme) {
            dispatch(
                updateTheme({
                    updated: true,
                    setting: localStorage.getItem('setting'),
                    colors: {
                        primary: theme.primary,
                        secondary: theme.secondary,
                        extra: theme.extra
                    }
                })
            );
        }
    }

    /**
     * Function to render boxes where manager can put theme colors
     * @param setting variable, whether theme setting is
     * 1. light_on_dark
     * 2. dark_on_light
     * 3. default
     * @returns component to render boxes where manager can put theme colors
     */
    const showThemeBoxes = (setting: string) => {
        let primary, secondary, extra: string = '';
        const theme = JSON.parse(localStorage.getItem('preview_colors') as string ?? localStorage.getItem('colors') as string);
        /**
         * based on selected setting, the colors will be interchanged
         * to change the theme
         */
        if (theme) {
            /**
             * based on setting's value, values of variables primary, secondary and extra will be interchanged
             */
            switch (setting) {
                case 'light':
                    primary = theme.primary;
                    secondary = theme.secondary;
                    extra = theme.extra;
                    break;
                case 'dark':
                    primary = theme.extra;
                    secondary = theme.primary;
                    extra = theme.secondary;
                    break;
                default:
                    primary = theme.secondary;
                    secondary = theme.extra;
                    extra = theme.primary;
                    break;
            }
            return (
                <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" width="250px" height="160px" style={{ borderWidth: 2, borderStyle: "solid", borderColor: '#ffffff', backgroundColor: primary }}>
                    <Box>
                        <Box width="80px" height="30px" style={{ backgroundColor: secondary }}></Box>
                    </Box>
                    <Box>
                        <Box width="70px" height="5px" style={{ backgroundColor: secondary }}></Box>
                        <Box mt="10px" mb="10px" width="70px" height="5px" style={{ backgroundColor: extra }}></Box>
                        <Box mt="10px" mb="10px" width="70px" height="5px" style={{ backgroundColor: extra }}></Box>
                        <Box width="70px" height="5px" style={{ backgroundColor: extra }}></Box>
                    </Box>
                </Box>
            )
        }
    }

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .MuiTooltip-tooltip`]: {
            color: colors.secondary,
            background: colors.primary,
            fontSize: '1em',
            fontFamily: 'Campton-bold'
        },
        [`& .MuiTooltip-arrow`]: {
            color: colors.primary,
        }
    }));

    /**
     * Function to render menu items
     * @returns menuItems component wrapping menu for users based on roles
     */
    const renderMenuItems = () => {
        let menuItems: any;
        switch (role) {
            case "candidate":
                menuItems = (
                    <Box display="flex" flexDirection="column">
                        <BootstrapTooltip title="Contact Us" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { dispatch(toggleContactModal(true)); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Contact" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Affirmation" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { getAffirmations(); setSubMenuAffirmations(true); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Affirmations" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Dashboard" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { setfgDashbaord(true); openDrawer(!drawer); startAnimation(!drawer); }}>
                                <CustomSVGIcon width="48px" icon="Dash" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Tutorial" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { getVideoUrl(); setMediaPlayer(true); openDrawer(!drawer); startAnimation(!drawer); setPlaying(true); }}>
                                <CustomSVGIcon width="48px" icon="Tutorial" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Results" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { Props.history.push('/results'); openDrawer(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Result" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Assessment" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { setSurveyModal(true); openDrawer(!drawer); startAnimation(!drawer); }}>
                                <CustomSVGIcon width="48px" icon="Survey" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Language" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { setlang(!langDropdown); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Lang" />
                            </span>
                        </BootstrapTooltip>
                    </Box>
                );
                break;
            case "hr":
                menuItems = (
                    <Box display="flex" flexDirection="column">
                        <BootstrapTooltip title="Contact Us" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { dispatch(toggleContactModal(true)); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Contact" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Affirmation" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { getAffirmations(); setSubMenuAffirmations(true); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Affirmations" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Dashboard" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { setfgDashbaord(true); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Dash" />
                            </span>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Invite" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { openDrawer(!drawer); Props.mail({ mailInvitationModal: true, trigger: 'fieldGuide' }) }}>
                                <CustomSVGIcon width="48px" icon="Mail" />
                            </span>
                        </BootstrapTooltip>
                        {
                            localStorage.getItem('is_premium') === 'true' ?
                                <BootstrapTooltip title="Customizer" arrow={true} placement="left">
                                    <span className={classes.menuButtons} onClick={() => { setCustomizer(!customizer); openDrawer(!drawer); startAnimation(!drawer); setcolorSelected(false) }}>
                                        <CustomSVGIcon width="48px" icon="Customizer" />
                                    </span>
                                </BootstrapTooltip>
                                : <></>
                        }
                        <BootstrapTooltip title="Language" arrow={true} placement="left">
                            <span className={classes.menuButtons} onClick={() => { setlang(!langDropdown); openDrawer(!drawer); startAnimation(!drawer) }}>
                                <CustomSVGIcon width="48px" icon="Lang" />
                            </span>
                        </BootstrapTooltip>
                    </Box>
                );
                break;
            default:
                break;
        }
        return menuItems;
    }

    const uploadedFile = (e: any) => {
        setLogo(e.target.files[0].name);
    }

    const uploadLogoFile = () => {
        let formData = new FormData();
        formData.append("account[theme_logo]", logoFile.current.files[0]);
        axios.put(baseURL + '/account_block/accounts/update_theme', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': localStorage.getItem('token')
            }
        })
            .then((response: any) => {
                setLogo('Logo uploaded successfully!');
                dispatch(
                    updateLogo(baseURL + response.data.data.attributes.theme_logo.url)
                );
            })
            .catch((error: any) => {
                console.log('logo', error);
                setLogo('Logo cannot be uploaded!');
            })
    }

    const getDraftTestList = (testId: any) => {
        let userId: any = localStorage.getItem('user_id');
        axios.get(baseURL + '/bx_block_test/tests/get_draft?test_id=' + parseInt(testId) + '&user_id=' + parseInt(userId), {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        .then((response: any) => {
            status.push(response.data?.data[0]?.attributes)
            setInvitedTestStatus(status)
        })
    }

    const getAllResultsList = (invitedTestIds: any) => {
        let invitedTestId: any = invitedTestIds
        axios.get("https://knowmeq.me/api/tests", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token 645126db3e41e1ab51d72b644b4e149ce42465c6'
            }
        })
            .then((response: any) => {
                let filteredTest: any = [];
                filteredTest = response.data.filter((item: any) => invitedTestId.some((i: any) => item.test_id === i));
                filteredTest.map((item: any) => {
                    getDraftTestList(item.test_id)
                })
                setInvitedTestList(filteredTest)
            })
            .catch((error: any) => {
                console.log("Something went wrong!")
            })
    }

    const getInvitedTestList = () => {
        setCount(1);
        let userId: any = localStorage.getItem('user_id');
        axios.get(baseURL + '/bx_block_notifications/notifications/test_lists?account_id=' + parseInt(userId), {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
            .then((response: any) => {
                getAllResultsList(response.data.data.attributes.test_id);
            })
            .catch((error: any) => {
                console.log("Something went wrong!")
            })
    }

    useEffect(() => {
        if(count == 0){
            getInvitedTestList();
        }
    }, [invitedTestList, testStatus])

    return (
        <Box>
            <Box className={classes.drawer}>
                <Slide in={drawer} direction="up">
                    {renderMenuItems()}
                </Slide>
                <Button
                    className={classes.drawerOpenerButton}
                    onClick={() => {
                        openDrawer(!drawer);
                        !subMenu ? startAnimation(!drawer) : setSubMenu(false);
                    }}>
                    <Box className={classes.drawerOpener}>
                        <CustomSVGIcon color={drawer ? colors.extra : colors.secondary} width="50%" height="50%" icon="Knowmeq" />
                    </Box>
                </Button  >
                {/*contact modal*/}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={isContactEnabled}
                    onClose={Props.onClose}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box width={isMobile ? (Helper.isPotrait() ? '100%' : '100%') : '85%'} height={isMobile ? (Helper.isPotrait() ? '80vh' : '85vh') : '85vh'} className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit })} overflow="none">
                        <Box display="flex" justifyContent="space-between" height="100%">
                            <Box flexGrow="0.8" p={2} pb={3} overflow={isMobile ? 'auto' : 'none'}>
                                <TypoGraphy transform="none" variant="h5" color={colors.extra}>
                                    {localStorage.getItem("role") == "candidate" ? t("assistance") : t("assistance_man")}
                                </TypoGraphy>
                                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between">
                                    <Box mb={5} mt={5} flexGrow="1" className={classes.formGrid}>
                                        <Box>
                                            <TypoGraphy transform="none" color={colors.secondary} variant="h6">
                                                {t("full_name")}
                                            </TypoGraphy>
                                            <FormField
                                                bgcolor={'#ffffff'}
                                                inputcolor={colors.primary}
                                                width={100}
                                                height={"auto"}
                                                value={localStorage.getItem(
                                                    "fullName"
                                                )}
                                                passwordfield={false}
                                                readOnly={true}
                                            />
                                        </Box>
                                        {localStorage.getItem("role") == "candidate" ? (
                                            <Box mt={5}>
                                                <TypoGraphy transform="none" color={colors.secondary} variant="h6">
                                                    {t("application_id")}
                                                </TypoGraphy>
                                                <FormField
                                                    bgcolor={'#ffffff'}
                                                    inputcolor={colors.primary}
                                                    width={100}
                                                    height={"auto"}
                                                    value={localStorage.getItem(
                                                        "applicationId"
                                                    )}
                                                    passwordfield={false}
                                                    readOnly={true}
                                                />
                                            </Box>
                                        ) : (
                                            <></>
                                        )}

                                        <Box mt={5}>
                                            <TypoGraphy
                                                color={colors.secondary}
                                                variant="h6"
                                            >
                                                {t("email")}
                                            </TypoGraphy>
                                            <FormField
                                                width={100}
                                                bgcolor={'#ffffff'}
                                                inputcolor={colors.primary}
                                                height={"auto"}
                                                value={localStorage.getItem(
                                                    "email"
                                                )}
                                                passwordfield={false}
                                                readOnly={true}
                                            />
                                        </Box>
                                    </Box>
                                    <Box mb={5} mt={5} flexGrow="1">
                                        <Box height="100%" display="flex" flexDirection="column">
                                            <Box>
                                                <TypoGraphy color={colors.secondary} variant="h6" transform={'none'}>{t("issue_description")}</TypoGraphy>
                                            </Box>
                                            <Box flex="1">
                                                <FormField
                                                    inputRef={messageRef}
                                                    readOnly={false}
                                                    inputcolor={colors.primary}
                                                    multiline={true}
                                                    bgcolor={'#ffffff'}
                                                    rows={12}
                                                    passwordfield={false}
                                                    width={100}
                                                    height={100}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Grid container>
                                        <Grid item xs={12} sm={8}>
                                            <Box>
                                                <Box display={success ? "flex" : "none"}>
                                                    <Typography color="textPrimary" variant="body1">{t('success')}</Typography>
                                                </Box>
                                                <Box display={loading ? "flex" : "none"}>
                                                    <Typography color="textPrimary" variant="body1">{t('sending')}</Typography>
                                                </Box>
                                                <Box display={error ? "flex" : "none"}>
                                                    <Typography color="textPrimary" variant="body1">{t('failure')}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <KnowMeButton disabled={messageRef.current == '' ? true : false} bgcolor={colors.secondary} onClick={() => submitEnquiry()}>
                                                <TypoGraphy variant="h6" color={colors.primary}>
                                                    {t("submit")}
                                                </TypoGraphy>
                                            </KnowMeButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            {/* button to switch and open back the menu */}
                            <Box display="flex" flexDirection="column" justifyContent="space-between" p={2}>
                                <Button onClick={() => { dispatch(toggleContactModal(false)); openDrawer(!drawer); startAnimation(!drawer) }}>
                                    <CustomSVGIcon width="48px" icon="Contact" />
                                </Button>
                                <Box>
                                    <Button onClick={() => dispatch(toggleContactModal(false))}>
                                        <CustomSVGIcon width="48px" icon="Knowmeq" />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                {/*affirmations modal*/}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={subMenuAffirmations}
                    onClose={Props.onClose}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit, })} width={isMobile ? (Helper.isPotrait() ? '100%' : '100%') : wp('35%')} height={isMobile ? (Helper.isPotrait() ? '40vh' : '60vh') : wp('30%')} overflow="none">
                        {/* load the component for  */}
                        <Box display="flex" flexDirection="row" justifyContent="space-between" height="100%">
                            <Box flexGrow="0.8" p={2}>
                                <TypoGraphy color={colors.extra} transform="none" variant="h5">
                                    {affirmation}
                                </TypoGraphy>
                            </Box>
                            {/* button to switch and open back the menu */}
                            <Box display="flex" flexDirection="column" justifyContent="space-between" p={2}>
                                <Button onClick={() => { setSubMenuAffirmations(false); openDrawer(!drawer); startAnimation(!drawer) }}>
                                    <CustomSVGIcon width="48px" icon="Affirmations" />
                                </Button>
                                <Box>
                                    <Button onClick={() => setSubMenuAffirmations(false)}>
                                        <CustomSVGIcon width="48px" icon="Knowmeq" />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                {/*language modal*/}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={langDropdown}
                    onClose={Props.onClose}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box width={isMobile ? (Helper.isPotrait() ? '100%' : '100%') : wp('35%')} height={isMobile ? (Helper.isPotrait() ? '40vh' : '60vh') : wp('30%')} display="flex" justifyContent="space-between" flexDirection="row" className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit })}>
                        <Box p={2} style={{ flex: 6, display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ color: colors.extra }} variant="h5">{t('guide.guide1.item4')}</Typography>
                            <Select
                                className={clsx(classes.selectRoot)}
                                value={locale}
                                onChange={switchLang}
                                inputProps={{
                                    name: "lang",
                                    id: "age-native-helper",
                                }}
                                disableUnderline={true}
                            >
                                <MenuItem style={{ color: colors.primary }} value="en" classes={{ selected: classes.selected }}>English</MenuItem>
                                <MenuItem style={{ color: colors.primary }} value="fr" classes={{ selected: classes.selected }}>French</MenuItem>
                            </Select>
                        </Box>
                        <Box p={2} display="flex" flexDirection="column" justifyContent="space-between">
                            <Button onClick={() => { setlang(false); openDrawer(!drawer); startAnimation(!drawer); }}>
                                <CustomSVGIcon width="48px" icon="Lang" />
                            </Button>
                            <Button onClick={() => setlang(false)}>
                                <CustomSVGIcon width="48px" icon="Knowmeq" />
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                {/*dashboard modal*/}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={fgDashbaord}
                    onClose={Props.onClose}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box width={isMobile ? "100%" : "85%"} height="85vh" display="flex" justifyContent="space-between" flexDirection="row" className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit })}>
                        {
                            localStorage.getItem('role') == 'hr' ?
                                <Box p={2} overflow={isMobile ? 'auto' : 'none'}>
                                    <Grid container justify="space-between" alignItems="flex-start" spacing={10}>
                                        <Grid item sm={12} md={5}>
                                            <Grid container>
                                                {!isMobile ?
                                                    <Grid item xs={2}>
                                                        <CustomSVGIcon width="48px" icon="Affirmations" />
                                                    </Grid> : <></>
                                                }
                                                <Grid item xs={isMobile ? 12 : 10}>
                                                    <TypoGraphy color={colors.extra} transform="none" variant="h5">
                                                        {role === 'hr' ? t("beginAssessmentHr") : t("beginAssessment")}
                                                    </TypoGraphy>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.margin}>
                                                {!isMobile ?
                                                    <Grid item xs={2}>
                                                        <CustomSVGIcon width="48px" icon="Lang" />
                                                    </Grid> : <></>
                                                }
                                                <Grid item xs={isMobile ? 12 : 10}>
                                                    <Typography
                                                        style={{ color: colors.extra }}
                                                        variant="h5"
                                                    >
                                                        {t("select_language")}
                                                    </Typography>
                                                    <Select
                                                        className={clsx(classes.selectRoot)}
                                                        value={locale}
                                                        onChange={switchLang}
                                                        inputProps={{
                                                            name: "lang",
                                                            id: "age-native-helper",
                                                        }}
                                                        disableUnderline={true}
                                                    >
                                                        <MenuItem value="en" classes={{ selected: classes.selected, root: classes.rootMenuItem }}>English</MenuItem>
                                                        <MenuItem value="fr" classes={{ selected: classes.selected, root: classes.rootMenuItem }}>French</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                {!isMobile ?
                                                    <Grid item xs={2}>
                                                        <CustomSVGIcon width="48px" icon="Website" />
                                                    </Grid> : <></>
                                                }
                                                <Grid item xs={isMobile ? 12 : 10}>
                                                    <Link
                                                        href="https://knowmeq.com"
                                                        target="_blank"
                                                        style={{ height: "100px" }}
                                                    >
                                                        <Typography
                                                            style={{ color: colors.extra }}
                                                            variant="h5"
                                                        >
                                                            {t("website")}
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <Grid container>
                                                {!isMobile ?
                                                    <Grid item xs={2}>
                                                        <CustomSVGIcon width="48px" icon="Contact" />
                                                    </Grid> : <></>
                                                }
                                                <Grid item xs={isMobile ? 12 : 10}>
                                                    <TypoGraphy transform={'none'} color={colors.extra} variant="h5">
                                                        {t("assistance")}
                                                    </TypoGraphy>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item md={2} />
                                                <Grid item sm={12} md={10}>
                                                    <Box flexDirection="column" mt={5}>
                                                        <Box flexDirection="row" justifyContent="space-between" display={localStorage.getItem("role") == "candidate" ? "flex" : "block"}>
                                                            <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                                {t("full_name")}
                                                            </TypoGraphy>
                                                            <FormField width={localStorage.getItem("role") == "candidate" ? 95 : 100}
                                                                size="h6"
                                                                bgcolor={'#ffffff'}
                                                                inputcolor={colors.primary}
                                                                height={40}
                                                                value={localStorage.getItem(
                                                                    "fullName"
                                                                )}
                                                                passwordfield={false}
                                                                readOnly={true}
                                                                onKeyPress={(e: any) => enterPressed(e)}
                                                            />
                                                            {localStorage.getItem("role") ==
                                                                "candidate" ? (
                                                                <>
                                                                <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                                    {t("application_id")}
                                                                </TypoGraphy>
                                                                <FormField
                                                                    width={100}
                                                                    color={colors.secondary}
                                                                    bgcolor={'#ffffff'}
                                                                    inputcolor={colors.primary}
                                                                    size="h6"
                                                                    height={40}
                                                                    value={localStorage.getItem("applicationId")}
                                                                    readOnly={true}
                                                                    passwordfield={false}
                                                                    onKeyPress={(e: any) => enterPressed(e)}
                                                                />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Box>
                                                        <Box mt={2} mb={2}>
                                                            <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                                {t("email")}
                                                            </TypoGraphy>
                                                            <FormField
                                                                width={100}
                                                                bgcolor={'#ffffff'}
                                                                inputcolor={colors.primary}
                                                                color={colors.secondary}
                                                                size="h6"
                                                                height={40}
                                                                value={localStorage.getItem("email")}
                                                                readOnly={true}
                                                                passwordfield={false}
                                                                onKeyPress={(e: any) => enterPressed(e)}
                                                            />
                                                        </Box>
                                                        <Box mt={2}>
                                                            <TypoGraphy variant="h6" color={palette.secondary.main}>
                                                                {t("issue_description")}
                                                            </TypoGraphy>
                                                            <FormField
                                                                width={100}
                                                                bgcolor={'#ffffff'}
                                                                inputcolor={colors.primary}
                                                                transform={'none'}
                                                                size="h6"
                                                                inputRef={messageRef}
                                                                color={colors.secondary}
                                                                readOnly={false}
                                                                multiline={true}
                                                                rows={3}
                                                                passwordfield={false}
                                                                onKeyPress={(e: any) => enterPressed(e)}
                                                            />
                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            justifyContent="flex-end"
                                                            alignItems="center">
                                                            <Box
                                                                mt={5}
                                                                display={validate ? "flex" : "none"}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        color: palette.error.main,
                                                                    }}
                                                                >
                                                                    {t("Please enter your query")}
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                display={
                                                                    success
                                                                        ? "inline"
                                                                        : "none"
                                                                }
                                                                flex="auto">
                                                                <Text
                                                                    style={{
                                                                        color:
                                                                            palette.info
                                                                                .main,
                                                                    }}>
                                                                    {t("success")}
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                display={
                                                                    loading
                                                                        ? "inline"
                                                                        : "none"
                                                                }
                                                                flex="auto">
                                                                <Text
                                                                    style={{
                                                                        color:
                                                                            palette.info
                                                                                .main,
                                                                    }}>
                                                                    {"Sending..."}
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                display={
                                                                    error
                                                                        ? "inline"
                                                                        : "none"
                                                                }
                                                                flex="auto">
                                                                <Text
                                                                    style={{
                                                                        color:
                                                                            palette.error
                                                                                .main,
                                                                    }}
                                                                >
                                                                    {t("failure")}
                                                                </Text>
                                                            </Box>
                                                            <Box mt={5}>
                                                                <KnowMeButton onKeyPress={(e: any) => enterPressed(e)} bgcolor={colors.secondary} onClick={() => submitEnquiry()}>
                                                                    <Box p={0.75}>
                                                                        <TypoGraphy variant="h5" color={colors.primary}>
                                                                            {t("submit")}
                                                                        </TypoGraphy>
                                                                    </Box>
                                                                </KnowMeButton>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                :
                                <Box p={6} pl={5} overflow={isMobile ? 'auto' : 'none'} style={{ width: '100%' }}>
                                    <Grid item container justify="space-between" alignItems="flex-start" spacing={10}>
                                        <Grid item sm={12} md={6}>
                                            <Grid container>
                                                {!isMobile ?
                                                    <Grid item xs={2} md={3}>
                                                        <CustomSVGIcon width="48px" icon="Survey" />
                                                    </Grid> : <></>
                                                }
                                                <Grid item xs={isMobile ? 12 : 10} md={9}>
                                                    {
                                                        invitedTestList && invitedTestList.map((item: any, index: any) => {
                                                            let temp: any = testStatus && testStatus.filter((testItem: any) => {
                                                                return testItem?.test_id == item.test_id
                                                            })
                                                            return (
                                                                <Grid container key={index}>
                                                                    <Box
                                                                        style={{
                                                                            padding: 6,
                                                                            backgroundColor: temp && temp[0]?.status === "completed" ? palette.primary.main : palette.secondary.main,
                                                                            borderRadius: 5,
                                                                            borderWidth: "2px",
                                                                            borderStyle: "solid",
                                                                            borderColor: colors.secondary,
                                                                            width: "100%",
                                                                        }}
                                                                        mb={2}
                                                                        display="flex"
                                                                        justifyContent="space-between"
                                                                        flexDirection="row"
                                                                    >
                                                                        <Box style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            <TypoGraphy variant="h6" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main}>{item?.test_title}</TypoGraphy>
                                                                            <TypoGraphy variant="body1" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main} style={{ textTransform: 'capitalize' }}>
                                                                                {
                                                                                    temp && temp[0]?.status === "completed" ?
                                                                                        "Completed"
                                                                                        : temp && temp[0]?.status === "draft" ?
                                                                                            "In Progress"
                                                                                            : "Pending"
                                                                                }
                                                                            </TypoGraphy>
                                                                        </Box>
                                                                        <TouchableOpacity
                                                                            onPress={() => {
                                                                                Props.history.push('/test/' + item?.test_id);
                                                                                setfgDashbaord(false);
                                                                                openDrawer(!drawer);
                                                                            }}
                                                                        >
                                                                            <CustomSVGIcon width="28px" icon="Expand" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main} />
                                                                        </TouchableOpacity>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <Grid container>
                                                {!isMobile ?
                                                    <Grid item xs={2} md={3}>
                                                        <CustomSVGIcon width="48px" icon="Result" />
                                                    </Grid> : <></>
                                                }
                                                <Grid item xs={isMobile ? 12 : 10} md={9}>
                                                    {
                                                        invitedTestList && invitedTestList.map((item: any, index: any) => {
                                                            let temp: any = testStatus && testStatus.filter((testItem: any) => {
                                                                return testItem?.test_id == item.test_id
                                                            })
                                                            return (
                                                                <Grid container key={index}>
                                                                    <Box
                                                                        style={{
                                                                            padding: 6,
                                                                            backgroundColor: temp && temp[0]?.status === "completed" ? palette.primary.main : palette.secondary.main,
                                                                            borderRadius: 5,
                                                                            borderWidth: "2px",
                                                                            borderStyle: "solid",
                                                                            borderColor: colors.secondary,
                                                                            width: "100%",
                                                                        }}
                                                                        mb={2}
                                                                        display="flex"
                                                                        justifyContent="space-between"
                                                                        flexDirection="row"
                                                                    >
                                                                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <TypoGraphy variant="h6" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main}>{item?.test_title}</TypoGraphy>
                                                                            <TypoGraphy style={{ textTransform: 'capitalize' }} variant="body1" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main}>
                                                                                {
                                                                                    temp && temp[0]?.status === "completed" ?
                                                                                        "Generated"
                                                                                        : temp && temp[0]?.status === "draft" ?
                                                                                            "Not yet generated"
                                                                                            : "Not yet generated"
                                                                                }
                                                                            </TypoGraphy>
                                                                        </Box>
                                                                        <TouchableOpacity
                                                                            disabled={temp && temp[0]?.status != 'completed' ? true : false}
                                                                            onPress={() => {
                                                                                Props.history.push('/results/' + item?.test_id);
                                                                                openDrawer(!drawer);
                                                                                setfgDashbaord(false);
                                                                            }}
                                                                        >
                                                                            <CustomSVGIcon width="28px" icon="Expand" color={temp && temp[0]?.status === "completed" ? colors.secondary : colors.primary} />
                                                                        </TouchableOpacity>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                        }
                        <Box pt={localStorage.getItem('role') == "candidate" ? 6 : 2} display="flex" flexDirection="column" justifyContent="space-between">
                            <Box>
                                <Button onClick={() => { setfgDashbaord(false); openDrawer(!drawer); startAnimation(!drawer) }}>
                                    <CustomSVGIcon width="48px" icon="Dash" />
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={() => setfgDashbaord(false)}>
                                    <CustomSVGIcon width="48px" icon="Knowmeq" />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                {/* {Survey Modal} */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={surveyModal}
                    onClose={Props.onClose}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box width={isMobile ? "100%" : "85%"} height="85vh" display="flex" justifyContent="space-between" flexDirection="row" className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit })}>
                        <Box p={3} pt={6} overflow={isMobile ? 'auto' : 'none'} style={{ width: '100%' }}>
                            <Grid item container justify="space-between" alignItems="flex-start" spacing={10}>
                                <Grid item sm={12} md={12}>
                                    <Grid container>
                                        {!isMobile ?
                                            <Grid item xs={2}>
                                                <CustomSVGIcon width="48px" icon="Survey" />
                                            </Grid> : <></>
                                        }
                                        <Grid item xs={isMobile ? 12 : 10}>
                                            {
                                                invitedTestList && invitedTestList.map((item: any, index: any) => {
                                                    let temp: any = testStatus && testStatus.filter((testItem: any) => {
                                                        return testItem?.test_id == item.test_id
                                                    })
                                                    return (
                                                        <Grid container key={index}>
                                                            <Box
                                                                style={{
                                                                    padding: 6,
                                                                    backgroundColor: temp && temp[0]?.status === "completed" ? colors.primary : colors.secondary,
                                                                    borderRadius: 5,
                                                                    borderWidth: "2px",
                                                                    borderStyle: "solid",
                                                                    borderColor: colors.secondary,
                                                                    width: "100%",
                                                                }}
                                                                mb={2}
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                flexDirection="row"
                                                            >
                                                                <Box style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column'
                                                                }}>
                                                                    <TypoGraphy variant="h6" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main}>{item?.test_title}</TypoGraphy>
                                                                    <TypoGraphy variant="body1" color={temp && temp[0]?.status === "completed" ? palette.secondary.main : palette.primary.main} style={{ textTransform: 'capitalize' }}>
                                                                        {
                                                                            temp && temp[0]?.status === "completed" ?
                                                                                "Completed"
                                                                                : temp && temp[0]?.status === "draft" ?
                                                                                    "In Progress"
                                                                                    : "Pending"
                                                                        }
                                                                    </TypoGraphy>
                                                                </Box>
                                                                <TouchableOpacity
                                                                    onPress={() => { Props.history.push('/test/' + item?.test_id); openDrawer(!drawer); setSurveyModal(false); }}
                                                                >
                                                                    <CustomSVGIcon width="28px" icon="Expand" color={temp && temp[0]?.status === "completed" ? colors.secondary : colors.primary} />
                                                                </TouchableOpacity>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={2} pt={6} display="flex" flexDirection="column" justifyContent="space-between">
                            <Box>
                                <Button onClick={() => { setSurveyModal(false); openDrawer(!drawer); startAnimation(!drawer) }}>
                                    <CustomSVGIcon width="48px" icon="Survey" />
                                </Button>
                            </Box>
                            <Box>
                                <Button onClick={() => setSurveyModal(false)}>
                                    <CustomSVGIcon width="48px" icon="Knowmeq" />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                {/* video modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={mediaPlayer}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box width={isMobile ? "100%" : "55%"} height="85vh" display="flex" justifyContent="space-between" flexDirection="row" className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit })}>
                        <Player videoURL={videoURL} open={mediaPlayer} onClosePlayer={() => {
                            setPlaying(false);
                            setMediaPlayer(false);
                            openDrawer(!drawer);
                            startAnimation(!drawer);
                        }}
                            onClose={setMediaPlayer}
                        />
                    </Box>
                </Modal>
                {/* theme customizer and theme selector */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={customizer}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                        invisible: true,
                    }}>
                    <Box className={clsx(classes.animatedItem, { [classes.animatedItemExiting]: exit })} style={{ width: "70%" }}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" height="100%">
                            <Box p={2} pb={3} overflow={isMobile ? 'auto' : 'none'}>
                                <Box mb="2em">
                                    <TypoGraphy transform="none" color={colors.secondary} variant="h4">
                                        {t("theme_head")}
                                    </TypoGraphy>
                                </Box>
                                <Box display={!colorSelected ? 'flex' : 'none'} flexDirection="column">
                                    <Box mb="2em">
                                        <TypoGraphy transform="none" color={colors.extra} variant="body1">
                                            {t("theme_s1")}
                                        </TypoGraphy>
                                    </Box>
                                    <Box mb="3em" display="flex" flexDirection="row" justifyContent="space-between">
                                        <Box flex="1.5" className={classes.inputFileContainer}>
                                            <input onChange={uploadedFile} className={classes.inputFile} ref={logoFile} accept="image/*" type="file" />
                                            <KnowMeButton height="100%" bgcolor={colors.secondary}>
                                                <TypoGraphy variant="h5" color={colors.primary}>{t('browse')}</TypoGraphy>
                                            </KnowMeButton>
                                        </Box>
                                        <Box flex="2" alignSelf="center">
                                            <TypoGraphy align="center" fontWeight="light" color="#ffffff" variant="subtitle2">{t(logo)}</TypoGraphy>
                                        </Box>
                                        <Box flex="1.19">
                                            <KnowMeButton bgcolor={colors.secondary} onClick={() => { uploadLogoFile() }}>
                                                <Box p={0.75}>
                                                    <TypoGraphy variant="h5" color={colors.extra}>{t('upload')}</TypoGraphy>
                                                </Box>
                                            </KnowMeButton>
                                        </Box>
                                    </Box>
                                    <Box mb="2em">
                                        <TypoGraphy transform="none" color={colors.extra} variant="body1">
                                            {t("theme_s2")}
                                        </TypoGraphy>
                                    </Box>
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                        <Box flex="2">
                                            <FormField size={"subtitle2"} inputcolor={colors.primary} passwordfield={false} text={t('main')} width="80" defaultValue={colors.primary} inputRef={themeColor1} placeholder="#######" />
                                        </Box>
                                        <Box flex="2">
                                            <FormField size={"subtitle2"} inputcolor={colors.primary} passwordfield={false} text={t('secondary')} width="80" defaultValue={colors.secondary} inputRef={themeColor2} placeholder="#######" />
                                        </Box>
                                        <Box flex="2">
                                            <FormField size={"subtitle2"} inputcolor={colors.primary} passwordfield={false} text={t('font')} width="80" defaultValue={colors.extra} inputRef={themeColor3} placeholder="#######" />
                                        </Box>
                                        <Box flex="2" mt={"0.75em"}>
                                            <KnowMeButton bgcolor={colors.secondary} onClick={() => storeColors()}>
                                                <Box p={0.75}>
                                                    <TypoGraphy variant="h5" color={colors.extra}>{t('upload')}</TypoGraphy>
                                                </Box>
                                            </KnowMeButton>
                                        </Box>
                                    </Box>
                                </Box>

                                { /* theme selection */}
                                <Box display={!colorSelected ? "none" : "flex"}>
                                    <Box mb="2em">
                                        <TypoGraphy transform="none" color={colors.extra} variant="body1">
                                            {t("theme_s3")}
                                        </TypoGraphy>
                                        <Box mt="2em" display="flex" flexDirection="row" justifyContent="space-between">
                                            <Box>
                                                {showThemeBoxes('light')}
                                                <FormControlLabel control={<Radio className={classes.checkboxes} checked={selectedTheme == 'light_on_dark' ? true : false} value="light_on_dark" onChange={(e: any) => { setselectedTheme(e.target.value); localStorage.setItem('setting', e.target.value) }} />}
                                                    label={<TypoGraphy transform="none" color={colors.extra} variant="body2"> {t("theme_option1")}</TypoGraphy>} />
                                            </Box>
                                            <Box>
                                                {showThemeBoxes('dark')}
                                                <FormControlLabel control={<Radio className={classes.checkboxes} checked={selectedTheme == 'dark_on_light' ? true : false} value="dark_on_light" onChange={(e: any) => { setselectedTheme(e.target.value); localStorage.setItem('setting', e.target.value) }} />}
                                                    label={<TypoGraphy transform="none" color={colors.extra} variant="body2">{t("theme_option2")}</TypoGraphy>} />
                                            </Box>
                                            <Box>
                                                {showThemeBoxes('mixed')}
                                                <FormControlLabel control={<Radio className={classes.checkboxes} checked={selectedTheme == 'mixed' ? true : false} value="mixed" onChange={(e: any) => { setselectedTheme(e.target.value); localStorage.setItem('setting', e.target.value) }} />}
                                                    label={<TypoGraphy transform="none" color={colors.extra} variant="body2">{t("theme_option3")}</TypoGraphy>} />
                                            </Box>
                                        </Box>
                                        <Box mt="2em" display="flex" flexDirection="row" justifyContent="flex-end">
                                            <KnowMeButton
                                                width="15%"
                                                bgcolor={"#e5e5e5"}
                                                onClick={(e: any) => {
                                                    previewTheme();
                                                    openDrawer(false);
                                                    setCustomizer(false);
                                                    setPublish(true);
                                                }}
                                            >
                                                <Box p={0.75}>
                                                    <TypoGraphy variant="h5" color={"#5b5b5b"}>{t('preview')}</TypoGraphy>
                                                </Box>
                                            </KnowMeButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="space-between" p={2}>
                                <Button onClick={() => { setCustomizer(false); openDrawer(!drawer); startAnimation(!drawer) }}>
                                    <CustomSVGIcon width="48px" icon="Customizer" />
                                </Button>
                                <Button onClick={() => setCustomizer(false)}>
                                    <CustomSVGIcon width="48px" icon="Knowmeq" />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Box>
            <Box className={classes.publishActions} display={publish ? "flex" : "none"} flexDirection="row" justifyContent="space-around">
                <KnowMeButton onClick={() => { undoTheme(); setCustomizer(true); setPublish(false); setcolorSelected(false) }} width="15%" bgcolor={colors.primary}>
                    <Box width="100%" alignItems="center" justifyContent="space-evenly" p={2.5} flexDirection="row" display="flex">
                        <CustomSVGIcon width="3em" height="3em" icon="Customizer" />
                        <TypoGraphy variant="h5" color={colors.extra}>{t('back')}</TypoGraphy>
                    </Box>
                </KnowMeButton>
                <KnowMeButton onClick={() => { publishTheme(); }} width="15%" bgcolor={colors.primary}>
                    <Box width="100%" alignItems="center" justifyContent="space-evenly" p={2.5} flexDirection="row" display="flex">
                        <CustomSVGIcon width="3em" height="3em" icon="Check" />
                        <TypoGraphy variant="h5" color={colors.extra}>{t('publish')}</TypoGraphy>
                    </Box>
                </KnowMeButton>
            </Box>
        </Box >
    );
}

/**
 * Function to map state to props to use this.props.state
 * @param state state of the component
 * @returns state as HOC
 */
const mapStateToProps = (state: any) => ({
    state: state
});

export default withRouter(connect(mapStateToProps)(FieldGuide))