import React from "react";
// Customizable Area Start
import { StyleSheet, TouchableOpacity, View, ScrollView, TouchableWithoutFeedback } from "react-native";
import { Button, Box, Container, Typography, Grid, Modal, Checkbox, FormControl, FormGroup, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import ModalTab1 from "../../../components/src/guide/ModalTab1";
import { Image, Dimensions } from "react-native";
import { Translation } from "react-i18next";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import styled from "styled-components";
import GenericCard from "../../../components/src/GenericCard";
import { palette } from "../../../components/src/Palette";
import { TypoGraphy } from "../../../components/src/TypoGraphy";
import { IconImage } from "../../../components/src/IconImage";
import { connect } from "react-redux";
import KnowMeButton from "../../../components/src/Button";
import CustomSVGIcon from "../../../components/src/SVGIcon";
import { updateTheme, expandFilter, testFilter, openEventDialog, changeLocale } from "../../../components/src/actions/index";
import { withTranslation } from 'react-i18next';
import * as _ from "lodash";

const isPotrait = () => {
  return Dimensions.get("window").height >= Dimensions.get("window").width;
};
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import moment from "moment";

class Dashboard extends DashboardController {
  colors: any
  calendarRef: any
  subcalendarRef: any
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.calendarRef = React.createRef();
    this.subcalendarRef = React.createRef();
    this.switch = this.switch.bind(this);
    this.colors = this.props.state.updateTheme.present.colors
    this.language = localStorage.getItem("language") === 'French' ? 'fr' : 'en';
    // Customizable Area End
  }
  // Customizable Area Start
  language: any;

  /**
   * Function to update state for selected filters
   * @param filter 
   */
  openPositionFilter = (filter: string) => {
    this.setState({ selectedPositionFilter: filter, positionFilter: false });
    this.getFilterData(filter);
  };

  /**
   * Function to set title and date for all events
   * @returns array of events to populate calendary for manager dashboard
   */
  allEvents = () => {
    let eveArr: any = [];
    this.state.nylasEventData.map((nylasEvent: any, index: any) => {
      return eveArr.push({ title: nylasEvent.attributes.title, date: nylasEvent.attributes.time });
    })
    return eveArr;
  }

  /**
   * Setting height and width while resizing the browser window
   */
  onresize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
  }

  async componentDidMount() {
    super.componentDidMount();
    /**
     * set a key in localstorage to determine if events are fetched, later when user manually changes calendar view
     * or click prev/next/today buttons reset the counter again to false               
     */
    if (localStorage.getItem('role') == 'hr') {
      let calendar = this.calendarRef.current.getApi();
      this.getNylasEventList(moment().startOf('month').format('X'), moment().endOf('month').format('X'))
      calendar.gotoDate(moment().startOf('month').toISOString());
      localStorage.setItem('eventsFetched', 'false')
    }

    const path = window.location.href.split('/', 5).pop();
    const theme: any = JSON.parse(localStorage.getItem('colors') as string);
    window.addEventListener("resize", this.onresize);
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
    if (theme) {
      this.props.dispatch(
        updateTheme({
          updated: false,
          setting: localStorage.getItem('setting'),
          colors: {
            primary: theme.primary,
            secondary: theme.secondary,
            extra: theme.extra
          }
        }),
      );
    }
    if (path === 'roles') {
      this.props.dispatch(
        expandFilter(true)
      );
    }
    this.props.dispatch(changeLocale(this.language));
    if (localStorage.getItem('role') === 'candidate') {
      this.getAllDraftAnswers()
    }

  }

  componentDidUpdate(prevProps: any) {

    if (this.props.state.testFilter) {
      this.props.dispatch(
        testFilter(!this.props.state.testFilter)
      );
    }

    if (localStorage.getItem('role') == 'hr') {
      let calendar = this.calendarRef.current.getApi();
      if (localStorage.getItem('eventsFetched') == 'true') {
        calendar.gotoDate(this.state.currentDate);
      }
      calendar.changeView(this.state.calendarView);
    }

    /**
     * re-render dashboard and fetch events only when renderDashboard is passsed as true
     */
    if (this.props.proppedState.renderDashboard) {
      let calendarAPI = this.calendarRef.current.getApi();
      let { start, end } = calendarAPI?.currentDataManager?.data?.dateProfile?.currentRange;
      this.getNylasEventList(moment(start).format('X'), moment(end).format('X'));
    }
  }

  /**
   * Expand Job posting listing to full screen
   */
  expandJobs = () => {
    this.props.dispatch(
      expandFilter(!this.props.state.expandFilter)
    );
  }

  /** 
   * method to get the positions from the state
   * and rendering cards on the right side 
   */
  showJobs = () => {
    const jobs = this.state.jobPostData.map((jobPost: any) =>
      <Box width="100%" style={{ marginBottom: 15 }}>
        <GenericCard type={'Recent'} cardFor={'jobs'}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" style={{ padding: '0.7em' }}>
            <Box flex="3">
              <TypoGraphy variant="subtitle1" color={jobPost.attributes.status != 'Recent' ? "#ffffff" : palette.primary.main}>{jobPost.attributes.title}</TypoGraphy>
              <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ marginTop: '0.8em' }}>
                <span style={{
                  color: jobPost.attributes.status != 'Recent' ? "#ffffff" : palette.primary.main,
                  fontSize: '0.8em',
                  fontFamily: 'Campton-Bold'
                }}
                >
                  {jobPost.attributes.city}
                </span>
                <TypoGraphy fontWeight="light" variant="body2" color={jobPost.attributes.status != 'Recent' ? "#ffffff" : palette.primary.main}>{jobPost.attributes.employee_count ? jobPost.attributes.employee_count : '0'}</TypoGraphy>
              </Box>
            </Box>
            <Box style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignContent: 'flex-end'
            }}>
              <Button onClick={() => this.state.selectedPositionFilter === "Roles" ? this.props.navigation.navigate('DashboardGuests', { type: 'roles', id: jobPost.id, }) : this.props.navigation.navigate('ProjecttaskTracking', { id: 1 })}>
                <IconImage height="1.2em" width="1.2em">
                  {<img width="100%" src={require(`../assets/expand-Recent.svg`)} />}
                  {/*<img width="100%" src={require(`../assets/expand-${jobPost.attributes.status}.svg`)} />*/}
                </IconImage>
              </Button>
            </Box>
          </Box>
        </GenericCard>
      </Box>
    );
    return <Box>{jobs}</Box>;
  };

  /**
   * show main dashboard area based on user type
   * @returns wrapped jsx component for dashboard area
   */
  showDashboard = () => {
    /**
     * getting settings like time, color
     */
    const { t } = this.props;
    const today = moment().format("YYYY-MM-DD");
    const { navigation } = this.props;
    const colors = this.props.state.updateTheme.present;

    const StyledWrapper = styled.div`
      * {
        font-family: Campton-Bold;
        color: ${this.colors.primary};
      }
      .fc-day-today .fc-daygrid-day-frame {
        background: #ffffff;
      }
      .fc-daygrid-day-number {
        color: ${this.colors.primary};
        font-weight: 500;
      }
      .fc-day-today .fc-daygrid-day-number {
        color: ${this.colors.extra};
        padding: 0.3em;
        background: ${this.colors.primary};
        border-radius: 50%;
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .fc-timegrid-slot-label-cushion {
        text-transform: uppercase;
      }
      .fc-timegrid-axis-cushion,
      .fc-direction-ltr .fc-timegrid-slot-label-frame {
        text-align: center;
      }
      .fc-timegrid-slot-minor {
        border: 0 !important;
      }
      .fc-col-header-cell-cushion {
        word-spacing: 20px;
      }
    `;
    const StyledWrapperSub = styled(StyledWrapper)`
      .fc-daygrid-day-events {
        display: none;
      }
      .fc-daygrid-day-number {
        font-size: 0.5em;
      }
      a.fc-col-header-cell-cushion {
        font-size: 0.5em;
      }
      .fc-day-today .fc-daygrid-day-frame {
        background-color: #e9e9e9;
      }
      .fc-col-header {
        display: none;
      }
    `;

    /**
     * conditional rendering based on user role
     */
    if (localStorage.getItem("role") == "candidate") {
      let name: any = localStorage.getItem('fullName')!.split(" ");
      return (
        <Box>
          <Box style={isPotrait() ? { paddingLeft: 10, paddingRight: 10 } : {}}>
            <Grid container justify="center">
              <Grid item xs={12} sm={4} style={{ wordWrap: 'break-word' }}>
                <Translation>
                  {(t) => (
                    <TypoGraphy color={this.colors.primary} variant="h2" align="center">
                      {" "}
                      {t("greet")}, {name[0]}!
                    </TypoGraphy>
                  )}
                </Translation>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Translation>
                  {(t) => (
                    <TypoGraphy transform={"none"} color={this.colors.primary} variant="h4">
                      {t("welcome_message")}
                    </TypoGraphy>
                  )}
                </Translation>
                <Box mt={isPotrait() ? 5 : 8}>
                  <Translation>
                    {(t) => (
                      <TypoGraphy
                        transform="normal"
                        color="#000000"
                        fontWeight="light"
                        variant="subtitle1"
                      >
                        {t("body1")}
                      </TypoGraphy>
                    )}
                  </Translation>
                  &nbsp;
                  <Translation>
                    {(t) => (
                      <TypoGraphy
                        transform="normal"
                        color="#000000"
                        fontWeight="light"
                        variant="subtitle1"
                      >
                        {t("body2")}
                      </TypoGraphy>
                    )}
                  </Translation>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexDirection="column"
            mt={10}
            mb={2}
          >
            {/* iterate over all the test here */}
            {
              this.state.invitedTestsList.sort(function (a: any, b: any) {
                return a.test_id - b.test_id;
              }).map((item: any, index: any) => {
                let temp: any = this.state.allTestInfo.length && this.state.allTestInfo.filter((testItem: any) => {
                  return testItem?.attributes.test_id == item.test_id
                })
                return (
                  <Box
                    style={{
                      padding: 20,
                      backgroundColor: temp && temp[0]?.attributes.status == 'completed' ? this.colors.primary : this.colors.secondary + '60',
                      borderRadius: 5,
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderColor: this.colors.primary,
                      width: "90%",
                    }}
                    mb={2}
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                  >
                    <TypoGraphy style={{ marginTop: '15px' }} transform={"none"} color={temp && temp[0]?.attributes.status == 'completed' ? this.colors.secondary : this.colors.primary} variant="h5">
                      {item.test_title}
                    </TypoGraphy>
                    <TouchableOpacity
                      onPress={() => { this.setState({ testModal: true, testId: item.test_id, testTitle: item.test_title, testDescription: item.test_description }), this.getDraftAnswers(item.test_id) }}
                    >
                      <CustomSVGIcon width="48px" icon="Expand" color={temp && temp[0]?.attributes.status == 'completed' ? this.colors.secondary : this.colors.primary} />
                    </TouchableOpacity>
                  </Box>
                )
              })
            }
          </Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.testModal}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
              invisible: true,
            }}
          >
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                padding: 50,
                width: "90%",
                height: "70%",
                backgroundColor: this.colors.primary,
              }}
            >
              <Grid container>
                <Grid item sm={2}>
                  <CustomSVGIcon color={this.colors.secondary} width="48px" height="48px" icon="Time" />
                  <Box style={{ marginTop: '20px' }}>
                    <TypoGraphy color={this.colors.extra} variant="body1" align="center">18 Mins</TypoGraphy>
                  </Box>
                </Grid>
                <Grid item sm={7}>
                  <TypoGraphy variant="h3" color={this.colors.secondary}>
                    {this.state.testTitle}
                  </TypoGraphy>
                  <Box mt={4}>
                    <TypoGraphy variant="body1" color={this.colors.extra}>{this.state.testDescription}</TypoGraphy>
                  </Box>
                  <Box mt={5}>
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate("ProjecttaskTracking", { id: this.state.testId })
                      }
                      style={{
                        width: "50%",
                        backgroundColor: this.colors
                          .secondary,
                      }}
                    >
                      <Typography
                        align="center"
                        style={{
                          width: "100%",
                          textAlign: 'center',
                          backgroundColor: this.colors
                            .secondary,
                        }}
                      >
                        <Typography
                          align="center"
                          style={{
                            padding: 20,
                            color: this.colors.primary,
                            fontSize: 18,
                            fontFamily: "Campton-Bold",
                            textAlign: 'center'
                          }}
                        >
                          {
                            this.state.testInfo.status === "pending" || !this.state.testInfo.status ? 'Start'
                              : this.state.testInfo.status === "draft" ? 'Continue'
                                : this.state.testInfo.status === "completed" ? 'Completed'
                                  : 'Start'
                          }
                        </Typography>
                      </Typography>
                    </TouchableOpacity>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Button onClick={() => this.setState({ testModal: false })}>
                    <CustomSVGIcon color={this.colors.secondary} width="48px" height="48px" icon="Close" />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box >
      );
    } else {
      if (window.location.href.split("/").pop() == "all-tests") {
        this.openPositionFilter("Show all available tests");
      }
      return (
        <Box>
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid
                item
                sm={this.props.state.expandFilter ? 12 : 3}
                style={{
                  display: this.state.expandCalendar ? "none" : "block",
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <span
                    style={{ padding: 0, justifyContent: "flex-start" }}
                    onClick={this.openFilter}
                  >
                    <CustomSVGIcon color={this.colors.primary} width="36px" height="36px" icon="Filter" />
                  </span>
                  <Menu
                    id="demo-positioned-menu"
                    open={this.state.anchorEl}
                    onClose={this.closeFilter}
                    getContentAnchorEl={null}
                    style={{
                      marginTop: '9em',
                      marginLeft: this.state.windowWidth > 599 ? '11em' : '8em'
                    }}
                  >
                    <MenuItem
                      onClick={this.closeFilter}
                    >
                      <Translation>
                        {(t: any) => (
                          <span style={{ color: this.colors.primary, fontFamily: 'Campton-Bold' }}>
                            {t("filterText")}
                          </span>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.closeFilter();
                        this.openPositionFilter("Roles");
                      }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Role")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      this.closeFilter();
                      this.props.navigation.navigate("DashboardGuests", {
                        type: "hire-date",
                      });
                    }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Hire Date")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      this.closeFilter();
                      this.props.navigation.navigate("DashboardGuests", {
                        type: "interview-date",
                      });
                    }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Interview Date")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      this.closeFilter();
                      this.props.navigation.navigate("DashboardGuests", {
                        type: "application-submission-date",
                      });
                    }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Application Submission Date")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      this.closeFilter();
                      this.props.navigation.navigate("DashboardGuests", {
                        type: "star-rating",
                      });
                    }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Star Rating")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      this.closeFilter();
                      this.props.navigation.navigate("DashboardGuests", {
                        type: "all-results",
                      });
                    }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Show all results")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      this.closeFilter();
                      this.props.navigation.navigate('DashboardGuests', { type: 'all-tests' });
                    }}>
                      <Translation>
                        {(t) => (
                          <TypoGraphy style={{ cursor: 'pointer' }} variant="subtitle2" color={this.colors.primary}>
                            {t("Show all available test")}
                          </TypoGraphy>
                        )}
                      </Translation>
                    </MenuItem>
                  </Menu>
                  {/* </Box> */}
                  <Box paddingX={2} flexGrow="1" alignItems="center">
                    <TypoGraphy color={this.colors.primary} variant="h5">{t(this.state.selectedPositionFilter)}</TypoGraphy>
                  </Box>
                  <span
                    style={{ padding: 0, justifyContent: "flex-end" }}
                    // variant="contained"
                    onClick={() => {
                      this.props.navigation.navigate('Dashboard');
                      this.expandJobs();
                    }}
                  >
                    <CustomSVGIcon color={this.colors.primary} width="36px" height="36px" icon={this.props.state.expandFilter ? "ContractLeft" : "ExpandRight"} />
                  </span>
                </Box>
                <Box mt={5} style={{ maxHeight: hp('60%'), overflowY: 'scroll' }}>
                  {
                    this.state.loading ?
                      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Image style={{ height: 34, width: 34 }} source={require(`../assets/loader.svg`)} />
                      </Box>
                      : this.showJobs()
                  }
                </Box>
              </Grid>
              <Grid
                item
                sm={this.state.expandCalendar ? 12 : 9}
                style={{ display: this.props.state.expandFilter ? "none" : "block" }}
              >
                <Box mb={5} display="flex" flexDirection="row" alignItems="center" position="relative">
                  {/* <Button style={{ padding: 0, justifyContent: "flex-start" }} variant="contained" onClick={this.openCalendarFilter} id={'filterBtn'}> */}
                    <CustomSVGIcon color={this.colors.primary} width="36px" height="36px" icon="Filter" onClick={this.openCalendarFilter} />
                  {/* </Button> */}
                  <Box display={this.state.agendaFilter == true ? "flex" : "none"} style={{ position: 'relative' }}>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      open={this.state.calFilter}
                      getContentAnchorEl={null}
                      onClose={this.closeCalendarFilter}
                      style={{
                        marginTop: this.state.windowWidth > 599 ? '9%' : '35em',
                        marginLeft: this.state.windowWidth > 599 ? '33%' : '15%'
                      }}
                    >
                      <MenuItem onClick={() => {
                        this.closeCalendarFilter();
                        this.changeView("timeGridDay");
                      }}>
                        <TypoGraphy style={{ cursor: 'pointer' }} color={this.colors.primary} variant="subtitle2">{t("day")}</TypoGraphy>
                      </MenuItem>
                      <MenuItem onClick={() => {
                        this.closeCalendarFilter();
                        this.changeView("timeGridWeek");
                      }}>
                        <TypoGraphy style={{ cursor: 'pointer' }} color={this.colors.primary} variant="subtitle2">{t("week")}</TypoGraphy>
                      </MenuItem>
                      <MenuItem onClick={() => {
                        this.closeCalendarFilter();
                        this.changeView("dayGridMonth");
                      }}>
                        <TypoGraphy style={{ cursor: 'pointer' }} color={this.colors.primary} variant="subtitle2">{t("month")}</TypoGraphy>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box paddingX={2} flexGrow="1" alignItems="center">
                    <TypoGraphy color={this.colors.primary} variant="h5">{new Date(2021, new Date().getMonth(), 21).toLocaleString("default", { month: "long" })}{" "}{new Date().getUTCDate()}, {new Date().getUTCFullYear()}</TypoGraphy>
                  </Box>
                  <span style={{ padding: 0, justifyContent: "flex-end" }} onClick={() => {
                    this.setState({ expandCalendar: !this.state.expandCalendar })
                  }}>
                    <CustomSVGIcon color={this.colors.primary} width="36px" height="36px" icon={!this.state.expandCalendar ? "ContractLeft" : "ExpandRight"} />
                  </span>
                </Box>
                <View
                  style={{
                    backgroundColor: this.colors
                      .primary,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.5em",
                  }}
                >
                  <Box ml={3}>
                    <TypoGraphy variant="body1" color={this.colors.secondary}><span id={'calRef'}>{this.state.calendarTitle}</span></TypoGraphy>
                  </Box>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: "0.5em",
                    }}
                  >
                    <KnowMeButton onClick={() => this.switch("prev")} width="15%" bgcolor="transparent">
                      <Box width="100%">
                        <CustomSVGIcon width="2.6em" height="2.6em" icon="Back" />
                      </Box>
                    </KnowMeButton>
                    <KnowMeButton onClick={() => this.switch("today")} width="15%" bgcolor={this.colors.secondary}>
                      <Box width="100%">
                        <TypoGraphy variant="subtitle2" color={this.colors.primary}>Today</TypoGraphy>
                      </Box>
                    </KnowMeButton>
                    <KnowMeButton onClick={() => this.switch("next")} width="15%" bgcolor="transparent">
                      <Box width="100%">
                        <CustomSVGIcon width="2.6em" height="2.6em" icon="Next" />
                      </Box>
                    </KnowMeButton>
                  </View>
                </View>
                <Grid
                  container
                  style={{
                    borderStyle: "solid",
                    borderWidth: "3px",
                    borderColor: this.colors.primary,
                  }}
                >
                  <Grid item sm={12} md={8}>
                    <StyledWrapper>
                      <FullCalendar
                        ref={this.calendarRef}
                        lazyFetching={true}
                        dateClick={(arg: any) => {
                          this.setState({ dateArgs: arg });
                          this.props.dispatch(openEventDialog(true))
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={false}
                        allDayText="All Day"
                        events={this.state.nylasEventData}
                        eventBackgroundColor={this.colors.secondary + '60'}
                        eventTextColor={this.colors.primary}
                        eventBorderColor={this.colors.primary}
                        displayEventTime={false}
                        eventClassNames="knowme-event"
                      />
                    </StyledWrapper>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={4}
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingX={5}
                      paddingY={5}
                    >
                      <Typography
                        style={{
                          color: this.colors.primary,
                          fontWeight: "bolder",
                        }}
                        variant="h6"
                      >
                        To do
                      </Typography>
                      <Box style={{ height: "12em", overflowY: "scroll" }}>
                        <FormControl required component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={
                                <TypoGraphy
                                  fontWeight="light"
                                  color={
                                    this.colors.primary
                                  }
                                  variant="subtitle1"
                                >
                                  Review notes from last week's meeting
                                </TypoGraphy>
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={
                                <TypoGraphy
                                  fontWeight="light"
                                  color={
                                    this.colors.primary
                                  }
                                  variant="subtitle1"
                                >
                                  Review notes from last week's meeting
                                </TypoGraphy>
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={
                                <TypoGraphy
                                  fontWeight="light"
                                  color={
                                    this.colors.primary
                                  }
                                  variant="subtitle1"
                                >
                                  Review notes from last week's meeting
                                </TypoGraphy>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>
                      <View>
                        <StyledWrapperSub>
                          <FullCalendar
                            ref={this.subcalendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={false}
                          />
                        </StyledWrapperSub>
                      </View>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      );
    }
  };


  /**
   * Function to change view of the calendar and update calendar's title
   * @param view month/day/week
   */
  changeView(view: string) {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView(view);
    this.setCalendarTitle(view);
    this.setState({ agendaFilter: !this.state.agendaFilter })
  }

  /**
   * Function to handle calendar re-rendar, event fetching on clicks of next/prev/today buttons
   * @param type next/prev
   */
  switch(type: string) {
    let calendarApi = this.calendarRef.current.getApi();
    let calId: any = document.getElementById('calRef');

    switch (type) {
      case "next":
        {
          calendarApi.next();
          let { start, end } = calendarApi?.currentDataManager?.data?.dateProfile?.currentRange;
          this.getNylasEventList(moment(start).format('X'), moment(end).format('X'));
          localStorage.setItem('eventsFetched', 'true')
          this.setState({ currentDate: start });
          if (this.state.calendarView === 'timeGridDay') {
            calId.innerText = moment(calendarApi.getDate()).format("dddd") + ", Week " + moment(calendarApi.getDate()).isoWeek()
          } else {
            calId.innerText = calendarApi.view.title
          }
          break;
        }
      case "prev":
        {
          calendarApi.prev();
          let { start, end } = calendarApi?.currentDataManager?.data?.dateProfile?.currentRange;
          this.getNylasEventList(moment(start).format('X'), moment(end).format('X'));
          localStorage.setItem('eventsFetched', 'true')
          this.setState({ currentDate: start });
          if (this.state.calendarView === 'timeGridDay') {
            calId.innerText = moment(calendarApi.getDate()).format("dddd") + ", Week " + moment(calendarApi.getDate()).isoWeek()
          } else {
            calId.innerText = calendarApi.view.title
          }
          break;
        }
      default:
        {
          calendarApi.today();
          let { start, end } = calendarApi?.currentDataManager?.data?.dateProfile?.currentRange;
          this.getNylasEventList(moment(start).format('X'), moment(end).format('X'));
          localStorage.setItem('eventsFetched', 'true')
          this.setState({ currentDate: start });
          if (this.state.calendarView === 'timeGridDay') {
            calId.innerText = moment(calendarApi.getDate()).format("dddd") + ", Week " + moment(calendarApi.getDate()).isoWeek()
          } else {
            calId.innerText = calendarApi.view.title
          }
          break;
        }
    }
  }

  /**
   * Function to update state for calendar's title based on selected view
   * @param view month/day/week
   */
  setCalendarTitle(view: string) {
    let calendarApi = this.calendarRef.current.getApi();
    if (view == "timeGridDay") {
      this.setState({ calendarTitle: moment(calendarApi.getDate()).format("dddd") + ", Week " + moment(calendarApi.getDate()).isoWeek(), });
    } else {
      this.setState({ calendarTitle: calendarApi.view.title });
    }
    this.setState({ calendarView: view })
  }

  // Customizable Area End

  render() {
    this.colors = this.props.state.updateTheme.present.colors;
    return (
      //Merge Engine DefaultContainer
      <ScrollView
        keyboardShouldPersistTaps="always"
        style={styles.container}
        contentContainerStyle={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View style={styles.container}>
            <View style={styles.mainContainer}>
              {/* showDashboard method renders dashboard conent based on user role - candidate / HR */}
              {this.showDashboard()}
              <ModalTab1 user={this.state.user} secondary={this.props.state.updateTheme.present.colors.secondary} primary={this.props.state.updateTheme.present.colors.primary} />
            </View>
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "90%",
    overflow: "hidden",
    margin: "auto",
  },
  mainContainer: {
    width: '100%',
  },
  filterButton: {
    backgroundColor: "transparent",
  },
  overlayMenu: {
    position: "absolute",
  },
  text: {
    fontFamily: "Campton-Bold",
  },
});

/**
 * Function to map state to props to use this.props.state
 * @param state state of the component
 * @returns state as HOC
 */
const mapStateToProps = (state: any) => ({
  state: state,
});

export default connect(mapStateToProps)(withTranslation()(Dashboard));
// Customizable Area End
