import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import { palette } from '../src/Palette';

const themeOptions = {
    typography: {
        allVariants: {
            fontFamily: 'Campton-Bold',
            textTransform: 'Capitalize'
        },
        body1: {
            fontFamily: 'Campton-Light'
        },
        h5: {
            '@media (max-width:600px)': {
                fontSize: '1.5rem',
            },
        },
        h6: {
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        }
    },
    palette: palette,
    overrides: {
        MuiButton: {
            root: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: "none",
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                borderRadius: 0
            },
            contained: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: "none",
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                }
            },
            MuiPickersDay: {
                day: {
                    '&$selected': {
                        color: "red"
                    }
                },
            }
        },
        MuiListItem: {
            root: {
                "&$disabled": {
                    // your disabled styles here
                }
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
};
let theme: any = createMuiTheme(themeOptions as ThemeOptions)
export default theme