import React from "react";

export default function Survey(props: any) {
    return (
        <svg viewBox="0 0 72 72" fill={props.color}>
            <path d="M15.0809 15.8398V61.4651C17.9609 63.8904 21.2957 65.7851 24.9336 67.0735C25.1609 66.9977 25.3883 66.9977 25.6157 66.9977C26.5251 66.9977 27.3588 67.3767 27.9651 67.983C30.542 68.6651 33.1946 68.9683 35.9988 68.9683C43.8809 68.9683 51.1567 66.1641 56.8409 61.5409V15.8398H15.0809ZM25.6157 58.9641C23.7967 58.9641 22.2809 57.4483 22.2809 55.6293C22.2809 53.8104 23.7967 52.2946 25.6157 52.2946C27.4346 52.2946 28.9504 53.8104 28.9504 55.6293C28.9504 57.4483 27.4346 58.9641 25.6157 58.9641ZM25.843 44.1851C24.0241 44.1851 22.5083 42.6693 22.5083 40.8504C22.5083 39.0314 24.0241 37.5156 25.843 37.5156C27.662 37.5156 29.1778 39.0314 29.1778 40.8504C29.1778 42.6693 27.662 44.1851 25.843 44.1851ZM49.6409 56.7662H33.2704V54.4925H49.6409V56.7662ZM49.7925 41.9872H33.422V39.7135H49.7925V41.9872ZM50.9294 28.6483H21.0683V24.1009H50.9294V28.6483Z"/>
            <path d="M36 72C16.1432 72 0 55.8568 0 36C0 16.1432 16.1432 0 36 0C55.8568 0 72 16.1432 72 36C72 55.8568 55.8568 72 36 72ZM36 3.03158C17.8105 3.03158 3.03158 17.8105 3.03158 36C3.03158 54.1895 17.8105 68.9684 36 68.9684C54.1895 68.9684 68.9684 54.1895 68.9684 36C68.9684 17.8105 54.1895 3.03158 36 3.03158Z"/>
        </svg>
    );
}
